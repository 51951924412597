import React, { useState, useEffect } from 'react';

import { Table } from 'antd';

import SuperTableColumn from './SuperTableColumn';

export default function SuperTableContent(props) {
  const [loading, setLoading] = useState(false);

  const {
    id,
    columns,
    queryParams = {},
    loadDataSource = () => {},
    dataSource = [],
    expandable = {},
    onChangeDataSource = () => {},
    selectable = false,
    selectedRowKeys = [],
    onSelectedRowChange = () => {},
    onQueryParamsChange = () => {},
    onPaginationChange = () => {},
    pagination,
  } = props;

  useEffect(() => {
    _onChange(queryParams, pagination);
  }, []);

  const _onSelectedRowChange = (selectedRowKeys) => {
    onSelectedRowChange(selectedRowKeys);
  };

  const _onChange = (_queryParams, _pagination, filters, sorter) => {
    const {
      pageSize = 10,
      current = 1,
      total,
    } = _pagination;

    setLoading(true);

    onQueryParamsChange(_queryParams)

    onPaginationChange({
      ...pagination,
      ..._pagination,
      current,
      total,
      pageSize,
    })

    if (loadDataSource) {
      loadDataSource({
        ..._queryParams,
        from: pageSize * (current - 1),
        count: pageSize
      })
      .then((res) => {
        const {data, totalCount} = res;

        setLoading(false);
        onChangeDataSource(data.map((record, index) => ({
            ...record,
            key: record.id !== undefined ? record.id : index
          })));

        onPaginationChange({
          ...pagination,
          ..._pagination,
          total: totalCount,
        })
      })
    } else {
      setLoading(false);
    }
  };

  // calculate width
  const scroll = {};

  let widthTotal = 0;
  columns.forEach(column => widthTotal += parseInt(column.width) || 240);
  scroll.x = widthTotal

  return (
    <Table
      id={id}
      className="super-table"
      size="small"
      loading={loading}
      expandable={expandable}
      columns={columns.map(column => SuperTableColumn(column))}
      dataSource={dataSource}
      pagination={pagination}
      rowSelection={selectable ? {
        type: selectable,
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys) => _onSelectedRowChange(selectedRowKeys)
      } : null}
      onRow={selectable ? (record) => {
        switch (selectable) {
          case 'radio':
            return {
              onClick: () => _onSelectedRowChange([record.key]),
              style: {
                cursor: 'pointer',
              },
            }
          case 'checkbox':
            return {
              onClick: () => {
                if (selectedRowKeys.indexOf(record.key) === -1) {
                  _onSelectedRowChange([...selectedRowKeys, record.key]);
                } else {
                  _onSelectedRowChange([...selectedRowKeys].filter(key => key !== record.key));
                }
              },
              style: {
                cursor: 'pointer',
              },
            }
        }
      } : null}
      onChange={(_pagination, filters, sorter) => _onChange(queryParams, _pagination, filters, sorter)}
      scroll = {{
        ...scroll,
      }}
    />
  );
};
