import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';

import { Modal, Form, Input, Button, Cascader, Col, Row } from 'antd';
import { EditOutlined, PlusOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import SpinFull from '../../components/SpinFull';

const formItemLayout = {
  labelCol: {
    md: { span: 5 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

export default function LockerCellLayoutModal(props) {
  const { cellLayout=[[[]]], visible, onOk, onCancel } = props;

  const [cellLayoutNew, setCellLayoutNew] = useState(cellLayout);

  const [editColModal, setEditColModal] = useState({
    key: new Date().getTime(),
    visible: false,
    colIndices: null,
  });

  const [editBatchModal, setEditBatchModal] = useState({
    key: new Date().getTime(),
    visible: false,
    input: '',
  });

  const sideOptions = [];
  for (let i = 1; i <= 8; ++i) {
    sideOptions.push({
      label: `${i}`,
      value: i,
    });
  }

  const rowOptions = [];
  for (let i = 1; i <= 36; ++i) {
    rowOptions.push({
      label: `${i}`,
      value: i,
    });
  }

  const colOptions = [];
  for (let i = 1; i <= 36; ++i) {
    colOptions.push({
      label: `${i}`,
      value: i,
    });
  }

  return (
    <>
      <Modal
        title={I18n.t('lockerCellLayoutModalTitle')}
        visible={visible}
        onOk={() => onOk(cellLayoutNew)}
        onCancel={onCancel}
        width="80%"
        okText={I18n.t('lockerCellLayoutButtonConfirm')}
        cancelText={I18n.t('lockerCellLayoutButtonCancel')}
        maskClosable={false}
      >
        <div style={{textAlign: 'right'}}>
          <span style={{marginRight: 8}}>{I18n.t('lockerCellLayoutModalNoOfSide')}</span>
          <Cascader
            allowClear={false}
            style={{width: 64, textAlign: 'left'}}
            onChange={(value) => {
              const layoutNew = [];

              for (let i = 0; i < value; ++i) {
                  layoutNew.push(cellLayoutNew[i] || [[]]);
              }

              setCellLayoutNew(layoutNew);
            }}
            placeholder={I18n.t('lockerCellLayoutModalNoOfSide')}
            options={sideOptions}
            value={[cellLayoutNew.length]}
          />

          <Button
            type="primary"
            onClick={() => {
              const input = cellLayoutNew.map(sideLayout => sideLayout.map(row => row.map(col => (col.name === undefined || col.name.trim() === '' ? '?' : col.name)).join('\n')).join('\n\n')).join('\n----\n');

              setEditBatchModal({
                ...editBatchModal,
                visible: true,
                input,
              });
            }}
            style={{marginLeft: 8}}
          >
            {I18n.t('lockerCellLayoutModalButtonBatchEdit')}
          </Button>
        </div>

        <Row type="flex">
          {cellLayoutNew.map((sideLayout, side) => (
            <Col key={side} style={{flex: 1}}>
              <div className="lockerCellLayoutSideContainer">
                <div className="header">
                  <span style={{marginRight: 8}}>{I18n.t('lockerCellLayoutModalNoOfRow')}</span>
                  <Cascader
                    allowClear={false}
                    style={{width: 64, textAlign: 'left'}}
                    onChange={(value) => {
                      const rowsNew = [];

                      const rowEmpty = [];
                      const maxColCount = sideLayout.length ? sideLayout[sideLayout.length - 1].length : 1;
                      for (let i = 0; i < maxColCount; ++i) {
                          rowEmpty.push({});
                      }

                      for (let i = 0; i < value; ++i) {
                          rowsNew.push(sideLayout[i] || rowEmpty);
                      }

                      // deep copy
                      const layoutNew = cellLayoutNew.map(sideTmp => {
                        return sideTmp.map(rowTmp => {
                          return rowTmp.map(col => ({ ...col }));
                        });
                      });
                      layoutNew[side] = rowsNew;

                      setCellLayoutNew(layoutNew);
                    }}
                    placeholder={I18n.t('lockerCellLayoutModalNoOfRow')}
                    options={rowOptions}
                    value={[sideLayout.length]}
                  />
                </div>
                <div>
                  {sideLayout.map((row, i) => {
                    return (
                      <Row type="flex" style={{marginTop: 2}} key={i}>
                        <Col style={{width: 40, textAlign: 'right', paddingRight: 8, color: '#ccc'}}>#{i + 1}</Col>
                        <Col style={{width: 80}}>
                          <Cascader
                            allowClear={false}
                            style={{width: 64}}
                            onChange={(value) => {
                              const rowNew = [];

                              for (let j = 0; j < value; ++j) {
                                rowNew.push(row[j] || {});
                              }

                              // deep copy
                              const layoutNew = cellLayoutNew.map(sideTmp => {
                                return sideTmp.map(rowTmp => {
                                  return rowTmp.map(col => ({ ...col }));
                                });
                              });
                              layoutNew[side][i] = rowNew;

                              setCellLayoutNew(layoutNew);
                            }}
                            placeholder={I18n.t('lockerCellLayoutModalNoOfCol')}
                            options={colOptions}
                            value={[row.length]}
                          />
                        </Col>
                        {row.map((col, j) => {
                          return (
                            <Col style={{flex: 1, padding: '0 1px'}} key={j}>
                              <Button
                                block
                                type={col && col.name ? '' : 'primary'}
                                icon={col && col.name ? <CheckOutlined /> : <ExclamationCircleOutlined />}
                                onClick={() => {
                                  setEditColModal({
                                    componentKey: new Date(),
                                    visible: true,
                                    name: col ? (col.name || '') : '',
                                    colIndices: {
                                      side,
                                      i,
                                      j,
                                    },
                                  });
                                }}
                                style={{height: 64}}
                              >
                                {col && col.name ? col.name : ''}
                              </Button>
                            </Col>
                          );
                        })}
                      </Row>
                    )})}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Modal>

      <Modal
        key={`col${editColModal.componentKey}`}
        title={I18n.t('lockerCellLayoutModalEditCellTitle')}
        visible={editColModal.visible}
        onOk={() => {
          // deep copy
          const layoutNew = cellLayoutNew.map(sideTmp => {
            return sideTmp.map(rowTmp => {
              return rowTmp.map(col => ({ ...col }));
            });
          });
          layoutNew[editColModal.colIndices.side][editColModal.colIndices.i][editColModal.colIndices.j] = {
            name: editColModal.name,
          };

          setCellLayoutNew(layoutNew);

          setEditColModal({
            ...editColModal,
            visible: false,
          });
        }}
        onCancel={() => setEditColModal({
          ...editColModal,
          visible: false,
        })}
        width="40%"
      >
        <Form.Item
          label={I18n.t('lockerCellLayoutModalEditCellName')}
          {...formItemLayout}
        >
          <Input
            placeholder={I18n.t('lockerCellLayoutModalEditCellName')}
            onChange={(e) => {
              setEditColModal({
                ...editColModal,
                name: e.target.value,
              });
            }}
            value={editColModal.name}
          />
        </Form.Item>
      </Modal>

      <Modal
        key={`batch${editBatchModal.componentKey}`}
        title={I18n.t('lockerCellLayoutModalBatchEditTitle')}
        visible={editBatchModal.visible}
        onOk={() => {
          const layoutNew = [[[]]];
          const lines = editBatchModal.input.trim().split('\n');
          let index = 0;

          for (let line of lines) {
            const sideNewLast = layoutNew[layoutNew.length - 1];
            const rowNewLast = sideNewLast[sideNewLast.length - 1];

            line = line.trim();
            if (line === '----') {
              layoutNew.push([[]]);
            } else if (line === '') {
              sideNewLast.push([]);
            } else {
              rowNewLast.push(line === '?' ? {} : {
                name: line,
              });
              index = 1;
            }
          }

          console.log(layoutNew);

          setCellLayoutNew(layoutNew);
          setEditBatchModal({
            ...editBatchModal,
            visible: false,
          });
        }}
        onCancel={() => {
          setEditBatchModal({
            ...editBatchModal,
            visible: false,
          })
        }}
        width="40%"
      >
        <div>{I18n.t('lockerCellLayoutModalBatchEditInstruction')}</div>
        <Input.TextArea
          onChange={(e) => setEditBatchModal({
            ...editBatchModal,
            input: e.target.value,
          })}
          value={editBatchModal.input}
          rows={8}
        />
      </Modal>
    </>
  );
};