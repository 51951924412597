import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {I18n, setLocale} from 'react-redux-i18n';

import { Modal, Form, Input, Button, Spin, Select, Cascader, message } from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';
import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { TextArea } = Input;
const { Option } = Select;

export default function MerchantSettingModal(props) {
  const { visible, onOk, onCancel } = props;
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.value);

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [merchant, setMerchant] = useState(null);

  useEffect(() => {
    (async () => {
      const merchantRes = await ToolRequest.request('GET', `/v1/merchant/${user.merchantId}`);

      setMerchant({
        ...merchantRes,
        locale: [merchantRes.locale],
      });

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const data = {
        contactPhone: values.contactPhone || null,
        locale: values.locale[0],
      };
      await ToolRequest.request('PUT', `/v1/merchant/${merchant.id}`, data);

      message.success(I18n.t('merchantEditScreenEditSuccessMessage'));

      onOk();
      setLoading(false);
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return (
    <Modal
      title={I18n.t('appHeaderMerchantSettingModalTitle')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={merchant}
            onFinish={onFinish}
          >
            <Form.Item
              label={I18n.t('merchantAddScreenFormDisplayName')}
              name="displayName"
            >
              <Input
                placeholder={I18n.t('merchantAddScreenFormDisplayName')}
                disabled
              />
            </Form.Item>

            <Form.Item
              label={I18n.t('merchantAddScreenFormLocale')}
              name="locale"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('merchantAddScreenFormLocale'),
                  }),
                },
              ]}
            >
              <Cascader
                allowClear={false}
                options={[
                  {
                    label: I18n.t('merchantAddScreenFormLocaleEn'),
                    value: 'en',
                  },
                  {
                    label: I18n.t('merchantAddScreenFormLocaleZhTw'),
                    value: 'zh-tw',
                  },
                  {
                    label: I18n.t('merchantAddScreenFormLocaleZhCn'),
                    value: 'zh-cn',
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              label={I18n.t('merchantAddScreenFormContactPhone')}
              name="contactPhone"
            >
              <Input
                placeholder={I18n.t('merchantAddScreenFormContactPhone')}
              />
            </Form.Item>

            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('merchantAddScreenButtonSubmit')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull />}
    </Modal>
  );
};