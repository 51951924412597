import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import {Dropdown, Menu, Button, Modal, message, PageHeader} from 'antd';
import { EditOutlined, CloseOutlined, PlusOutlined ,EllipsisOutlined} from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';
import SuperTable from '../../components/SuperTable';
import ChangePasswordModal from '../App/ChangePasswordModal';

export default function UserViewScreen(props) {
  const history = useHistory();
  const userData = useSelector(state => state.user.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const [loaded, setLoaded] = useState(false);
  const [tableKey, setTableKey] = useState(Math.random().toString());
  const [changePasswordModal, setChangePasswordModal] = useState(null);

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = ToolPermission.hasPermission(userPermissionsData, 'user', 'put');
        const canRemove = ToolPermission.hasPermission(userPermissionsData, 'user', 'delete');
        const canChangePasswordCrossUser = ToolPermission.hasPermission(userPermissionsData, 'user', 'put:xu');
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    history.push(`/user/edit/${record.id}`);
                    break;
                  }
                  case 'change-password': {
                    setChangePasswordModal({
                      userId: record.id,
                      visible: true,
                      key: Math.random().toString(),
                    });
                    break;
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('userViewScreenActionRemoveConfirm'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/v1/user/${record.id}`);
                            message.success(I18n.t('userViewScreenActionRemoveSuccessMessage'));
                            setTableKey(Math.random().toString());
                          } catch (err) {
                            message.error(I18n.t('userViewScreenActionRemoveErrorMessage'));
                          }
                        })();
                      },
                    });
                    break;
                  }
                }
              }
            }>
              {canEdit && (
                <Menu.Item key="edit">
                  <EditOutlined />
                  {I18n.t('userViewScreenActionButtonEdit')}
                </Menu.Item>
              )}
              {canChangePasswordCrossUser && (
                <Menu.Item key="change-password">
                  <EditOutlined />
                  {I18n.t('userViewScreenActionButtonChangePassword')}
                </Menu.Item>
              )}
              {canRemove && (
                <Menu.Item key="remove">
                  <CloseOutlined />
                  {I18n.t('userViewScreenActionButtonRemove')}
                </Menu.Item>
              )}
            </Menu>
          }>
            <Button
              type="primary"
              icon=<EllipsisOutlined  />
            />
          </Dropdown>
        ) : null;
      },
    },
    ...(ToolPermission.hasPermission(userPermissionsData, 'system-admin', 'get') ? [
      {
        template: 'id',
        key: 'id',
        width: 40,
        title: 'ID',
      },
    ] : []),
    ...(ToolPermission.hasPermission(userPermissionsData, 'user', 'get:xu') ? [
      {
        key: 'name',
        title: I18n.t('userViewScreenTableName'),
      },
    ] : []),
    {
      key: 'displayName',
      title: I18n.t('userViewScreenTableDisplayName'),
    },
    {
      key: 'locale',
      title: I18n.t('userViewScreenTableLocale'),
      render: value => {
        return {
          'zh-tw': I18n.t('userViewScreenLocaleZhTw'),
          'zh-cn': I18n.t('userViewScreenLocaleZhCn'),
          'en': I18n.t('userViewScreenLocaleZhEn'),
        }[value];
      },
    },
    {
      key: 'email',
      title: I18n.t('userViewScreenTableEmail'),
    },
    {
      key: 'phone',
      title: I18n.t('userViewScreenTablePhone'),
    },
    {
      key: 'roles',
      title: I18n.t('userViewScreenTableRoles'),
      render: (value, record) => {
        return value.join(', ');
      },
    },
  ];

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
    };
  };

  useEffect(() => {
    (async () => {
      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const userRes = await ToolRequest.request('GET', '/v1/user', {
      ...body,
    });
    return userRes;
  };

  return loaded ? (
    <>
      <PageHeader
        title={I18n.t('userViewScreenTitle')}
        extra={[
          ToolPermission.hasPermission(userPermissionsData, 'user', 'post') && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => history.push('/user/add')}
            >
              {I18n.t('userViewScreenButtonAdd')}
            </Button>
          ),
        ]}
      >
        <SuperTable
          showSearchInput
          searchQueryParams={searchQueryParams}
          tableKey={tableKey}
          onTableKeyChange={key => setTableKey(key)}
          loadDataSource={loadDataSource}
          columns={columns}
        />
      </PageHeader>

      {changePasswordModal && <ChangePasswordModal
        key={changePasswordModal.key}
        userId={changePasswordModal.userId}
        visible={changePasswordModal.visible}
        onOk={() => {
          setChangePasswordModal({
            ...changePasswordModal,
            visible: false,
          });
        }}
        onCancel={() => {
          setChangePasswordModal({
            ...changePasswordModal,
            visible: false,
          });
        }}
      />}
    </>
  ) : <SpinFull />;
};
