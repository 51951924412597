import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import {Typography, Descriptions, Card, Row, Col, Steps, Image, Divider, Button} from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPhone from '../../tools/ToolPhone';
import SpinFull from '../../components/SpinFull';
import QRCode from '../../components/QRCode';

const { Title, Text, Paragraph } = Typography;

export default function CellOrderPrintScreen(props) {
  const userData = useSelector(state => state.user.value);
  const [loaded, setLoaded] = useState(false);
  const [orders, setOrders] = useState(null);

  const ascCompare = (propertyName) => {
    return function (object1, object2) {
      let value1 = object1[propertyName];
      let value2 = object2[propertyName];
      if (value1 < value2) {
        return -1;
      }else if (value1 > value2) {
        return 1;
      }else {
        return 0;
      }
    };
  };

  useEffect(() => {
    (async () => {
      const orderRes = await ToolRequest.request('GET', `/v1/cell-order`,{state:'WAIT_REPLENISHMENT'});
      let lockerIds = [];
      for (let order of orderRes.data){
        const lockerCellRes = await ToolRequest.request('GET', `/v1/cell/${order.cellId}`);
        order.lockerCell = lockerCellRes;
        order.lockerCellName = order.lockerCell.name;
        const lockerRes = await ToolRequest.request('GET', `/v1/locker/${lockerCellRes.lockerId}`);
        order.locker = lockerRes;
        if(lockerIds.indexOf(lockerRes.id) === -1){
          lockerIds.push(lockerRes.id);
        }
        order.lockerCode = order.locker.code;
        order.lockerName = order.locker.name[userData.locale];
        order.lockerAddress = order.locker.address[userData.locale];
        const merchantRes = await ToolRequest.request('GET', `/v1/merchant/${order.merchantId}`);
        order.merchant = merchantRes;
      }
      let orderArr = [];
      for (let lockerId of lockerIds){
        let orders = orderRes.data.filter(item=>item.locker.id === lockerId);
        if(orders.length){
          orders.sort(ascCompare('lockerName'));
          orders.sort(ascCompare('lockerCellName'));
          orderArr.push({
            lockerCode:orders[0].lockerCode,
            lockerName:orders[0].lockerName,
            lockerAddress:orders[0].lockerAddress,
            orders
          })
        }
      }
      console.dir(orderRes.data);
      setOrders(orderArr);
      setLoaded(true);
    })();
  }, []);

  return loaded ? orders.map((lockerOrder, i) => {
    return (
      <div style={{pageBreakAfter: 'always'}} key={i}>
        <Title level={3}>{lockerOrder.lockerName} ({lockerOrder.lockerCode})</Title>
        <Paragraph>{lockerOrder.lockerAddress}</Paragraph>

        {lockerOrder.orders.map((order, j) => {
          return (
            <Card
              key={j}
              size="small"
              style={{marginBottom: 4}}
            >
              <Row wrap={false}>
                <Col flex="auto">
                  <Descriptions column={2}>
                    <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderMerchantName')}>
                      {order.merchant.displayName}
                    </Descriptions.Item>
                    <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderMerchantLockerCellName')}>
                      {`${order.locker.name[userData.locale]} / ${order.lockerCell.name}`}
                    </Descriptions.Item>
                    <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderTrackNumber')}>
                      {order.trackNumber || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderRecipientPhone')}>
                      {order.recipientPhone ? ToolPhone.simplify(order.recipientPhone) : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderMerchantRemark')}>
                      {order.merchantRemark || '-'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>

                <Col flex="160px" style={{textAlign: 'center'}}>
                  <Row justify="space-between" align="center">
                    <Col>
                      <Text>{I18n.t('lockerDetailScreenCellOrderQrCodeTitleDeliverShort')}</Text>
                      <div style={{marginTop: 2}}>
                        <QRCode
                          size={64}
                          value={`DELIVER,${order.replenishmentKey}`}
                          type="DELIVER"
                        />
                      </div>
                    </Col>

                    <Col>
                      <Text>{I18n.t('lockerDetailScreenCellOrderQrCodeTitleRecallShort')}</Text>
                      <div style={{marginTop: 2}}>
                        <QRCode
                          size={64}
                          value={`RECALL,${order.recallKey}`}
                          type="RECALL"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          )
        })}
      </div>
    );
  }) : <SpinFull />;
};
