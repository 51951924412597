import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import {
  Modal,
  Form,
  Input,
  Button,
  Cascader,
  Spin,
  Divider,
  Select,
  message,
  InputNumber,
  Alert,
  Typography,
} from 'antd'
import { EditOutlined, PlusOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { TextArea } = Input;
const { Paragraph } = Typography;
const { Option } = Select;

export default function LockerCellOrderAddModal(props) {
  const { merchantId, cellId, visible, onOk, onCancel } = props;
  const userData = useSelector(state => state.user.value);

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPhonePrefix, setSelectedPhonePrefix] = useState('+852');
  const [lockerCellOptions, setLockerCellOptions] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        if(cellId){
          const cellRes = await ToolRequest.request('GET', `/v1/cell/${cellId}`);
          const lockerRes = await ToolRequest.request('GET', `/v1/locker/${cellRes.lockerId}`);

          setLockerCellOptions([
            {
              label: lockerRes.name[userData.locale],
              value: cellRes.lockerId,
              children: [
                {
                  label: cellRes.name,
                  value: cellId,
                },
              ],
            },
          ]);
        }
      } catch (err) {
      }

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    Modal.confirm({
      title: I18n.t('lockerCellOrderAddModalConfirmTitle'),
      content: (
        <div>
          <Paragraph>{I18n.t('lockerCellOrderAddModalConfirmContent')}</Paragraph>
          <Paragraph strong>
            {I18n.t('lockerCellOrderAddModalConfirmContentTrackNumber', {
              trackNumber: values.trackNumber || '-',
            })}
          </Paragraph>
          <Paragraph strong>
            {I18n.t('lockerCellOrderAddModalConfirmContentRecipientPhone', {
              recipientPhone: values.recipientPhone,
            })}
          </Paragraph>
        </div>
      ),
      onOk: async () => {
        setLoading(true);

        try {
          const data = {
            cellId,
            recipientPhone: values.recipientPhone ? `${selectedPhonePrefix}-${values.recipientPhone}` : null,
            merchantRemark: values.merchantRemark,
            trackNumber: values.trackNumber,
            recipientExpiryHour: values.recipientExpiryHour
          };

          const res = await ToolRequest.request('POST', '/v1/cell-order', data);

          message.success(I18n.t('lockerCellOrderAddModalAddSuccessMessage'));

          onOk();
        } catch (err) {
          setLoading(false);

          if (err?.response?.data) {
            switch (err.response.data) {
              default:
                return message.error(I18n.t('errorMessageUnknownError'));
            }
          }

          message.error(I18n.t('errorMessageUnknownError'));
        }
      },
    });
  };

  return (
    <Modal
      title={I18n.t('lockerCellOrderAddModalTitle')}
      visible={visible}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={{
              lockerCell: lockerCellOptions ? [lockerCellOptions[0].value, lockerCellOptions[0].children[0].value] : null,
              recipientExpiryHour: 24,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label={I18n.t('lockerCellOrderAddModalLockerCell')}
              name="lockerCell"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Cascader
                placeholder={I18n.t('lockerCellOrderAddModalLockerCell')}
                disabled
                options={lockerCellOptions}
              />
            </Form.Item>

            <Divider />

            <Form.Item
              label={I18n.t('lockerCellOrderAddModalTrackNumber')}
              name="trackNumber"
            >
              <Input
                placeholder={I18n.t('lockerCellOrderAddModalTrackNumber')}
              />
            </Form.Item>

            <Form.Item
              label={I18n.t('lockerCellOrderAddModalRecipientPhone')}
              name="recipientPhone"
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator: async (_, value) => {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (!value.match(/^\d{8}$/)) {
                      return Promise.reject(new Error(I18n.t('lockerCellOrderAddModalRecipientPhoneInvalidMessage')));
                    }
                  },
                }),
              ]}
            >
              <Input
                addonBefore={(
                  <Select value={selectedPhonePrefix} onChange={value => setSelectedPhonePrefix(value)}>
                    <Option value="+852">+852</Option>
                  </Select>
                )}
                placeholder={I18n.t('lockerCellOrderAddModalRecipientPhone')}
              />
            </Form.Item>

            <Form.Item
              {...formTailLayout}
            >
              <Alert
                message={I18n.t('lockerCellOrderAddModalRecipientPhoneReminderMessage')}
                type="warning"
                showIcon
              />
            </Form.Item>

            <Form.Item
              label={I18n.t('lockerCellOrderAddModalRecipientExpiryHour')}
              name="recipientExpiryHour"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                min={1}
                placeholder={I18n.t('lockerCellOrderAddModalRecipientExpiryHour')}
              />
            </Form.Item>

            <Divider />

            <Form.Item
              label={I18n.t('lockerCellOrderAddModalMerchantRemark')}
              name="merchantRemark"
            >
              <TextArea
                placeholder={I18n.t('lockerCellOrderAddModalMerchantRemark')}
              />
            </Form.Item>

            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('lockerCellOrderAddModalButtonSubmit')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull />}
    </Modal>
  );
};