import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, Divider, message } from 'antd';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;

export default function MerchantAddScreen(props) {
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const data = {
        name: values.name,
        displayName: values.displayName,
        locale: values.locale[0],
      };

      const res = await ToolRequest.request('POST', '/v1/merchant', data);

      message.success(I18n.t('merchantAddScreenAddSuccessMessage'));

      history.push('/merchant');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('merchantAddScreenTitle')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={{
            locale: ['zh-tw'],
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={I18n.t('merchantAddScreenFormName')}
            name="name"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('merchantAddScreenFormName'),
                }),
              },
            ]}
          >
            <Input
              placeholder={I18n.t('merchantAddScreenFormName')}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('merchantAddScreenFormDisplayName')}
            name="displayName"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('merchantAddScreenFormDisplayName'),
                }),
              },
            ]}
          >
            <Input
              placeholder={I18n.t('merchantAddScreenFormDisplayName')}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('merchantAddScreenFormLocale')}
            name="locale"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('merchantAddScreenFormLocale'),
                }),
              },
            ]}
          >
            <Cascader
              allowClear={false}
              options={[
                {
                  label: I18n.t('merchantAddScreenFormLocaleEn'),
                  value: 'en',
                },
                {
                  label: I18n.t('merchantAddScreenFormLocaleZhTw'),
                  value: 'zh-tw',
                },
                {
                  label: I18n.t('merchantAddScreenFormLocaleZhCn'),
                  value: 'zh-cn',
                },
              ]}
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {I18n.t('merchantAddScreenButtonSubmit')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
