import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import LockerOnline from '../../components/LockerOnline';

import moment from 'moment';

import { PageHeader, Button, Typography, Descriptions,
  Spin, Card, Badge, Row, Col, Tooltip, Tag, Alert, Steps,
  Collapse, Tabs, Space, Divider, Modal, message,
} from 'antd';
import { ArrowLeftOutlined, CloseOutlined, EditOutlined, DoubleRightOutlined, PlusOutlined, HistoryOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';
import * as ToolPhone from '../../tools/ToolPhone';

import '../../less/LockerDetailScreen.less';
import SpinFull from '../../components/SpinFull';
import LockerHistoricalTemperatureChart from '../../components/LockerHistoricalTemperatureChart';
import LockerCellOrderAddModal from './LockerCellOrderAddModal';
import LockerCellContractAddModal from './LockerCellContractAddModal';
import QRCode from '../../components/QRCode';
import LockerCellOrderEditModal from './LockerCellOrderEditModal';
import LockerHistoricalTemperatureModal from './LockerHistoricalTemperatureModal';
import LockerDetailCellOrderForceModal from './LockerDetailCellOrderForceModal';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title, Text, Paragraph } = Typography;
const { Step } = Steps;
const { Panel } = Collapse;
const { TabPane } = Tabs;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

export function CellContractDetail(props) {
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const { cellId, onUpdate } = props;
  const [loaded, setLoaded] = useState(false);
  const [contract, setContract] = useState(null);

  useEffect(() => {
    (async () => {
      const contractRes = await ToolRequest.request('GET', `/v1/cell-contract`, {
        cellId,
        count: 1,
        status: 'ONGOING',
      });

      if (contractRes.data[0]) {
        const merchantRes = await ToolRequest.request('GET', `/v1/merchant/${contractRes.data[0].merchantId}`);
        setContract({
          ...contractRes.data[0],
          merchant: merchantRes,
        });
      } else {
        setContract(null);
      }

      setLoaded(true);
    })();
  }, []);

  return loaded ? (
    contract ? (
      <Collapse defaultActiveKey={['1']} bordered style={{marginTop: 4}}>
        <Panel key="1" header={I18n.t('lockerDetailScreenCellContractTitle')}>
          <Descriptions
            bordered
            size="small"
            column={1}
          >
            <Descriptions.Item label={I18n.t('lockerDetailScreenCellContractHumanId')}>
              {contract.humanId}
            </Descriptions.Item>
            <Descriptions.Item label={I18n.t('lockerDetailScreenCellContractType')}>
              {{
                'DAY_RENTAL': I18n.t('lockerDetailScreenCellContractTypeDay'),
                'MONTH_RENTAL': I18n.t('lockerDetailScreenCellContractTypeMonth'),
              }[contract.type]}
            </Descriptions.Item>
            <Descriptions.Item label={I18n.t('lockerDetailScreenCellContractMerchantName')}>
              {contract.merchant.displayName}
            </Descriptions.Item>
            <Descriptions.Item label={I18n.t('lockerDetailScreenCellContractCreatedAt')}>
              {moment(contract.createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item label={I18n.t('lockerDetailScreenCellContractStartAt')}>
              {moment(contract.contractStartAt).format('YYYY-MM-DD HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item label={I18n.t('lockerDetailScreenCellContractEndAt')}>
              {moment(contract.contractEndAt).format('YYYY-MM-DD HH:mm:ss')}
            </Descriptions.Item>
            {ToolPermission.hasPermission(userPermissionsData, 'cell-contract', 'get:more') && (
              <Descriptions.Item label={I18n.t('lockerDetailScreenCellContractPrice')}>
                ${(contract.price / 1000000).toFixed(1)}
              </Descriptions.Item>
            )}
          </Descriptions>
          <br/>
          {contract.type === 'DAY_RENTAL' && <Button type="primary" onClick={()=>{
            Modal.confirm({
              title: I18n.t('lockerDetailScreenActionCancelContractConfirmMessage'),
              okType: 'danger',
              onOk: () => {
                (async () => {
                  try {
                    await ToolRequest.request('POST', `/v1/cell-contract/${contract.id}/cancel`);
                    message.success(I18n.t('lockerDetailScreenActionCancelContractSuccessMessage'));
                    onUpdate();
                  } catch (err) {
                    if (err?.response?.data) {
                      switch (err.response.data) {
                        case 'ORDER_NOT_ALLOWED': {
                          return message.error(I18n.t('lockerDetailScreenActionCancelContractNoWaitReplenishmentOrder'));
                        }
                        default:
                          return message.error(I18n.t('errorMessageUnknownError'));
                      }
                    }
                  }
                })();
              },
            });
          }}  icon={<CloseOutlined />} danger>{I18n.t('lockerDetailScreenActionButtonCancelContract')}</Button>}
        </Panel>
      </Collapse>
    ) : <Text type="secondary">{I18n.t('lockerDetailScreenCellContractEmpty')}</Text>
  ) : <SpinFull />;
};

export function CellOrderDetail(props) {
  const { cellId, onUpdate } = props;
  const [loaded, setLoaded] = useState(false);
  const [orders, setOrders] = useState([]);

  const [orderEditModal, setOrderEditModal] = useState({
    key: Math.random().toString(),
    visible: false,
    cellOrderId: null,
  });

  const [orderForceModal, setOrderForceModal] = useState({
    key: Math.random().toString(),
    visible: false,
    cellOrderId: null,
    onOk: () => {},
  });

  const [detailKey, setDetailKey] = useState(Math.random().toString());

  useEffect(() => {
    (async () => {
      const orderRes = await ToolRequest.request('GET', `/v1/cell-order`, {
        cellId,
        state: 'PENDING',
        count: 1000,
      });

      const merchantIds = orderRes.data.map(order => order.merchantId)
        .filter((value, index, self) => self.indexOf(value) === index);

      const merchantRes = await Promise.all(merchantIds.map(merchantId => ToolRequest.request('GET', `/v1/merchant/${merchantId}`)));

      setOrders(orderRes.data.map(order => {
        const merchant = merchantRes.find(merchantItem => merchantItem.id === order.merchantId);
        return {
          ...order,
          merchant,
        };
      }).sort((a, b) => {   // 最新在右邊
        if (a.createdAt < b.createdAt) {
          return -1;
        } else if (a.createdAt > b.createdAt) {
          return 1;
        }
        return 0;
      }));

      setLoaded(true);
    })();
  }, [detailKey]);

  return loaded ? (
    orders.length ? (
      <>
        <Collapse defaultActiveKey={['1']} bordered>
          <Panel key="1" header={I18n.t('lockerDetailScreenCellOrderTitle')} extra={<Badge count={orders.length} />}>
            <Tabs type="card">
              {orders.map((order, i) => {
                let stateStep = 0;
                if (order) {
                  if (order.pickedUpAt) {
                    stateStep = 3;
                  } else if (order.replenishedAt) {
                    stateStep = 1;
                  }
                }

                let orderBadgeStatus;
                let orderBadgeText;

                if (order.state === 'WAIT_PICKUP' && new Date().toISOString() >= order.expiredAt) {
                  orderBadgeStatus = 'error';
                  orderBadgeText = I18n.t('cellOrderViewScreenTableStateWaitPickupExpired');
                } else {
                  orderBadgeStatus = {
                    'WAIT_REPLENISHMENT': 'warning',
                    'WAIT_PICKUP': 'processing',
                    'PICKED_UP': 'success',
                    'CANCELED': 'default',
                    'RECALLED': 'error',
                  }[order.state];

                  orderBadgeText = {
                    'WAIT_REPLENISHMENT': I18n.t('cellOrderViewScreenTableStateWaitReplenishment'),
                    'WAIT_PICKUP': I18n.t('cellOrderViewScreenTableStateWaitPickup'),
                    'PICKED_UP': I18n.t('cellOrderViewScreenTableStatePickedUp'),
                    'CANCELED': I18n.t('cellOrderViewScreenTableStateCanceled'),
                    'RECALLED': I18n.t('cellOrderViewScreenTableStateRecalled'),
                  }[order.state];
                }

                return (
                  <TabPane
                    tab={[
                      <Badge
                        status={orderBadgeStatus}
                        text={order.humanId}
                      />,
                      i === 0 && <Tag color="processing" style={{marginLeft: 8}}>{I18n.t('lockerDetailScreenCellOrderTabNew')}</Tag>,
                    ]}
                    key={order.id}
                  >
                    <Row justify="center" align="center" style={{marginBottom: 16}}>
                      <Col span={24} sm={12}>
                        <Row justify="center" align="center">
                          <Col>
                            <Title level={5}>{I18n.t('lockerDetailScreenCellOrderQrCodeTitlePickup')}</Title>
                          </Col>
                        </Row>
                        <Row justify="center" align="center">
                          <Col>
                            <QRCode
                              value={`PICKUP,${order.pickupKey}`}
                              type="PICKUP"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} sm={12}>
                        <Row justify="center" align="center">
                          <Col>
                            <Title level={5}>{order.state === 'WAIT_REPLENISHMENT' ? I18n.t('lockerDetailScreenCellOrderQrCodeTitleDeliver') : I18n.t('lockerDetailScreenCellOrderQrCodeTitleRecall')}</Title>
                          </Col>
                        </Row>
                        <Row justify="center" align="center">
                          <Col>
                            <QRCode
                              value={order.state === 'WAIT_REPLENISHMENT' ? `DELIVER,${order.replenishmentKey}` : `RECALL,${order.recallKey}`}
                              type={order.state === 'WAIT_REPLENISHMENT' ? 'DELIVER' : 'RECALL'}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Descriptions
                      bordered
                      size="small"
                      column={1}
                    >
                      <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderHumanId')}>
                        {order.humanId}
                      </Descriptions.Item>
                      <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderMerchantName')}>
                        {order.merchant.displayName}
                      </Descriptions.Item>
                      <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderState')}>
                        <Badge
                          status={orderBadgeStatus}
                          text={orderBadgeText}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderTrackNumber')}>
                        {order.trackNumber || '-'}
                      </Descriptions.Item>
                      <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderRecipientPhone')}>
                        {order.recipientPhone ? ToolPhone.simplify(order.recipientPhone) : '-'}
                      </Descriptions.Item>
                      <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderRecipientExpiryHour')}>
                        {order.recipientExpiryHour}
                      </Descriptions.Item>
                      <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderMerchantRemark')}>
                        {order.merchantRemark}
                      </Descriptions.Item>
                      <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderCreatedAt')}>
                        {moment(order.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                      </Descriptions.Item>
                      <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderExpiredAt')}>
                        {order.expiredAt ? moment(order.expiredAt).format('YYYY-MM-DD HH:mm:ss') : '-'}
                      </Descriptions.Item>
                      <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderShortUrl')}>
                        <Paragraph copyable={{text: order.shortUrl}}>
                          <a href={order.shortUrl} target="_blank">{order.shortUrl}</a>
                        </Paragraph>
                      </Descriptions.Item>
                    </Descriptions>

                    <Title level={5} style={{marginTop: 16}}>
                      {I18n.t('lockerDetailScreenCellStatusTitle')}
                    </Title>
                    <Steps direction="vertical" current={stateStep} style={{padding: 16}}>
                      <Step
                        title={I18n.t('lockerDetailScreenCellStatusCreatedAtTitle')}
                        description={`${I18n.t('lockerDetailScreenCellStatusCreatedAt')} ${moment(order.createdAt).format('YYYY-MM-DD HH:mm:ss')}`}
                      />
                      <Step
                        title={I18n.t('lockerDetailScreenCellStatusDeliveredAtTitle')}
                        description={order.replenishedAt ? `${I18n.t('lockerDetailScreenCellStatusDeliveredAt')} ${moment(order.replenishedAt).format('YYYY-MM-DD HH:mm:ss')}` : I18n.t('lockerDetailScreenCellStatusDeliveredAtEmpty')}
                      />
                      <Step
                        title={I18n.t('lockerDetailScreenCellStatusPickupAtTitle')}
                        description={order.pickedUpAt ? `${I18n.t('lockerDetailScreenCellStatusPickupAt')} ${moment(order.pickedUpAt).format('YYYY-MM-DD HH:mm:ss')}` : I18n.t('lockerDetailScreenCellStatusPickupAtEmpty')}
                      />
                    </Steps>
                    <Space wrap>
                      <Button type="primary" onClick={()=>{
                        setOrderEditModal({
                          key: Math.random().toString(),
                          visible: true,
                          cellOrderId: order.id,
                        })
                      }}  icon={<EditOutlined />}>{I18n.t('lockerDetailScreenActionButtonEditOrder')}</Button>

                      {order.state === 'WAIT_REPLENISHMENT' && (
                        <Button
                          type="default"
                          onClick={()=>{
                            setOrderForceModal({
                              key: Math.random().toString(),
                              visible: true,
                              cellOrderId: order.id,
                              title: I18n.t('lockerDetailScreenActionForceReplenishOrderConfirmTitle'),
                              onOk: (includeSms) => {
                                Modal.confirm({
                                  title: includeSms ? I18n.t('lockerDetailScreenActionForceReplenishOrderConfirmWithSmsMessage')
                                    : I18n.t('lockerDetailScreenActionForceReplenishOrderConfirmWithoutSmsMessage'),
                                  okType: 'danger',
                                  onOk: () => {
                                    (async () => {
                                      try {
                                        await ToolRequest.request('POST', `/v1/cell-order/${order.id}/force-replenish`, {
                                          includeSms,
                                        });
                                        message.success(I18n.t('lockerDetailScreenActionForceReplenishOrderSuccessMessage'));
                                        onUpdate();
                                      } catch (err) {
                                        message.error(I18n.t('lockerDetailScreenActionForceReplenishOrderErrorMessage'));
                                      }
                                    })();
                                  },
                                });
                              },
                            });
                          }}
                          icon={<ExclamationCircleOutlined />}
                          danger
                        >
                          {I18n.t('lockerDetailScreenActionButtonForceReplenishOrder')}
                        </Button>
                      )}

                      {order.state === 'WAIT_PICKUP' && (
                        <Button
                          type="default"
                          onClick={()=>{
                            Modal.confirm({
                              title: I18n.t('lockerDetailScreenActionForcePickupOrderConfirmMessage'),
                              okType: 'danger',
                              onOk: () => {
                                (async () => {
                                  try {
                                    await ToolRequest.request('POST', `/v1/cell-order/${order.id}/force-pickup`);
                                    message.success(I18n.t('lockerDetailScreenActionForcePickupOrderSuccessMessage'));
                                    onUpdate();
                                  } catch (err) {
                                    message.error(I18n.t('lockerDetailScreenActionForcePickupOrderErrorMessage'));
                                  }
                                })();
                              },
                            });
                          }}
                          icon={<ExclamationCircleOutlined />}
                          danger
                        >
                          {I18n.t('lockerDetailScreenActionButtonForcePickupOrder')}
                        </Button>
                      )}

                      {order.state === 'WAIT_PICKUP' && (
                        <Button
                          type="default"
                          onClick={()=>{
                            setOrderForceModal({
                              key: Math.random().toString(),
                              visible: true,
                              cellOrderId: order.id,
                              title: I18n.t('lockerDetailScreenActionForceRecallOrderConfirmTitle'),
                              onOk: (includeSms) => {
                                Modal.confirm({
                                  title: includeSms ? I18n.t('lockerDetailScreenActionForceRecallOrderConfirmWithSmsMessage')
                                    : I18n.t('lockerDetailScreenActionForceRecallOrderConfirmWithoutSmsMessage'),
                                  okType: 'danger',
                                  onOk: () => {
                                    (async () => {
                                      try {
                                        await ToolRequest.request('POST', `/v1/cell-order/${order.id}/force-recall`, {
                                          includeSms,
                                        });
                                        message.success(I18n.t('lockerDetailScreenActionForceRecallOrderSuccessMessage'));
                                        onUpdate();
                                      } catch (err) {
                                        message.error(I18n.t('lockerDetailScreenActionForceRecallOrderErrorMessage'));
                                      }
                                    })();
                                  },
                                });
                              },
                            });
                          }}
                          icon={<ExclamationCircleOutlined />}
                          danger
                        >
                          {I18n.t('lockerDetailScreenActionButtonForceRecallOrder')}
                        </Button>
                      )}

                      {order.state === 'WAIT_REPLENISHMENT' && (
                        <Button
                          type="primary"
                          onClick={()=>{
                            Modal.confirm({
                              title: I18n.t('lockerDetailScreenActionCancelOrderConfirmMessage'),
                              okType: 'danger',
                              onOk: () => {
                                (async () => {
                                  try {
                                    await ToolRequest.request('POST', `/v1/cell-order/${order.id}/cancel`);
                                    message.success(I18n.t('lockerDetailScreenActionCancelOrderSuccessMessage'));
                                    onUpdate();
                                  } catch (err) {
                                    message.error(I18n.t('lockerDetailScreenActionCancelOrderErrorMessage'));
                                  }
                                })();
                              },
                            });
                          }}
                          icon={<CloseOutlined />}
                          danger
                        >
                          {I18n.t('lockerDetailScreenActionButtonCancelOrder')}
                        </Button>
                      )}
                    </Space>
                  </TabPane>
                );
              })}
            </Tabs>
          </Panel>
          {orderEditModal.cellOrderId && <LockerCellOrderEditModal
            key={orderEditModal.key}
            visible={orderEditModal.visible}
            cellOrderId={orderEditModal.cellOrderId}
            onOk={() => {
              setOrderEditModal({
                ...orderEditModal,
                visible: false,
              });
              setDetailKey(Math.random().toString())
            }}
            onCancel={() => {
              setOrderEditModal({
                ...orderEditModal,
                visible: false,
              });
            }}
          />}
        </Collapse>

        <LockerDetailCellOrderForceModal
          key={orderForceModal.key}
          title={orderForceModal.title}
          visible={orderForceModal.visible}
          onOk={orderForceModal.onOk}
          onCancel={() => {
            setOrderForceModal({
              ...orderForceModal,
              visible: false,
            });
          }}
        />
      </>
    ) : <Text type="secondary">{I18n.t('lockerDetailScreenCellOrderEmpty')}</Text>
  ) : <SpinFull />;
};

export default function LockerDetailScreen(props) {
  const history = useHistory();
  const { lockerId } = useParams();
  const userData = useSelector(state => state.user.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const [loaded, setLoaded] = useState(false);
  const [locker, setLocker] = useState(null);
  const [cells, setCells] = useState(null);
  const [selectedCellId, setSelectedCellId] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [isLoadingSelectedCell, setIsLoadingSelectedCell] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [detailKey, setDetailKey] = useState(Math.random().toString());
  const [latestTemperature, setLatestTemperature] = useState(null);
  const [lockerHistoricalTemperatureModal, setLockerHistoricalTemperatureModal] = useState({
    key: Math.random().toString(),
    visible: false
  });

  const [orderModal, setOrderModal] = useState({
    key: Math.random().toString(),
    visible: false,
    cellId: null,
  });

  const [contractModal, setContractModal] = useState({
    key: Math.random().toString(),
    visible: false,
    cellId: null,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    (async () => {
      const lockerRes = await ToolRequest.request('GET', `/v1/locker/${lockerId}`);
      setLocker(lockerRes);

      const cellRes = await ToolRequest.request('GET', `/v1/cell`, {
        lockerId,
        count: 1000,
      });
      setCells(cellRes.data);

      const temperatureRes = await ToolRequest.request('GET', '/v1/locker-temperature-record', {
        capturedStartAt: moment().subtract(30,'minutes').toISOString(),
        lockerId: lockerId,
        count: 1,
      });

      if (temperatureRes.data.length && temperatureRes.data[0].temperature) {
        setLatestTemperature(Number(temperatureRes.data[0].temperature).toFixed(1));
      }

      setLoaded(true);
    })();
  }, [detailKey]);

  useEffect(() => {
    if (selectedCellId) {
      setIsLoadingSelectedCell(true);

      (async () => {
        const cellRes = await ToolRequest.request('GET', `/v1/cell/${selectedCellId}`);

        if (cellRes.activeContract && cellRes.activeContract.id) {
          const cellContractRes = await ToolRequest.request('GET', `/v1/cell-contract/${cellRes.activeContract.id}`)
          cellRes.activeContract = cellContractRes;
        }
        setSelectedCell(cellRes);

        setIsLoadingSelectedCell(false);
      })();
    }
  }, [selectedCellId, detailKey]);

  // 計算格子渲染
  const windowScale = Math.min(windowDimensions.width, windowDimensions.height) * .18;

  let layoutWidthTotal = 0;
  const layoutSideWidths = [];
  const layoutRowTotalWidths = [];
  const layoutRowHeights = [];
  let layoutSideHeightMax = 0;

  if (loaded) {
    for (let side of locker.cellLayout) {
      let sideWidthMax = 0;
      let sideHeight = 0;
      const rowTotalWidths = [];
      const rowHeights = [];

      for (let row of side) {
        let rowWidth = 0;
        let rowHeightMax = 0;

        for (let col of row) {
          const cell = cells.find(cellItem => cellItem.name === col);

          const colWidth = cell ? cell.width : 150;
          const colHeight = cell ? cell.height : 150;

          rowWidth += colWidth;
          if (colHeight > rowHeightMax) {
            rowHeightMax = colHeight;
          }
        }

        if (rowWidth > sideWidthMax) {
          sideWidthMax = rowWidth;
        }
        sideHeight += rowHeightMax;
        rowTotalWidths.push(rowWidth);
        rowHeights.push(rowHeightMax);
      }

      layoutRowTotalWidths.push(rowTotalWidths);
      layoutRowHeights.push(rowHeights);

      layoutWidthTotal += sideWidthMax;
      layoutSideWidths.push(sideWidthMax);
      if (sideHeight > layoutSideHeightMax) {
        layoutSideHeightMax = sideHeight;
      }
    }
  }

  // cell size list
  let cellSizes = null;

  if (cells) {
    const sizes = {};
    for (let cell of cells) {
      const key = `${cell.width} x ${cell.height} x ${cell.depth}`;
      if (!sizes[key]) {
        sizes[key] = [];
      }
      sizes[key].push(cell);
    }

    cellSizes = Object.keys(sizes).sort().map(size => {
      return {
        size,
        cells: sizes[size].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),
      };
    });
  }

  const isSystemAdmin = userData.roles.indexOf('SYSTEM_ADMIN') !== -1;

  return loaded ? (
    <>
      <PageHeader
        onBack={() => history.goBack()}
        title={I18n.t('lockerDetailScreenTitle')}
      >
        <Card>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Title level={5}>{locker.name[userData.locale]} ({locker.code})</Title>
              <Descriptions
                size="small"
                column={1}
              >
                <Descriptions.Item label={I18n.t('lockerDetailScreenLockerAddress')}>
                  {locker.address[userData.locale]}
                </Descriptions.Item>
                {locker.serviceHour && (
                  <Descriptions.Item label={I18n.t('lockerDetailScreenLockerServiceHour')}>
                    {locker.serviceHour[userData.locale]}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label={I18n.t('lockerDetailScreenLockerStatus')}>
                  <LockerOnline
                    key={locker.id}
                    lockerId={locker.id}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={I18n.t('lockerDetailScreenLockerNoOfCell')}>
                  <Tooltip title={cellSizes && cellSizes.map(cellSize => {
                    return `${cellSize.size} (${cellSize.cells.length})`;
                  }).join('; ')}>
                    {locker.cellLayout.reduce((count, row) => count + row.reduce((count2, col) => count2 + col.length, 0), 0)}
                  </Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label={I18n.t('lockerDetailScreenLockerLatestTemperature')}>
                  {latestTemperature === null ? (
                    <Badge
                      status="error"
                      text={I18n.t('lockerDetailScreenLockerNoLatestTemperatureLabel')}
                    />
                  ) : (
                    <Badge
                      status={latestTemperature < 8 ? 'success' : 'error'}
                      text={`${latestTemperature} °C`}
                    />
                  )}
                  <Tooltip
                    title={I18n.t('lockerViewScreenTableClickToViewHistoricalTemperature')}
                  >
                    <Button
                      type="link"
                      size="small"
                      onClick={() =>{
                        setLockerHistoricalTemperatureModal({
                          ...lockerHistoricalTemperatureModal,
                          key: Math.random().toString(),
                          visible: true,
                        });
                      }}
                      style={{marginLeft: 4}}
                    >
                      <HistoryOutlined />
                    </Button>
                  </Tooltip>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24} sm={12}>
              <div className="lockerHistoricalTemperatureChartContainer">
                <LockerHistoricalTemperatureChart
                  lockerId={locker.id}
                  simple={true}
                  style={{width: '100%', height: 150}}
                  timespan={48}
                />
              </div>
            </Col>
          </Row>
        </Card>

        {ToolPermission.hasPermission(userPermissionsData, 'locker-admin', 'post') && (
          <Card size="small" style={{marginTop: 8, borderColor: '#ff4d4f'}}>
            <Space wrap>
              <span>{I18n.t('lockerDetailScreenAdminTitle')}</span>
              <Button
                type="primary"
                danger
                onClick={() => {
                  Modal.confirm({
                    title: I18n.t('lockerDetailScreenAdminButtonRebootModalTitle'),
                    okType: 'danger',
                    onOk: () => {
                      (async () => {
                        try {
                          await ToolRequest.request('POST', `/v1/locker-admin/reboot`, {
                            lockerId: locker.id,
                          });
                          message.success(I18n.t('lockerDetailScreenAdminButtonRebootSuccessMessage'));
                        } catch (err) {
                          if (err?.response?.data) {
                            switch (err.response.data) {
                              case 'SYSTEM_ERROR': {
                                return message.error(I18n.t('lockerDetailScreenAdminButtonRebootErrorMessageSystemError'));
                              }
                              default:
                                return message.error(I18n.t('errorMessageUnknownError'));
                            }
                          }
                        }
                      })();
                    },
                  });
                }}
              >
                {I18n.t('lockerDetailScreenAdminButtonReboot')}
              </Button>
            </Space>
          </Card>
        )}

        {cells.length && (
          <Row gutter={16} style={{marginTop: 16}} wrap={false} key={detailKey} className="lockerCellLayoutRow">
            <Col flex="none" className="lockerCellLayoutColLeft">
              <div className="lockerCellLayoutContainer">
                <Row type="flex" gutter={4} align="bottom">
                  {locker.cellLayout.map((sideLayout, side) => {
                    return (
                      <Col key={side}>
                        {sideLayout.map((row, i) => {
                          return (
                            <Row type="flex" align="middle" justify="space-between" key={i}>
                              {row.map((col, j) => {
                                const cell = cells.find(cellItem => cellItem.name === col);

                                return (
                                  <Col key={j}>
                                    <Tooltip
                                      placement="left"
                                      title={cell ? `${cell.width} x ${cell.height} x ${cell.depth}` : `(${I18n.t('lockerDetailScreenLockerCellError')})`}
                                    >
                                      <Button
                                        className={[
                                          'cell',
                                          ...(cell && selectedCellId === cell.id ? ['active'] : []),
                                          ...(cell && !cell.activeContract ? ['can-rent'] : (
                                            cell && (cell.activeContract.merchantId === userData.merchantId || userData.roles.indexOf('SYSTEM_ADMIN') > -1) && !cell.isOccupied ? ['can-use'] : (
                                              (cell.activeContract.merchantId === userData.merchantId || userData.roles.indexOf('SYSTEM_ADMIN') > -1) ? ['used'] : ['occupied']
                                            )
                                          )),
                                        ]}
                                        block
                                        type="default"
                                        onClick={() => {
                                          setSelectedCellId(cell.id);
                                        }}
                                        disabled={!cell}
                                        style={{
                                          width: (cell ? cell.width : 150) / layoutRowTotalWidths[side][i] * layoutSideWidths[side] * layoutSideWidths[side] / layoutWidthTotal * .004 * windowScale,
                                          height: layoutRowHeights[side][i] * .002 * windowScale,
                                        }}
                                      >
                                        {col}
                                      </Button>
                                    </Tooltip>
                                  </Col>
                                );
                              })}
                            </Row>
                          )})}
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>

            <Col flex="auto" className="lockerCellLayoutColRight">
              <Card>
                <Spin spinning={isLoadingSelectedCell}>
                  {!selectedCell ? (
                    <Text type="secondary"><ArrowLeftOutlined /> {I18n.t('lockerDetailScreenLockerSelectCellLabel')}</Text>
                  ) : (
                    <>
                      <Descriptions
                        title={(
                          <>
                            <span>{locker.name[userData.locale]}</span>
                            <DoubleRightOutlined style={{fontSize: 12, marginLeft: 4, marginRight: 4, color: '#AAA'}} />
                            <span>{selectedCell.name}</span>
                          </>
                        )}
                        size="small"
                        column={1}
                      >

                        {ToolPermission.hasPermission(userPermissionsData, 'locker-admin', 'post') && (
                          <Descriptions.Item>
                            <Card size="small" style={{borderColor: '#ff4d4f', width: '100%'}}>
                              <Space wrap>
                                <span>{I18n.t('lockerDetailScreenAdminTitle')}</span>

                                <Tooltip title={I18n.t('lockerDetailScreenAdminButtonOpenSlotTooltip')}>
                                  <Button
                                    type="primary"
                                    danger
                                    onClick={() => {
                                      Modal.confirm({
                                        title: I18n.t('lockerDetailScreenAdminButtonOpenSlotModalTitle'),
                                        okType: 'danger',
                                        onOk: () => {
                                          (async () => {
                                            try {
                                              await ToolRequest.request('POST', `/v1/locker-admin/open-slot`, {
                                                lockerId: locker.id,
                                                cellId: selectedCell.id,
                                              });
                                              message.success(I18n.t('lockerDetailScreenAdminButtonOpenSlotSuccessMessage'));
                                            } catch (err) {
                                              if (err?.response?.data) {
                                                switch (err.response.data) {
                                                  case 'SYSTEM_ERROR': {
                                                    return message.error(I18n.t('lockerDetailScreenAdminButtonOpenSlotErrorMessageSystemError'));
                                                  }
                                                  default:
                                                    return message.error(I18n.t('errorMessageUnknownError'));
                                                }
                                              }
                                            }
                                          })();
                                        },
                                      });
                                    }}
                                  >
                                    {I18n.t('lockerDetailScreenAdminButtonOpenSlot')}
                                  </Button>
                                </Tooltip>

                                <Tooltip title={I18n.t('lockerDetailScreenAdminButtonForceOpenSlotTooltip')}>
                                  <Button
                                    type="primary"
                                    danger
                                    onClick={() => {
                                      Modal.confirm({
                                        title: I18n.t('lockerDetailScreenAdminButtonForceOpenSlotModalTitle'),
                                        okType: 'danger',
                                        onOk: () => {
                                          (async () => {
                                            try {
                                              await ToolRequest.request('POST', `/v1/locker-admin/force-open-slot`, {
                                                lockerId: locker.id,
                                                cellId: selectedCell.id,
                                              });
                                              message.success(I18n.t('lockerDetailScreenAdminButtonForceOpenSlotSuccessMessage'));
                                            } catch (err) {
                                              if (err?.response?.data) {
                                                switch (err.response.data) {
                                                  case 'SYSTEM_ERROR': {
                                                    return message.error(I18n.t('lockerDetailScreenAdminButtonForceOpenSlotErrorMessageSystemError'));
                                                  }
                                                  default:
                                                    return message.error(I18n.t('errorMessageUnknownError'));
                                                }
                                              }
                                            }
                                          })();
                                        },
                                      });
                                    }}
                                  >
                                    {I18n.t('lockerDetailScreenAdminButtonForceOpenSlot')}
                                  </Button>
                                </Tooltip>
                              </Space>
                            </Card>
                          </Descriptions.Item>
                        )}

                        <Descriptions.Item>
                          {!selectedCell.activeContract ? (
                            <Alert
                              style={{width: '100%'}}
                              type="info"
                              message={I18n.t('lockerDetailScreenLockerAlertCanRent')}
                              action={
                                <Button
                                  type="primary"
                                  icon={<PlusOutlined />}
                                  onClick={() => {
                                    setContractModal({
                                      ...contractModal,
                                      key: Math.random().toString(),
                                      visible: true,
                                      cellId: selectedCellId,
                                    });
                                  }}
                                >
                                  {I18n.t('lockerDetailScreenLockerButtonDayRental')}
                                </Button>
                              }
                              showIcon
                            />
                          ) : ((selectedCell.activeContract.merchantId === userData.merchantId || isSystemAdmin) && selectedCell.activeContract.type === 'MONTH_RENTAL' || (selectedCell.activeContract.merchantId === userData.merchantId || isSystemAdmin) && !selectedCell.isOccupied ? (
                            <Alert
                              style={{width: '100%'}}
                              type="success"
                              message={isSystemAdmin ? I18n.t('lockerDetailScreenLockerAlertCanOrderSystemAdmin') : I18n.t('lockerDetailScreenLockerAlertCanOrder')}
                              action={
                                <Button
                                  type="primary"
                                  icon={<PlusOutlined />}
                                  onClick={() => {
                                    setOrderModal({
                                      ...orderModal,
                                      key: Math.random().toString(),
                                      visible: true,
                                      cellId: selectedCellId,
                                    });
                                  }}
                                  disabled={isSystemAdmin}
                                >
                                  {I18n.t('lockerDetailScreenLockerButtonOrder')}
                                </Button>
                              }
                              showIcon
                            />
                          ) : ((selectedCell.activeContract.merchantId === userData.merchantId || isSystemAdmin) ? (
                            <Alert
                              style={{width: '100%'}}
                              type="warning"
                              message={isSystemAdmin ? I18n.t('lockerDetailScreenLockerAlertHaveOrderSystemAdmin') : I18n.t('lockerDetailScreenLockerAlertHaveOrder')}
                              showIcon
                            />
                          ) : (
                            <Alert
                              style={{width: '100%'}}
                              type="error"
                              message={I18n.t('lockerDetailScreenLockerAlertOccupied')}
                              showIcon
                            />
                          )))}
                        </Descriptions.Item>
                        <Descriptions.Item label={I18n.t('lockerDetailScreenCellSize')}>
                          {selectedCell.width} x {selectedCell.height} x {selectedCell.depth}
                        </Descriptions.Item>
                        {ToolPermission.hasPermission(userPermissionsData, 'cell', 'get:more') && (
                          <Descriptions.Item label={I18n.t('lockerDetailScreenCellPrice')}>
                            ${(selectedCell.price / 1000000).toFixed(1)}
                          </Descriptions.Item>
                        )}
                      </Descriptions>

                      {selectedCell.activeContract && selectedCell.isOccupied && (
                        <CellOrderDetail
                          key={`detail-${selectedCellId}`}
                          cellId={selectedCellId}
                          onUpdate={()=>setDetailKey(Math.random().toString())}
                        />
                      )}

                      {selectedCell.activeContract && selectedCell.activeContract.id && (
                        <CellContractDetail
                          key={`contract-${selectedCellId}`}
                          cellId={selectedCellId}
                          cellContract={selectedCell.activeContract}
                          onUpdate={()=>setDetailKey(Math.random().toString())}
                        />
                      )}
                    </>
                  )}
                </Spin>
              </Card>
            </Col>
          </Row>
        )}
      </PageHeader>

      <LockerCellOrderAddModal
        key={orderModal.key}
        visible={orderModal.visible}
        cellId={orderModal.cellId}
        merchantId={userData.merchantId}
        onOk={() => {
          setDetailKey(Math.random().toString());

          setOrderModal({
            ...orderModal,
            visible: false,
          });
        }}
        onCancel={() => {
          setOrderModal({
            ...orderModal,
            visible: false,
          });
        }}
      />

      <LockerCellContractAddModal
        key={contractModal.key}
        visible={contractModal.visible}
        cellId={contractModal.cellId}
        merchantId={userData.merchantId}
        onOk={() => {
          setDetailKey(Math.random().toString());

          setContractModal({
            ...contractModal,
            visible: false,
          });
        }}
        onCancel={() => {
          setContractModal({
            ...contractModal,
            visible: false,
          });
        }}
      />

      <LockerHistoricalTemperatureModal
        key={lockerHistoricalTemperatureModal.key}
        visible={lockerHistoricalTemperatureModal.visible}
        lockerId={lockerId}
        onCancel={() => {
          setLockerHistoricalTemperatureModal({
            ...lockerHistoricalTemperatureModal,
            visible: false,
          });
        }}
      />
    </>
  ) : <SpinFull />;
};
