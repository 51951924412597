import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { Layout } from 'antd';
import { HomeOutlined, ClusterOutlined, GiftOutlined, NotificationOutlined,FileOutlined,DollarCircleOutlined,UnorderedListOutlined,
  UserOutlined, ShopOutlined, MoneyCollectOutlined, SettingOutlined, GlobalOutlined } from '@ant-design/icons';

import * as ToolDevice from '../../tools/ToolDevice';
import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import AppMenu from './AppMenu';
import AppHeader from './AppHeader';
import AppContent from './AppContent';

import '../../less/App.less';

export default function AppScreen(props) {
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const [menuCollapsed, setMenuCollapsed] = useState(ToolDevice.isMobile());

  const menu = [
    {
      key: '/',
      title: I18n.t('appMenuHome'),
      icon: <HomeOutlined />,
      match: '^\/$',
    },
    {
      key: '/locker',
      title: I18n.t('appMenuLocker'),
      icon: <ClusterOutlined />,
      sub: [
        {
          key: '/locker',
          title: I18n.t('appMenuLockerList'),
          match: '^\/locker',
        },
        {
          key: '/region',
          title: I18n.t('appMenuRegionList'),
          match: '^\/region',
        },
      ],
    },
    ...(ToolPermission.hasPermission(userPermissionsData, 'merchant', 'get:xm') ? [
      {
        key: '/merchant',
        title: I18n.t('appMenuMerchant'),
        icon: <ShopOutlined />,
        sub: [
          {
            key: '/merchant',
            title: I18n.t('appMenuHomeMerchantList'),
            match: '^\/merchant',
          },
          {
            key: '/cell-contract',
            title: I18n.t('appMenuHomeContractList'),
            match: '^\/cell-contract',
          },
          {
            key: '/cell-order',
            title: I18n.t('appMenuHomeOrderList'),
            match: '^\/cell-order'
          },
        ],
      },
    ] : []),
    {
      key: '/user',
      title: I18n.t('appMenuUser'),
      icon: <UserOutlined />,
      match: '^\/user'
    },
    ...(ToolPermission.hasPermission(userPermissionsData, 'system-admin', 'get') ? [] : [
      {
        key: '/cell-order',
        title: I18n.t('appMenuMyOrder'),
        icon: <MoneyCollectOutlined />,
        match: '^\/cell-order'
      },
      {
        key: '/cell-contract',
        title: I18n.t('appMenuMyContract'),
        icon: <FileOutlined />,
        match: '^\/cell-contract'
      },
      ...(ToolPermission.hasPermission(userPermissionsData, 'merchant-transaction', 'get') ? [
        {
          key: '/merchant-bill',
          title: I18n.t('appMenuMyBill'),
          icon: <DollarCircleOutlined />,
          match: '^\/merchant-bill'
        },
      ] : []),
    ]),
    ...(ToolPermission.hasPermission(userPermissionsData, 'merchant-transaction', 'get') ? [
      {
        key: '/merchant-transaction',
        title: I18n.t('appMenuTransactionList'),
        icon: <UnorderedListOutlined />,
        match: '^\/merchant-transaction'
      },
    ] : []),
  ];

  return (
    <Layout id="mainLayout">
      <AppMenu
        collapsed={menuCollapsed}
        setCollapsed={setMenuCollapsed}
        menu={menu}
      />
      <Layout>
        <AppHeader
          collapsed={menuCollapsed}
          setCollapsed={setMenuCollapsed}
        />
        <AppContent />
      </Layout>
    </Layout>
  );
}
