import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken } from 'firebase/messaging';

let app;
let analytics;

export const init = () => {
  const firebaseConfig = window.getConfig().firebase;

  app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
};

export const getPushToken = async () => {
  if (!app) {
    throw new Error('Not inited yet');
  }

  const messaging = getMessaging();
  const token = await getToken(messaging, {
    vapidKey: window.getConfig().firebasePushKey,
  });

  return token;
};
