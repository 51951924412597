import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {I18n, setLocale} from 'react-redux-i18n';
import * as ToolRequest from '../../tools/ToolRequest';
import SpinFull from '../../components/SpinFull';
import ReactMarkdown from 'react-markdown'

import { updateMessage } from '../../reducers/messageSlice';

import { Modal, Spin, Badge} from 'antd'
import SuperTable from '../../components/SuperTable'

export default function MerchantMessageModal(props) {
  const dispatch = useDispatch();
  const messageData = useSelector(state => state.message.value);

  const { merchantMessageId, visible, onOk, onCancel } = props;
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableKey, setTableKey] = useState(Math.random().toString());
  const [expandedRowKeys, setExpandedRowKeys] = useState(merchantMessageId ? [merchantMessageId] : []);

  const columns = [
    {
      key: 'title',
      title: I18n.t('appHeaderMerchantMessageModalTableTitle')
    },
    {
      key: 'isUnread',
      title: I18n.t('appHeaderMerchantMessageModalTableState'),
      render: (value, record) => {
        if(value){
          return <Badge status="warning" text={I18n.t('appHeaderMerchantMessageModalTableStateUnread')} />;
        }else {
          return <Badge status="success" text={I18n.t('appHeaderMerchantMessageModalTableStateRead')}/>;
        }
      }
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('appHeaderMerchantMessageModalTableCreatedAt'),
    },
  ];

  useEffect(() => {
    (async () => {
      if (merchantMessageId) {
        await readMessage(merchantMessageId);
      }
      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const merchantMessageRes = await ToolRequest.request('GET', `/v1/merchant-message`);
    return {
      data: merchantMessageRes.data,
      totalCount: merchantMessageRes.totalCount,
    };
  };

  const readMessage = async (messageId) => {
    try {
      const readRes = await ToolRequest.request('POST', `/v1/merchant-message/${messageId}/read`);
      setTableKey(Math.random().toString());

      if (readRes.hasRead) {
        dispatch(updateMessage({
          merchantMessageUnread: messageData.merchantMessageUnread - 1,
        }));
      }
    } catch (err) {
    }
  };

  return (
    <Modal
      title={I18n.t('appHeaderMerchantMessageModalTitle')}
      visible={visible}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <SuperTable
            tableKey={tableKey}
            expandable={{
              expandedRowRender: record => <ReactMarkdown>{record.content}</ReactMarkdown>,
              rowExpandable: record => true,
              expandRowByClick: true,
              expandedRowKeys,
              onExpand: (expanded, record) => {
                if (expanded && record.isUnread) {
                  readMessage(record.id);
                }
              },
              onExpandedRowsChange: (expandedRows) => {
                setExpandedRowKeys(expandedRows);
              },
            }}
            onTableKeyChange={key => setTableKey(key)}
            loadDataSource={loadDataSource}
            columns={columns}
          />
        </Spin>
      ) : <SpinFull />}
    </Modal>
  );
};