import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Spin, ConfigProvider } from 'antd';
import { setLocale } from 'react-redux-i18n';
import { useSelector, useDispatch } from 'react-redux';

import LoginScreen from '../Login/LoginScreen';
import CellOrderPrintScreen from '../CellOrder/CellOrderPrintScreen';
import AppScreen from '../App/AppScreen';

import { updateMetadata } from '../../reducers/metadataSlice';
import { updateUser } from '../../reducers/userSlice';
import { updateUserPermissions } from '../../reducers/userPermissionsSlice';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolUser from '../../tools/ToolUser';

import '../../less/SplashScreen.less';

import enUS from 'antd/es/locale/en_US';
import zhTW from 'antd/es/locale/zh_TW';
import zhCN from 'antd/es/locale/zh_CN';

import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';

export default function SplashScreen(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector(state => state.user.value);

  const [loaded, setLoaded] = useState(false);

  let locale = enUS;
  if (userData) {
    switch (userData.locale) {
      case 'zh-tw':
        locale = zhTW;
        break;
      case 'zh-cn':
        locale = zhCN;
        break;
    }
  }

  useEffect(() => {
    Promise.all([
      ToolRequest.request('GET', '/v1/metadata')
        .then(metadata => {
          dispatch(updateMetadata(metadata));
        }),

      ToolRequest.request('GET', '/v1/user/me')
        .then(user => {
          ToolUser.updateLocale(user.locale);

          dispatch(setLocale(user.locale));

          dispatch(updateUser(user));

          return ToolRequest.request('GET', '/v1/user-permission')
            .then(userPermissions => {
              dispatch(updateUserPermissions(userPermissions));

              if (!Object.keys(userPermissions).length) {
                throw new Error();
              }
            });
        })
        .catch(err => {
          history.replace(`/login?next=${encodeURIComponent(history.location.pathname)}`);
        }),
    ])
    .then(() => {
      setLoaded(true);
    });
  }, []);

  return (
    <ConfigProvider locale={locale}>
      {loaded ? (
        <Switch>
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/cell-order/print" component={CellOrderPrintScreen} />
          <Route path="/" component={AppScreen} />
        </Switch>
      ) : (
        <div id="splashContainer">
          <Spin />
        </div>
      )}
    </ConfigProvider>
  );
};
