export default {
  "appHeaderButtonChangePassword": "修改密碼",
  "appHeaderButtonLogout": "登出",
  "appHeaderButtonMerchantSetting": "商戶設定",
  "appHeaderButtonMyAccount": "我的帳號",
  "appHeaderButtonUserSetting": "我的設定",
  "appHeaderChangePasswordModalConfirmPassword": "確認密碼",
  "appHeaderChangePasswordModalConfirmPasswordErrorMessage": "您輸入的兩個新密碼不一致！",
  "appHeaderChangePasswordModalIncorrectPassword": "舊密碼不正確，請重新輸入",
  "appHeaderChangePasswordModalNewPassword": "新密碼",
  "appHeaderChangePasswordModalOldPassword": "您的密碼",
  "appHeaderChangePasswordModalSubmit": "提交",
  "appHeaderChangePasswordModalTitle": "修改密碼",
  "appHeaderCurrentUser": "已登入",
  "appHeaderFirebaseStatusButtonErrorInfoMessage": "您可以在瀏覽器網址欄附近, 打開設置允許推送通知",
  "appHeaderFirebaseStatusButtonErrorLabel": "推送通知未開啟",
  "appHeaderFirebaseStatusButtonLoadingLabel": "按此允許推送通知",
  "appHeaderFirebaseStatusButtonSuccessLabel": "已開啟推送通知",
  "appHeaderGreetings": "您好!",
  "appHeaderMerchantMessageModalTableCreatedAt": "建立日期",
  "appHeaderMerchantMessageModalTableState": "狀態",
  "appHeaderMerchantMessageModalTableStateRead": "已讀",
  "appHeaderMerchantMessageModalTableStateUnread": "未讀",
  "appHeaderMerchantMessageModalTableTitle": "標題",
  "appHeaderMerchantMessageModalTitle": "商戶消息",
  "appHeaderMerchantSettingModalTitle": "修改商戶設定",
  "appHeaderNotificationMessageNewMessage": "您有新訊息",
  "appHeaderSystemMessageModalTableCreatedAt": "建立日期",
  "appHeaderSystemMessageModalTableState": "狀態",
  "appHeaderSystemMessageModalTableStateRead": "已讀",
  "appHeaderSystemMessageModalTableStateUnread": "未讀",
  "appHeaderSystemMessageModalTableTitle": "標題",
  "appHeaderSystemMessageModalTitle": "系統消息",
  "appHeaderUserSettingModalTitle": "編輯",

  "appMenuHome": "主頁",
  "appMenuHomeContractList": "合約列表",
  "appMenuHomeMerchantList": "商戶列表",
  "appMenuHomeOrderList": "訂單列表",
  "appMenuLocker": "自提櫃",
  "appMenuLockerList": "自提櫃列表",
  "appMenuMerchant": "商戶",
  "appMenuMyBill": "我的帳單",
  "appMenuMyContract": "我的合約",
  "appMenuMyOrder": "我的訂單",
  "appMenuRegionList": "地區列表",
  "appMenuTransactionList": "我的交易記錄",
  "appMenuUser": "用戶",

  "cellContractAddScreenAddSuccessMessage": "添加 %{count} 合約成功",
  "cellContractAddScreenButtonSubmit": "提交",
  "cellContractAddScreenContractDates": "合約有效時間",
  "cellContractAddScreenFormCells": "自提櫃格子",
  "cellContractAddScreenFormContractMonths": "合約有效月份",
  "cellContractAddScreenFormHourUnit": "小時",
  "cellContractAddScreenFormMerchant": "商戶",
  "cellContractAddScreenFormNoOfDay": "日數",
  "cellContractAddScreenFormRemark": "備註",
  "cellContractAddScreenFormSubmissionTime": "提交時間",
  "cellContractAddScreenFormType": "類別",
  "cellContractAddScreenFormTypeDay": "日租",
  "cellContractAddScreenFormTypeMonth": "月租",
  "cellContractAddScreenTitle": "添加合約",
  "cellContractCellPriceSelectButtonAdd": "選擇自提櫃格子",
  "cellContractCellPriceSelectButtonBatchAdd": "批量選擇自提櫃格子",
  "cellContractCellPriceSelectButtonRemove": "刪除",
  "cellContractCellPriceSelectModalButtonSubmit": "提交",
  "cellContractCellPriceSelectModalFormLockerCell": "自提櫃格子",
  "cellContractCellPriceSelectModalFormPrice": "價錢",
  "cellContractCellPriceSelectModalFormPriceErrorInvalidMessage": "請輸入有效的價錢",
  "cellContractCellPriceSelectModalTitle": "選擇自提櫃格子",
  "cellContractDetailScreenCellName": "格子",
  "cellContractDetailScreenCellPrice": "格子價錢",
  "cellContractDetailScreenCreatedAt": "建立時間",
  "cellContractDetailScreenEndAt": "結束時間",
  "cellContractDetailScreenHumanId": "合約號",
  "cellContractDetailScreenLockerName": "自提櫃",
  "cellContractDetailScreenMerchantName": "商戶",
  "cellContractDetailScreenMonthRental": "月租",
  "cellContractDetailScreenRemark": "備註",
  "cellContractDetailScreenStartAt": "開始時間",
  "cellContractDetailScreenStatus": "狀態",
  "cellContractDetailScreenStatusActive": "生效中",
  "cellContractDetailScreenStatusEnded": "已結束",
  "cellContractDetailScreenStatusPending": "未開始",
  "cellContractDetailScreenTitle": "合約詳情",
  "cellContractDetailScreenTotalPrice": "總價錢",
  "cellContractDetailScreenType": "類別",
  "cellContractDetailScreenTypeDayRental": "日租",
  "cellContractEditScreenEditSuccessMessage": "修改合約成功",
  "cellContractEditScreenPrice": "價錢",
  "cellContractEditScreenTitle": "編輯合約",
  "cellContractViewScreenActionButtonEdit": "編輯",
  "cellContractViewScreenActionButtonRemove": "刪除",
  "cellContractViewScreenActionButtonView": "查看",
  "cellContractViewScreenActionRemoveConfirm": "您確定要刪除此合約?",
  "cellContractViewScreenActionRemoveErrorMessage": "刪除合約失敗",
  "cellContractViewScreenActionRemoveSuccessMessage": "刪除合約成功",
  "cellContractViewScreenButtonAdd": "添加合約",
  "cellContractViewScreenTableCreatedAt": "建立時間",
  "cellContractViewScreenTableEndAt": "結束時間",
  "cellContractViewScreenTableHumanId": "合約號",
  "cellContractViewScreenTableLockerCell": "自提櫃格子",
  "cellContractViewScreenTableLockerCellInvalid": "無效格子",
  "cellContractViewScreenTableMerchantName": "商戶",
  "cellContractViewScreenTablePrice": "價錢",
  "cellContractViewScreenTableRemark": "備註",
  "cellContractViewScreenTableSearchStatusAny": "任何狀態",
  "cellContractViewScreenTableSearchStatusEnded": "已結束",
  "cellContractViewScreenTableSearchStatusOngoing": "生效中",
  "cellContractViewScreenTableSearchStatusPending": "未開始",
  "cellContractViewScreenTableStartAt": "開始時間",
  "cellContractViewScreenTableStatus": "狀態",
  "cellContractViewScreenTableStatusActive": "生效中",
  "cellContractViewScreenTableStatusEnded": "已結束",
  "cellContractViewScreenTableStatusPending": "未開始",
  "cellContractViewScreenTableType": "類別",
  "cellContractViewScreenTableTypeDay": "日租",
  "cellContractViewScreenTableTypeMonth": "月租",
  "cellContractViewScreenTitle": "合約列表",

  "cellOrderDetailScreenTitle": "訂單詳情",
  "cellOrderEditScreenCell": "自提櫃格子",
  "cellOrderEditScreenEditSuccessMessage": "修改訂單成功",
  "cellOrderEditScreenRecipientExpiryHour": "取貨期限 (小時)",
  "cellOrderEditScreenRecipientPhone": "收貨人電話",
  "cellOrderEditScreenRemark": "備註",
  "cellOrderEditScreenSubmit": "提交",
  "cellOrderEditScreenTitle": "編輯訂單",
  "cellOrderEditScreenTrackNumber": "運單號",
  "cellOrderViewScreenActionButtonCancelOrder": "取消訂單",
  "cellOrderViewScreenActionButtonEdit": "編輯",
  "cellOrderViewScreenActionButtonView": "查看",
  "cellOrderViewScreenActionCancelOrderConfirmMessage": "你確定要取消此訂單嗎？",
  "cellOrderViewScreenActionCancelOrderErrorMessage": "取消訂單失敗",
  "cellOrderViewScreenActionCancelOrderSuccessMessage": "取消訂單成功",
  "cellOrderViewScreenButtonPrintReplenishment": "打印待送貨訂單",
  "cellOrderViewScreenTableCreatedAt": "建立日期",
  "cellOrderViewScreenTableExpiredAt": "取貨過期時間",
  "cellOrderViewScreenTableHumanId": "訂單號",
  "cellOrderViewScreenTableLockerCell": "自提櫃格子",
  "cellOrderViewScreenTableMerchantName": "商戶",
  "cellOrderViewScreenTablePickedUpAt": "取貨時間",
  "cellOrderViewScreenTableRecipientExpiryHour": "取貨期限 (小時)",
  "cellOrderViewScreenTableRecipientPhone": "收貨人電話",
  "cellOrderViewScreenTableRemark": "備註",
  "cellOrderViewScreenTableReplenishedAt": "送達時間",
  "cellOrderViewScreenTableState": "狀態",
  "cellOrderViewScreenTableStateCanceled": "已取消",
  "cellOrderViewScreenTableStatePickedUp": "已取貨",
  "cellOrderViewScreenTableStateRecalled": "已回收",
  "cellOrderViewScreenTableStateWaitPickup": "等待取貨",
  "cellOrderViewScreenTableStateWaitPickupExpired": "等待取貨 (已過期)",
  "cellOrderViewScreenTableStateWaitReplenishment": "等待送貨",
  "cellOrderViewScreenTableTrackNumber": "運單號",
  "cellOrderViewScreenTitle": "訂單列表",

  "errorMessageRequiredInputAny": "請輸入%{name}",
  "errorMessageRequiredSelectAny": "請選擇%{name}",
  "errorMessageUnknownError": "未知錯誤, 請稍後重試",

  "homeScreenAdminTitleBalance": "當前結餘",
  "homeScreenAdminTitleDayContractCount": "日租合約數目",
  "homeScreenAdminTitleDayContractCountTooltip": "當前有效的日租合約",
  "homeScreenAdminTitleDayContractUsagePercent": "日租使用率",
  "homeScreenAdminTitleDayContractUsagePercentTooltip": "當前日租格子的使用率",
  "homeScreenAdminTitleMerchantDisplayName": "您的商戶",
  "homeScreenAdminTitleMonthContractCount": "月租合約數目",
  "homeScreenAdminTitleMonthContractCountTooltip": "當前有效的月租合約",
  "homeScreenAdminTitleMonthContractUsagePercent": "月租使用率",
  "homeScreenAdminTitleMonthContractUsagePercentTooltip": "當前月租格子的使用率",
  "homeScreenAdminTitleTodayExpiredCount": "已過期訂單數目",
  "homeScreenAdminTitleTodayExpiredCountButtonView": "查看過期訂單",
  "homeScreenAdminTitleTodayExpiredCountTooltip": "當前待取貨而已過取時間的訂單數目",
  "homeScreenAdminTitleTodayOrder": "今天的訂單",
  "homeScreenAdminTitleTodayOrderCompletedCount": "已完成訂單數目",
  "homeScreenAdminTitleTodayOrderCompletedCountTooltip": "今天已取貨的訂單總數",
  "homeScreenAdminTitleTodayOrderCount": "訂單數目",
  "homeScreenAdminTitleTodayOrderCountTooltip": "今天的訂單總數",
  "homeScreenAdminTitleTodayOrderDeliveredCount": "已運送訂單數目",
  "homeScreenAdminTitleTodayOrderDeliveredCountTooltip": "今天已運送的訂單總數",
  "homeScreenMerchantMessage": "商戶消息",
  "homeScreenMessage": "消息",
  "homeScreenMessageMore": "更多",
  "homeScreenSystemMessage": "系統消息",

  "lockerAddScreenAddSuccessMessage": "添加自提櫃成功",
  "lockerAddScreenButtonSubmit": "提交",
  "lockerAddScreenCellLayoutPlaceholder": "未設置",
  "lockerAddScreenFormAddress": "地址",
  "lockerAddScreenFormCellLayout": "格子排列",
  "lockerAddScreenFormCode": "編號",
  "lockerAddScreenFormCoordinate": "座標",
  "lockerAddScreenFormCoordinateLatPlaceholder": "緯度",
  "lockerAddScreenFormCoordinateLngPlaceholder": "經度",
  "lockerAddScreenFormEnabled": "啟用",
  "lockerAddScreenFormLockerlyLockerId": "Lockerly ID",
  "lockerAddScreenFormName": "名稱",
  "lockerAddScreenFormRegion": "地區",
  "lockerAddScreenFormServiceHour": "服務時間",
  "lockerAddScreenFormVisible": "可見",
  "lockerAddScreenTitle": "添加自提櫃",

  "lockerCellContractAddModalAddSuccessMessage": "建立日租合約成功",
  "lockerCellContractAddModalButtonCancel": "取消",
  "lockerCellContractAddModalButtonConfirm": "確定",
  "lockerCellContractAddModalContractDates": "合約有效時間",
  "lockerCellContractAddModalHourUnit": "小時",
  "lockerCellContractAddModalNoOfDay": "租用日數",
  "lockerCellContractAddModalSubmissionTime": "提交時間",
  "lockerCellContractAddModalTitle": "建立日租合約",
  "lockerCellLayoutButtonCancel": "取消",
  "lockerCellLayoutButtonConfirm": "確定",
  "lockerCellLayoutModalBatchEditInstruction": "每種小區的一行 (名稱); 一個新行的新行; ---- 為新的一面",
  "lockerCellLayoutModalBatchEditTitle": "批量編輯格子",
  "lockerCellLayoutModalButtonBatchEdit": "批量編輯",
  "lockerCellLayoutModalEditCellName": "格子名稱",
  "lockerCellLayoutModalEditCellTitle": "編輯格子",
  "lockerCellLayoutModalNoOfCol": "格子數目",
  "lockerCellLayoutModalNoOfRow": "層數目",
  "lockerCellLayoutModalNoOfSide": "櫃子數目",
  "lockerCellLayoutModalTitle": "編輯格子排列",
  "lockerCellOrderAddModalAddSuccessMessage": "添加訂單成功",
  "lockerCellOrderAddModalButtonSubmit": "提交",
  "lockerCellOrderAddModalConfirmContent": "請再次確認運單號和收貨人電話是否正確",
  "lockerCellOrderAddModalConfirmContentRecipientPhone": "收貨人電話: %{recipientPhone}",
  "lockerCellOrderAddModalConfirmContentTrackNumber": "運單號: %{trackNumber}",
  "lockerCellOrderAddModalConfirmTitle": "確認資料",
  "lockerCellOrderAddModalLockerCell": "自提櫃格子",
  "lockerCellOrderAddModalMerchantRemark": "商戶備註",
  "lockerCellOrderAddModalRecipientExpiryHour": "取貨期限 (小時)",
  "lockerCellOrderAddModalRecipientPhone": "收貨人電話",
  "lockerCellOrderAddModalRecipientPhoneInvalidMessage": "請輸入正確格式的電話號碼",
  "lockerCellOrderAddModalRecipientPhoneReminderMessage": "請確保運單號和收貨人電話正確, 填寫錯誤會導致不能送貨或客人收不到取貨短訊",
  "lockerCellOrderAddModalTitle": "添加訂單",
  "lockerCellOrderAddModalTrackNumber": "運單號",

  "lockerDetailCellOrderForceModalSmsAlertMessage": "如選擇不經短訊通知, 您需另外通知收貨人",
  "lockerDetailCellOrderForceModalSmsLabel": "發短訊通知收貨人",
  "lockerDetailScreenActionButtonCancelContract": "取消合約",
  "lockerDetailScreenActionButtonCancelOrder": "取消訂單",
  "lockerDetailScreenActionButtonEditOrder": "編輯訂單",
  "lockerDetailScreenActionButtonForcePickupOrder": "更新為已取貨",
  "lockerDetailScreenActionButtonForceRecallOrder": "更新為已回收",
  "lockerDetailScreenActionButtonForceReplenishOrder": "更改為已送貨",
  "lockerDetailScreenActionCancelContractConfirmMessage": "你確定要取消此合約嗎？",
  "lockerDetailScreenActionCancelContractErrorMessage": "取消合約失敗",
  "lockerDetailScreenActionCancelContractNoWaitReplenishmentOrder": "有待送貨的訂單的合約才可以取消",
  "lockerDetailScreenActionCancelContractSuccessMessage": "取消合約成功",
  "lockerDetailScreenActionCancelOrderConfirmMessage": "你確定要取消此訂單嗎",
  "lockerDetailScreenActionCancelOrderErrorMessage": "取消訂單失敗",
  "lockerDetailScreenActionCancelOrderSuccessMessage": "取消訂單成功",
  "lockerDetailScreenActionForcePickupOrderConfirmMessage": "確定要強制更新為已取貨?",
  "lockerDetailScreenActionForcePickupOrderErrorMessage": "強制更新為已取貨失敗",
  "lockerDetailScreenActionForcePickupOrderSuccessMessage": "強制更新為已取貨成功",
  "lockerDetailScreenActionForceRecallOrderConfirmTitle": "確定要強制更新為已回收?",
  "lockerDetailScreenActionForceRecallOrderConfirmWithSmsMessage": "確定要強制更新為已回收? (發短訊通知)",
  "lockerDetailScreenActionForceRecallOrderConfirmWithoutSmsMessage": "確定要強制更新為已回收? (不會發短訊通知)",
  "lockerDetailScreenActionForceRecallOrderErrorMessage": "強制更新為已回收失敗",
  "lockerDetailScreenActionForceRecallOrderSuccessMessage": "強制更新為已回收成功",
  "lockerDetailScreenActionForceReplenishOrderConfirmTitle": "確定要強制更新為已送貨?",
  "lockerDetailScreenActionForceReplenishOrderConfirmWithSmsMessage": "確定要強制更新為已送貨? (發短訊通知)",
  "lockerDetailScreenActionForceReplenishOrderConfirmWithoutSmsMessage": "確定要強制更新為已送貨? (不會發短訊通知)",
  "lockerDetailScreenActionForceReplenishOrderErrorMessage": "強制更新為已送貨失敗",
  "lockerDetailScreenActionForceReplenishOrderSuccessMessage": "強制更新為已送貨成功",
  "lockerDetailScreenAdminButtonForceOpenSlot": "強制打開格子",
  "lockerDetailScreenAdminButtonForceOpenSlotErrorMessageSystemError": "請求失敗, 請稍後重試",
  "lockerDetailScreenAdminButtonForceOpenSlotModalTitle": "確定要強制打開格子?",
  "lockerDetailScreenAdminButtonForceOpenSlotSuccessMessage": "成功發出打開指令",
  "lockerDetailScreenAdminButtonForceOpenSlotTooltip": "不理會大門有沒有打開, 直接打開格子",
  "lockerDetailScreenAdminButtonOpenSlot": "解鎖打開格子",
  "lockerDetailScreenAdminButtonOpenSlotErrorMessageSystemError": "請求失敗, 請稍後重試",
  "lockerDetailScreenAdminButtonOpenSlotModalTitle": "確定要解鎖打開格子?",
  "lockerDetailScreenAdminButtonOpenSlotSuccessMessage": "成功發出解鎖指令",
  "lockerDetailScreenAdminButtonOpenSlotTooltip": "解鎖大門, 等待拉開門後自動打開格子",
  "lockerDetailScreenAdminButtonReboot": "立即重啟機器",
  "lockerDetailScreenAdminButtonRebootErrorMessageSystemError": "請求失敗, 請稍後重試",
  "lockerDetailScreenAdminButtonRebootModalTitle": "確定要立即重啟機器?",
  "lockerDetailScreenAdminButtonRebootSuccessMessage": "成功發出重啟機器指令",
  "lockerDetailScreenAdminTitle": "管理員操作",
  "lockerDetailScreenCellContractCreatedAt": "建立日期",
  "lockerDetailScreenCellContractEmpty": "暫時沒有合約",
  "lockerDetailScreenCellContractEndAt": "結束日期",
  "lockerDetailScreenCellContractHumanId": "合約號",
  "lockerDetailScreenCellContractMerchantName": "商戶",
  "lockerDetailScreenCellContractPrice": "價錢",
  "lockerDetailScreenCellContractStartAt": "開始日期",
  "lockerDetailScreenCellContractTitle": "當前生效合約",
  "lockerDetailScreenCellContractType": "類別",
  "lockerDetailScreenCellContractTypeDay": "日租合約",
  "lockerDetailScreenCellContractTypeMonth": "月租合約",
  "lockerDetailScreenCellOrderCreatedAt": "建立日期",
  "lockerDetailScreenCellOrderEmpty": "暫時沒有訂單",
  "lockerDetailScreenCellOrderExpiredAt": "取貨過期時間",
  "lockerDetailScreenCellOrderHumanId": "訂單號",
  "lockerDetailScreenCellOrderLockerName": "自提櫃",
  "lockerDetailScreenCellOrderMerchantCellName": "格子",
  "lockerDetailScreenCellOrderMerchantLockerCellName": "自提櫃格子",
  "lockerDetailScreenCellOrderMerchantName": "商戶",
  "lockerDetailScreenCellOrderMerchantRemark": "商家備註",
  "lockerDetailScreenCellOrderQrCodeTitleDeliver": "送貨二維碼",
  "lockerDetailScreenCellOrderQrCodeTitleDeliverShort": "送貨",
  "lockerDetailScreenCellOrderQrCodeTitlePickup": "取貨二維碼",
  "lockerDetailScreenCellOrderQrCodeTitleRecall": "回收二維碼",
  "lockerDetailScreenCellOrderQrCodeTitleRecallShort": "回收",
  "lockerDetailScreenCellOrderRecipientExpiryHour": "取貨期限（小時）",
  "lockerDetailScreenCellOrderRecipientPhone": "收貨人電話",
  "lockerDetailScreenCellOrderShortUrl": "客戶訂單連結",
  "lockerDetailScreenCellOrderState": "狀態",
  "lockerDetailScreenCellOrderStateCanceled": "取消訂單",
  "lockerDetailScreenCellOrderStatePickedUp": "已取貨",
  "lockerDetailScreenCellOrderStateRecalled": "已回收",
  "lockerDetailScreenCellOrderStateWaitPickup": "等待取貨",
  "lockerDetailScreenCellOrderStateWaitReplenishment": "等待送貨",
  "lockerDetailScreenCellOrderTabNew": "當前",
  "lockerDetailScreenCellOrderTitle": "當前有效訂單",
  "lockerDetailScreenCellOrderTrackNumber": "運單號",
  "lockerDetailScreenCellPrice": "價錢 (日租)",
  "lockerDetailScreenCellSize": "尺寸 (mm)",
  "lockerDetailScreenCellStatusCreatedAt": "訂單建立於",
  "lockerDetailScreenCellStatusCreatedAtTitle": "建立訂單",
  "lockerDetailScreenCellStatusDeliveredAt": "送達於",
  "lockerDetailScreenCellStatusDeliveredAtEmpty": "等待送貨中",
  "lockerDetailScreenCellStatusDeliveredAtTitle": "貨物送達",
  "lockerDetailScreenCellStatusPickupAt": "取貨於",
  "lockerDetailScreenCellStatusPickupAtEmpty": "等待取貨中",
  "lockerDetailScreenCellStatusPickupAtTitle": "取貨",
  "lockerDetailScreenCellStatusTitle": "物流狀態",
  "lockerDetailScreenLockerAddress": "地址",
  "lockerDetailScreenLockerAlertCanOrder": "您有生效合約, 可以使用",
  "lockerDetailScreenLockerAlertCanOrderSystemAdmin": "商戶有生效合約, 可以使用",
  "lockerDetailScreenLockerAlertCanRent": "目前可以租用",
  "lockerDetailScreenLockerAlertHaveOrder": "您已有訂單",
  "lockerDetailScreenLockerAlertHaveOrderSystemAdmin": "商戶已有訂單",
  "lockerDetailScreenLockerAlertOccupied": "已有另一個商戶使用中",
  "lockerDetailScreenLockerButtonDayRental": "請求日租",
  "lockerDetailScreenLockerButtonOrder": "添加訂單",
  "lockerDetailScreenLockerCellError": "錯誤",
  "lockerDetailScreenLockerLatestTemperature": "最新溫度",
  "lockerDetailScreenLockerNoLatestTemperature": "過去三十分鐘無溫度數據",
  "lockerDetailScreenLockerNoLatestTemperatureLabel": "未知",
  "lockerDetailScreenLockerNoOfCell": "格子數目",
  "lockerDetailScreenLockerSelectCellLabel": "請選擇格子",
  "lockerDetailScreenLockerServiceHour": "服務時間",
  "lockerDetailScreenLockerStatus": "狀態",
  "lockerDetailScreenLockerStatusRunning": "運作中",
  "lockerDetailScreenLockerStatusUnknown": "未知",
  "lockerDetailScreenTitle": "自提櫃詳情",

  "lockerEditScreenEditSuccessMessage": "修改自提櫃成功",
  "lockerEditScreenTitle": "編輯自提櫃",

  "lockerHistoricalTemperatureModalButtonDownload": "下載 xlsx",
  "lockerHistoricalTemperatureModalTimespan168Label": "過去 7 天",
  "lockerHistoricalTemperatureModalTimespan48Label": "過去 48 小時",
  "lockerHistoricalTemperatureModalTitle": "自提櫃歷史溫度",

  "lockerViewScreenActionButtonCopy": "複製此櫃",
  "lockerViewScreenActionButtonEdit": "編輯",
  "lockerViewScreenActionButtonEditCells": "編輯格子",
  "lockerViewScreenActionButtonRemove": "刪除",
  "lockerViewScreenActionButtonView": "查看",
  "lockerViewScreenActionRemoveConfirmMessage": "您確定要刪除此自提櫃?",
  "lockerViewScreenActionRemoveErrorMessage": "刪除自提櫃失敗",
  "lockerViewScreenActionRemoveSuccessMessage": "刪除自提櫃成功",
  "lockerViewScreenButtonAdd": "添加自提櫃",
  "lockerViewScreenCopySuccessMessage": "自提櫃成功複製",
  "lockerViewScreenTableAddress": "地址",
  "lockerViewScreenTableClickToViewHistoricalTemperature": "點擊查看歷史溫度",
  "lockerViewScreenTableCode": "編號",
  "lockerViewScreenTableIsEnabled": "啟用",
  "lockerViewScreenTableLatestTemperature": "最新溫度",
  "lockerViewScreenTableName": "名稱",
  "lockerViewScreenTableNoTemperature": "過去三十分鐘無溫度數據",
  "lockerViewScreenTableOffline": "離線",
  "lockerViewScreenTableOnline": "在線",
  "lockerViewScreenTableRegion": "地區",
  "lockerViewScreenTableSearchOnlyHasCellContract": "只顯示租用中",
  "lockerViewScreenTableSearchRegionAny": "任何地區",
  "lockerViewScreenTableServiceHour": "服務時間",
  "lockerViewScreenTableState": "狀態",
  "lockerViewScreenTableStatus": "狀態",
  "lockerViewScreenTableUnknown": "未知",
  "lockerViewScreenTitle": "自提櫃列表",

  "loginScreenFormLoginButton": "登入",
  "loginScreenFormMerchantName": "商戶",
  "loginScreenFormPassword": "密碼",
  "loginScreenFormUserName": "用戶",
  "loginScreenLoginFailedMessageIncorrectPassword": "密碼錯誤",
  "loginScreenLoginFailedMessageUserNotFound": "找不到此用戶",
  "loginScreenLoginSuccessMessage": "登入成功",

  "merchantAddScreenAddSuccessMessage": "添加商戶成功",
  "merchantAddScreenButtonSubmit": "提交",
  "merchantAddScreenFormContactPhone": "聯絡電話",
  "merchantAddScreenFormDisplayName": "顯示名稱",
  "merchantAddScreenFormLocale": "語言",
  "merchantAddScreenFormLocaleEn": "英文",
  "merchantAddScreenFormLocaleZhCn": "簡體中文",
  "merchantAddScreenFormLocaleZhTw": "繁體中文",
  "merchantAddScreenFormName": "登入名稱",
  "merchantAddScreenTitle": "添加商戶",
  "merchantAddTransactionModalAmount": "金額",
  "merchantAddTransactionModalMerchantName": "商戶",
  "merchantAddTransactionModalRemark": "備註",
  "merchantAddTransactionModalSubmit": "提交",
  "merchantAddTransactionModalTitle": "添加交易記錄",
  "merchantAddTransactionModalType": "類別",
  "merchantAddTransactionModalTypeCredit": "積分/贈送",
  "merchantAddTransactionModalTypeDayRental": "日租",
  "merchantAddTransactionModalTypeMonthRental": "月租",
  "merchantAddTransactionModalTypeRefund": "退款",
  "merchantAddTransactionModalTypeTransfer": "銀行過帳",

  "merchantBillDetailScreenBillCellContractPanelHeaderDayType": "%{lockerName} / %{cellName} (費用: $%{price}) (合約期: %{duration}小時)",
  "merchantBillDetailScreenBillCellContractPanelHeaderMonthType": "%{lockerName} / %{cellName} (費用: $%{price})",
  "merchantBillDetailScreenCreatedAt": "建立日期",
  "merchantBillDetailScreenDecscription": "描述",
  "merchantBillDetailScreenMerchantName": "商戶",
  "merchantBillDetailScreenPrice": "金額",
  "merchantBillDetailScreenReceiptNo": "帳單編號",
  "merchantBillDetailScreenRemark": "備註",
  "merchantBillDetailScreenTitle": "帳單詳情",
  "merchantBillDetailScreenTypeDay": "日租",
  "merchantBillDetailScreenTypeMonth": "月租",
  "merchantBillViewScreenActionButtonView": "查看",
  "merchantBillViewScreenDescription": "描述",
  "merchantBillViewScreenTableCreatedAt": "建立日期",
  "merchantBillViewScreenTableMerchantName": "商戶",
  "merchantBillViewScreenTablePrice": "金額",
  "merchantBillViewScreenTableReceiptNo": "帳單編號",
  "merchantBillViewScreenTableRemark": "備註",
  "merchantBillViewScreenTableType": "類別",
  "merchantBillViewScreenTitle": "帳單列表",
  "merchantBillViewScreenTypeDay": "日租帳單",
  "merchantBillViewScreenTypeMonth": "月租帳單",

  "merchantDetailScreenBalance": "當前結餘",
  "merchantDetailScreenCreatedAt": "建立日期",
  "merchantDetailScreenDisplayLocale": "語言",
  "merchantDetailScreenDisplayName": "顯示名稱",
  "merchantDetailScreenMerchantName": "商戶",
  "merchantDetailScreenTitle": "商戶詳情",

  "merchantEditScreenEditSuccessMessage": "修改商戶成功",
  "merchantEditScreenTitle": "編輯商戶",

  "merchantTransactionViewScreenTableAmount": "金額",
  "merchantTransactionViewScreenTableCreatedAt": "建立日期",
  "merchantTransactionViewScreenTableMerchantName": "商戶",
  "merchantTransactionViewScreenTableRemark": "備註",
  "merchantTransactionViewScreenTableType": "類別",
  "merchantTransactionViewScreenTableTypeCredit": "積分/贈送",
  "merchantTransactionViewScreenTableTypeDayRental": "日租",
  "merchantTransactionViewScreenTableTypeMonthRental": "月租",
  "merchantTransactionViewScreenTableTypeRefund": "退款",
  "merchantTransactionViewScreenTableTypeTransfer": "銀行過帳",
  "merchantTransactionViewScreenTitle": "交易列表",

  "merchantViewScreenActionButtonAddTransaction": "添加交易記錄",
  "merchantViewScreenActionButtonEdit": "編輯",
  "merchantViewScreenActionButtonRemove": "刪除",
  "merchantViewScreenActionButtonView": "查看",
  "merchantViewScreenActionRemoveConfirm": "您確定要刪除此商戶?",
  "merchantViewScreenActionRemoveErrorMessage": "刪除商戶失敗",
  "merchantViewScreenActionRemoveSuccessMessage": "刪除商戶成功",
  "merchantViewScreenButtonAdd": "添加商戶",
  "merchantViewScreenCreatedAt": "建立日期",
  "merchantViewScreenIsLocked": "鎖定",
  "merchantViewScreenTableBalance": "結餘",
  "merchantViewScreenTableContactPhone": "聯絡電話",
  "merchantViewScreenTableDisplayName": "顯示名稱",
  "merchantViewScreenTableName": "名稱",
  "merchantViewScreenTitle": "商戶列表",

  "regionAddScreenAddSuccessMessage": "添加地區成功",
  "regionAddScreenButtonSubmit": "提交",
  "regionAddScreenFormName": "名稱",
  "regionAddScreenTitle": "添加地區",

  "regionEditScreenEditSuccessMessage": "修改地區成功",
  "regionEditScreenTitle": "編輯地區",

  "regionViewScreenActionButtonEdit": "編輯",
  "regionViewScreenActionButtonRemove": "刪除",
  "regionViewScreenActionRemoveConfirmMessage": "您確定要刪除此區域?",
  "regionViewScreenActionRemoveErrorMessage": "刪除地區失敗",
  "regionViewScreenActionRemoveSuccessMessage": "刪除地區成功",
  "regionViewScreenButtonAdd": "添加地區",
  "regionViewScreenTableName": "名稱",
  "regionViewScreenTitle": "地區列表",

  "tablePaginationTotal": "總共 %{total} 個記錄",

  "tableSearchBillTypeAny": "任何類別",
  "tableSearchBillTypeDay": "日租帳單",
  "tableSearchBillTypeMonth": "月租帳單",
  "tableSearchContractTypeAny": "任何類別",
  "tableSearchContractTypeDay": "日租",
  "tableSearchContractTypeMonth": "月租",
  "tableSearchLockerAny": "任何自提櫃",
  "tableSearchMerchantAny": "任何商戶",
  "tableSearchMerchantTransactionTypeCredit": "積分/贈送",
  "tableSearchMerchantTransactionTypeDayRental": "日租",
  "tableSearchMerchantTransactionTypeMonthRental": "月租",
  "tableSearchMerchantTransactionTypeRefund": "退款",
  "tableSearchMerchantTransactionTypeTransfer": "銀行過帳",
  "tableSearchOrderStateAny": "任何狀態",
  "tableSearchOrderStateCanceled": "已取消",
  "tableSearchOrderStatePickedUp": "已取貨",
  "tableSearchOrderStateRecalled": "已回收",
  "tableSearchOrderStateWaitPickup": "等待取貨",
  "tableSearchOrderStateWaitReplenishment": "等待送貨",
  "tableSearchOrderStateWatiPickupExpired": "待取貨並過期",
  "tableSearchOrderTypeAny": "任何類別",
  "tableSearchOrderTypeDay": "日租",
  "tableSearchOrderTypeMonth": "月租",
  "tableSearchPlaceholder": "搜索...",
  "tableSearchTransactionTypeAny": "任何類別",

  "userAddScreenAddSuccessMessage": "添加用戶成功",
  "userAddScreenButtonSubmit": "提交",
  "userAddScreenFormDisplayLocale": "語言",
  "userAddScreenFormDisplayName": "顯示名稱",
  "userAddScreenFormEmail": "電郵",
  "userAddScreenFormLocaleEn": "英文",
  "userAddScreenFormLocaleZhCn": "簡體中文",
  "userAddScreenFormLocaleZhTw": "繁體中文",
  "userAddScreenFormMerchant": "商戶",
  "userAddScreenFormName": "登入名稱",
  "userAddScreenFormPassword": "密碼",
  "userAddScreenFormPhone": "電話",
  "userAddScreenFormRoles": "角色",
  "userAddScreenTitle": "添加用戶",

  "userEditScreenEditSuccessMessage": "修改用戶成功",
  "userEditScreenTitle": "編輯用戶",

  "userViewScreenActionButtonChangePassword": "修改密碼",
  "userViewScreenActionButtonEdit": "編輯",
  "userViewScreenActionButtonRemove": "刪除",
  "userViewScreenActionRemoveConfirm": "您確定要刪除此用戶?",
  "userViewScreenActionRemoveErrorMessage": "刪除用戶失敗",
  "userViewScreenActionRemoveSuccessMessage": "刪除用戶成功",
  "userViewScreenButtonAdd": "添加用戶",
  "userViewScreenLocaleZhCn": "簡體中文",
  "userViewScreenLocaleZhEn": "英文",
  "userViewScreenLocaleZhTw": "繁體中文",
  "userViewScreenTableDisplayName": "顯示名稱",
  "userViewScreenTableEmail": "電郵",
  "userViewScreenTableLocale": "語言",
  "userViewScreenTableName": "登入名稱",
  "userViewScreenTablePhone": "電話",
  "userViewScreenTableRoles": "角色",
  "userViewScreenTitle": "用戶列表"
}