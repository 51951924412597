import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import {
  PageHeader,
  Button,
  Typography,
  Dropdown,
  Menu,
  Modal,
  Badge,
  message,
} from 'antd'
import {
  CloseOutlined, CopyOutlined,
  EditOutlined,
  PrinterOutlined,
  SearchOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';
import * as ToolPhone from '../../tools/ToolPhone';

import SpinFull from '../../components/SpinFull';
import SuperTable from '../../components/SuperTable';

const { Text } = Typography;

export default function CellOrderViewScreen(props) {
  const history = useHistory();
  const userData = useSelector(state => state.user.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const [loaded, setLoaded] = useState(false);
  const [tableKey, setTableKey] = useState(Math.random().toString());
  const [merchants, setMerchants] = useState([]);
  const [searchLockers, setSearchLockers] = useState([]);
  const [searchLockerLoading, setSearchLockerLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = ToolPermission.hasPermission(userPermissionsData, 'cell-order', 'put');
        return canEdit ? (
          <Dropdown.Button
            type="primary"
            onClick={() => {
              history.push(`/cell-order/detail/${record.id}`);
            }}
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    history.push(`/cell-order/edit/${record.id}`);
                    break;
                  }
                  case 'cancel': {
                    Modal.confirm({
                      title: I18n.t('cellOrderViewScreenActionCancelOrderConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('POST', `/v1/cell-order/${record.id}/cancel`);
                            message.success(I18n.t('cellOrderViewScreenActionCancelOrderSuccessMessage'));
                            setTableKey(Math.random().toString());
                          } catch (err) {
                            message.error(I18n.t('cellOrderViewScreenActionCancelOrderErrorMessage'));
                          }
                        })();
                      },
                    });
                    break;
                  }
                }
              }
              }>
                {canEdit && (
                  <Menu.Item key="edit">
                    <EditOutlined />
                    {I18n.t('cellOrderViewScreenActionButtonEdit')}
                  </Menu.Item>
                )}
                {canEdit && record.state === 'WAIT_REPLENISHMENT' ? (
                  <Menu.Item key="cancel">
                    <CloseOutlined />
                    {I18n.t('cellOrderViewScreenActionButtonCancelOrder')}
                  </Menu.Item>
                ) : null}
              </Menu>
            }>
            <SearchOutlined />
            {I18n.t('cellOrderViewScreenActionButtonView')}
          </Dropdown.Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              history.push(`/cell-order/detail/${record.id}`);
            }}
          >
            <SearchOutlined />
            {I18n.t('cellOrderViewScreenActionButtonView')}
          </Button>
        );
      },
    },
    ...(ToolPermission.hasPermission(userPermissionsData, 'system-admin', 'get') ? [
      {
        template: 'id',
        key: 'id',
        width: 40,
        title: 'ID',
      },
    ] : []),
    {
      key: 'humanId',
      title: I18n.t('cellOrderViewScreenTableHumanId'),
      width: 80,
    },
    {
      key: 'merchantName',
      title: I18n.t('cellOrderViewScreenTableMerchantName'),
      render: (value, record) => {
        return record.merchant && record.merchant.displayName;
      },
    },
    {
      key: 'cell',
      title: I18n.t('cellOrderViewScreenTableLockerCell'),
      render: (value, record) => {
        return record.locker && record.cell
          ? (
            <Button
              type="link"
              href={`#/locker/detail/${record.locker.id}`}
            >
              {record.locker.name[userData.locale]} / {record.cell.name}
            </Button>
          ) : <Text type="danger">(Invalid Cell)</Text>;
      },
    },
    {
      key: 'state',
      title: I18n.t('cellOrderViewScreenTableState'),
      width: 150,
      render: (value, record) => {
        if (value === 'WAIT_PICKUP' && new Date().toISOString() >= record.expiredAt) {
          return (
            <Badge status="error" text={I18n.t('cellOrderViewScreenTableStateWaitPickupExpired')} />
          );
        } else {
          return {
            'WAIT_REPLENISHMENT': <Badge status="warning" text={I18n.t('cellOrderViewScreenTableStateWaitReplenishment')} />,
            'WAIT_PICKUP': <Badge status="processing" text={I18n.t('cellOrderViewScreenTableStateWaitPickup')} />,
            'PICKED_UP': <Badge status="success" text={I18n.t('cellOrderViewScreenTableStatePickedUp')} />,
            'CANCELED': <Badge status="default" text={I18n.t('cellOrderViewScreenTableStateCanceled')} />,
            'RECALLED': <Badge status="error" text={I18n.t('cellOrderViewScreenTableStateRecalled')} />,
          }[value];
        }
      },
    },
    {
      key: 'recipientPhone',
      title: I18n.t('cellOrderViewScreenTableRecipientPhone'),
      width: 150,
      render: (value, record) => {
        return value ? ToolPhone.simplify(value) : '-';
      },
    },
    {
      key: 'trackNumber',
      title: I18n.t('cellOrderViewScreenTableTrackNumber'),
      width: 200,
      render: (value, record) => {
        return value || '-';
      },
    },
    {
      template: 'datetime',
      key: 'replenishedAt',
      width: 150,
      title: I18n.t('cellOrderViewScreenTableReplenishedAt'),
    },
    {
      template: 'datetime',
      key: 'pickedUpAt',
      width: 150,
      title: I18n.t('cellOrderViewScreenTablePickedUpAt'),
    },
    {
      key: 'recipientExpiryHour',
      title: I18n.t('cellOrderViewScreenTableRecipientExpiryHour'),
      width: 120,
    },
    {
      template: 'datetime',
      key: 'expiredAt',
      width: 150,
      title: I18n.t('cellOrderViewScreenTableExpiredAt'),
    },
    {
      key: 'remark',
      title: I18n.t('cellOrderViewScreenTableRemark'),
    },
    {
      template: 'datetime',
      key: 'createdAt',
      width: 150,
      title: I18n.t('cellOrderViewScreenTableCreatedAt'),
    },
  ];

  const searchColumns = [
    ...(ToolPermission.hasPermission(userPermissionsData, 'merchant', 'get:xm') ? [
      {
        template: 'select',
        key: 'merchantId',
        title: I18n.t('tableSearchMerchantAny'),
        options: merchants.map(merchant => ({
          label: `${merchant.displayName} (${merchant.name})`,
          value: merchant.id,
        }))
      }
    ]: []),
    {
      template: 'select',
      key: 'lockerId',
      title: I18n.t('tableSearchLockerAny'),
      options: searchLockers.map(locker => ({
        label: `${locker.name[userData.locale]} (${locker.code})`,
        value: locker.id,
      })),
      style: {
        minWidth: 240,
      },
      showSearch: true,
      filterOption: false,
      notFoundContent: null,
      loading: searchLockerLoading,
      onSearch: (value) => {
        setSearchLockerLoading(true);

        (async () => {
          try {
            const lockerRes = await ToolRequest.request('GET', '/v1/locker', {
              searchText: `%${value}%`,
              count: 10,
            });
            setSearchLockers(lockerRes.data.sort((a, b) => {
              if (a.code < b.code) {
                return -1;
              } else if (a.code > b.code) {
                return 1;
              }
              return 0;
            }));
          } catch (err) {
          }

          setSearchLockerLoading(false);
        })();
      },
    },
    {
      template: 'select',
      key: 'state',
      title: I18n.t('tableSearchOrderStateAny'),
      options: [
        {
          label: <Badge status="warning" text={I18n.t('tableSearchOrderStateWaitReplenishment')} />,
          value: 'WAIT_REPLENISHMENT'
        },
        {
          label: <Badge status="processing" text={I18n.t('tableSearchOrderStateWaitPickup')} />,
          value: 'WAIT_PICKUP'
        },
        {
          label: <Badge status="success" text={I18n.t('tableSearchOrderStatePickedUp')} />,
          value: 'PICKED_UP'
        },
        {
          label: <Badge status="default" text={I18n.t('tableSearchOrderStateCanceled')} />,
          value: 'CANCELED'
        },
        {
          label: <Badge status="error" text={I18n.t('tableSearchOrderStateRecalled')} />,
          value: 'RECALLED'
        },
        {
          label: <Badge status="error" text={I18n.t('tableSearchOrderStateWatiPickupExpired')} />,
          value: 'WAIT_PICKUP_EXPIRED'
        },
      ],
    },
  ];

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
      merchantId: values.merchantId ? values.merchantId: undefined,
      state: values.state ? values.state : undefined,
      lockerId: values.lockerId ? values.lockerId : undefined,
    };
  };

  useEffect(() => {
    (async () => {
      try {
        if(ToolPermission.hasPermission(userPermissionsData, 'merchant', 'get:xm')){
          const merchantRes = await ToolRequest.request('GET', '/v1/merchant', {
            count: 1000,
          });
          setMerchants(merchantRes.data);
        }else {
          const merchantRes = await ToolRequest.request('GET', `/v1/merchant/${userData.merchantId}`);
          setMerchants(merchantRes.data);
        }
        // TODO filter by locker?
      } catch (err) {
      }

      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const orderRes = await ToolRequest.request('GET', '/v1/cell-order', {
      ...body,
    });
    setOrders(orderRes.data);

    const merchantIds = orderRes.data.map(order => order.merchantId)
      .filter((value, index, self) => self.indexOf(value) === index);

    const merchants = (await Promise.all(merchantIds.map(async merchantId => await ToolRequest.request('GET', `/v1/merchant/${merchantId}`).catch(err => {}))))
      .filter(value => value);

    const cellIds = orderRes.data.map(order => order.cellId)
      .filter((value, index, self) => self.indexOf(value) === index);

    const cells = (await Promise.all(cellIds.map(async cellId => await ToolRequest.request('GET', `/v1/cell/${cellId}`).catch(err => {}))))
      .filter(value => value);

    const lockerIds = cells.map(cell => cell.lockerId)
      .filter((value, index, self) => self.indexOf(value) === index);

    const lockers = await Promise.all(lockerIds.map(async lockerId => await ToolRequest.request('GET', `/v1/locker/${lockerId}`).catch(err => {})));

    return {
      data: orderRes.data.map(order => {
        const merchant = merchants.find(merchantItem => merchantItem.id === order.merchantId);
        const cell = cells.find(cellItem => cellItem.id === order.cellId);
        const locker = cell ? lockers.find(lockerItem => lockerItem.id === cell.lockerId) : null;

        return {
          ...order,
          merchant,
          locker,
          cell,
        };
      }),
      totalCount: orderRes.totalCount,
    };
  };
  return loaded ? (
    <PageHeader
      title={I18n.t('cellOrderViewScreenTitle')}
      extra={[
        <Button
          key="1"
          type="primary"
          icon={<PrinterOutlined />}
          onClick={() => window.open('#/cell-order/print')}
        >
          {I18n.t('cellOrderViewScreenButtonPrintReplenishment')}
        </Button>
      ]}
    >
      <SuperTable
        showSearchInput
        searchColumns={searchColumns}
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
    </PageHeader>
  ) : <SpinFull />;
};
