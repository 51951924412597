import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import {PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, Divider, message, InputNumber} from 'antd';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';
import LockerCellLayoutModal from './LockerCellLayoutModal';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

export default function LockerEditScreen(props) {
  const history = useHistory();
  const { lockerId } = useParams();
  const userData = useSelector(state => state.user.value);
  const [cells, setCells] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locker, setLocker] = useState(null);
  const [regions, setRegions] = useState(null);

  useEffect(() => {
    (async () => {
      const lockerRes = await ToolRequest.request('GET', `/v1/locker/${lockerId}`);
      setLocker(lockerRes);
      const cellRes = await ToolRequest.request('GET', `/v1/cell`, {
        lockerId,
        count: 1000,
      });
      setCells(cellRes.data);
      const regionRes = await ToolRequest.request('GET', `/v1/region`);
      setRegions(regionRes.data);
      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const code = values.code;
      const str = code.match(/(\d+)$/)[0];
      let num = parseInt(str);
      let amount = values.amount;
      let count = code.length;
      let fillz = '0';
      while (count>=1){
        fillz += '0';
        count --;
      }
      while (amount >= 1){
        let name = {};
        let regionName = '';
        if(locker.regionId){
          const region = regions.find(item=>item.id === locker.regionId);
          regionName = region && region.name[userData.locale];
        }
        for (let key in locker.name){
          if(regionName){
            name[key] = `${locker.name[key]} ${regionName} (${amount})`
          }else {
            name[key] = `${locker.name[key]} (${amount})`
          }
        }
        let newCode = code.replace(str,(fillz+num).substr(-str.length));
        const res = await ToolRequest.request('POST', '/v1/locker', {
          code: newCode,
          name,
          address:locker.address,
          isEnabled: locker.isEnabled,
          regionId:locker.regionId,
          coordinate:locker.coordinate,
          cellLayout: locker.cellLayout,
          isVisible: locker.isVisible
        });
        for (let value of cells){
          await ToolRequest.request('POST', '/v1/cell', {
            lockerId: res.id,
            lockerlyCellId: value.lockerlyCellId || null,
            depth: value.depth || null,
            width: value.width || null,
            height: value.height || null,
            price: value.price || null,
            name: value.name || null,
            isEnabled: value.isEnabled
          });
        }
        amount--;
        num++;
      }
      setLoading(false);
      message.success(I18n.t('lockerViewScreenCopySuccessMessage'));
      history.push('/locker');
    } catch (err) {
      setLoading(false);
      console.dir(err);
      setLoading(false);
      message.error('Unable to copy locker');
    }
  };

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('lockerViewScreenActionButtonCopy')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={
            {
              code:'001',
              amount:1
            }
          }
          onFinish={onFinish}
        >
          <Form.Item
            label={I18n.t('lockerAddScreenFormCode')}
            name="code"
            rules={[
              {
                required: true,
                message: 'Please enter the code',
              },
            ]}
          >
            <Input
              placeholder={I18n.t('lockerAddScreenFormCode')}
            />
          </Form.Item>

          <Form.Item
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter the amount',
              },
            ]}
          >
            <InputNumber
              placeholder="Amount"
              min={1}
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {I18n.t('lockerAddScreenButtonSubmit')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
