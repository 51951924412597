import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, Divider, DatePicker, message } from 'antd';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';

import moment from 'moment';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

export default function CellContractEditScreen(props) {
  const history = useHistory();
  const userData = useSelector(state => state.user.value);
  const { cellContractId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cellContract, setCellContract] = useState(null);

  useEffect(() => {
    (async () => {
      const cellContractRes = await ToolRequest.request('GET', `/v1/cell-contract/${cellContractId}`);

      const merchant = await ToolRequest.request('GET', `/v1/merchant/${cellContractRes.merchantId}`);

      const cell = await ToolRequest.request('GET', `/v1/cell/${cellContractRes.cellId}`);

      const locker = await ToolRequest.request('GET', `/v1/locker/${cell.lockerId}`);

      setCellContract({
        ...cellContractRes,
        merchantId: [cellContractRes.merchantId],
        cellId: [
          locker.name[userData.locale],
          `${cell.name} ($${(cell.price / 1000000).toFixed(1)})`,
        ],
        type: [cellContractRes.type],
        contractDates: [
          moment(cellContractRes.contractStartAt),
          moment(cellContractRes.contractEndAt),
        ],
        price: (cellContractRes.price / 1000000).toFixed(1),
        merchant,
        locker,
        cell,
      });

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const data = {
        remark: values.remark,
      };

      const res = await ToolRequest.request('PUT', `/v1/cell-contract/${cellContractId}`, data);

      message.success(I18n.t('cellContractEditScreenEditSuccessMessage'));

      history.push('/cell-contract');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('cellContractEditScreenTitle')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={cellContract}
          onFinish={onFinish}
        >
          <Form.Item
            label={I18n.t('cellContractAddScreenFormMerchant')}
            name="merchantId"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Cascader
              options={[
                {
                  label: `${cellContract.merchant.displayName} (${cellContract.merchant.name})`,
                  value: cellContract.merchant.id,
                },
              ]}
              disabled
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('cellContractAddScreenFormCells')}
            name="cellId"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Cascader
              options={[
                [
                  {
                    label: cellContract.locker.name[userData.locale],
                    value: cellContract.locker.id,
                  },
                ],
                [
                  {
                    label: `${cellContract.cell.name} ($${parseFloat(cellContract.cell.price).toFixed(1)})`,
                    value: cellContract.cellId,
                  },
                ],
              ]}
              disabled
            />
          </Form.Item>

          <Divider />

          <Form.Item
            label={I18n.t('cellContractAddScreenFormType')}
            name="type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Cascader
              options={[
                {
                  label: I18n.t('cellContractAddScreenFormTypeDay'),
                  value: 'DAY_RENTAL',
                },
                {
                  label: I18n.t('cellContractAddScreenFormTypeMonth'),
                  value: 'MONTH_RENTAL',
                },
              ]}
              disabled
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('cellContractAddScreenContractDates')}
            name="contractDates"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <RangePicker
              disabled
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('cellContractEditScreenPrice')}
            name="price"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              disabled
            />
          </Form.Item>

          <Divider />

          <Form.Item
            label={I18n.t('cellContractAddScreenFormRemark')}
            name="remark"
          >
            <TextArea
              placeholder={I18n.t('cellContractAddScreenFormRemark')}
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {I18n.t('cellContractAddScreenButtonSubmit')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
