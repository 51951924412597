import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { PageHeader, Dropdown, Menu, Button, Spin, Badge, Modal, message, Space } from 'antd';
import { EditOutlined, CloseOutlined, SearchOutlined, DownOutlined, EllipsisOutlined , PlusOutlined, SettingOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';
import SuperTable from '../../components/SuperTable';

export default function LockerCellViewScreen(props) {
  const history = useHistory();
  const { lockerId } = useParams();

  const userData = useSelector(state => state.user.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const [loaded, setLoaded] = useState(false);
  const [locker, setLocker] = useState(null);
  const [tableKey, setTableKey] = useState(Math.random().toString());

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        return (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    history.push(`/locker/edit/${record.lockerId}/cell/edit/${record.id}`);
                    break;
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: `Are you sure to remove this cell?`,
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/v1/cell/${record.id}`);
                            message.success('Remove cell successfully');
                            setTableKey(Math.random().toString());
                          } catch (err) {
                            message.error('Unable to remove cell');
                          }
                        })();
                      },
                    });
                    break;
                  }
                }
              }
            }>
              <Menu.Item key="edit">
                <EditOutlined />
                Edit
              </Menu.Item>
              <Menu.Item key="remove">
                <CloseOutlined />
                Remove
              </Menu.Item>
            </Menu>
          }>
            <Button
              type="primary"
              icon=<EllipsisOutlined  />
            />
          </Dropdown>
        );
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'name',
      title: 'Name',
    },
    {
      key: 'size',
      title: 'Size (mm)',
      width: 200,
      render: (value, record) => {
        return `${record.width} x ${record.height} x ${record.depth}`;
      },
    },
    {
      template: 'price',
      key: 'price',
      width: 120,
      title: 'Price',
    },
    {
      template: 'isEnabled',
      key: 'isEnabled',
      width: 100,
      title: 'Enabled',
    },
    {
      template: 'id',
      key: 'lockerlyCellId',
      width: 60,
      title: 'Lockerly ID',
    },
  ];

  useEffect(() => {
    (async () => {
      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const cellRes = await ToolRequest.request('GET', '/v1/cell', {
      lockerId,
      ...body,
    });

    return cellRes;
  };

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title="Locker Cell List"
      extra={[
        ToolPermission.hasPermission(userPermissionsData, 'cell', 'post') && (
          <>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => history.push(`/locker/edit/${lockerId}/cell/add`)}
            >
              Add Cell
            </Button>

            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => history.push(`/locker/edit/${lockerId}/cell/auto-add`)}
            >
              Auto Add Missing Cells
            </Button>
          </>
        ),
      ]}
    >
      <SuperTable
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
    </PageHeader>
  ) : <SpinFull />;
};
