import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

import { PageHeader, Typography, Descriptions, Card, Row, Col, Steps, Image, Divider, Badge } from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPhone from '../../tools/ToolPhone';
import SpinFull from '../../components/SpinFull';
import QRCode from '../../components/QRCode';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title, Text, Paragraph } = Typography;
const { Step } = Steps;

export default function CellOrderDetailScreen(props) {
  const history = useHistory();
  const { orderId } = useParams();
  const userData = useSelector(state => state.user.value);

  const [loaded, setLoaded] = useState(false);
  const [order, setOrder] = useState(null);
  const [lockerCell, setLockerCell] = useState(null);
  const [locker, setLocker] = useState(null);
  const [merchant, setMerchant] = useState(null);

  useEffect(() => {
    (async () => {
      const orderRes = await ToolRequest.request('GET', `/v1/cell-order/${orderId}`);
      setOrder(orderRes);

      const lockerCellRes = await ToolRequest.request('GET', `/v1/cell/${orderRes.cellId}`);
      setLockerCell(lockerCellRes);

      const lockerRes = await ToolRequest.request('GET', `/v1/locker/${lockerCellRes.lockerId}`);
      setLocker(lockerRes);

      const merchantRes = await ToolRequest.request('GET', `/v1/merchant/${orderRes.merchantId}`);
      setMerchant({
        ...merchantRes,
        locale: [merchantRes.locale],
      });

      setLoaded(true);
    })();
  }, []);

  let stateStep = 0;
  let orderBadgeStatus;
  let orderBadgeText;

  if (order) {
    if (order.pickedUpAt) {
      stateStep = 3;
    } else if (order.replenishedAt) {
      stateStep = 1;
    }

    if (order.state === 'WAIT_PICKUP' && new Date().toISOString() >= order.expiredAt) {
      orderBadgeStatus = 'error';
      orderBadgeText = I18n.t('cellOrderViewScreenTableStateWaitPickupExpired');
    } else {
      orderBadgeStatus = {
        'WAIT_REPLENISHMENT': 'warning',
        'WAIT_PICKUP': 'processing',
        'PICKED_UP': 'success',
        'CANCELED': 'default',
        'RECALLED': 'error',
      }[order.state];

      orderBadgeText = {
        'WAIT_REPLENISHMENT': I18n.t('cellOrderViewScreenTableStateWaitReplenishment'),
        'WAIT_PICKUP': I18n.t('cellOrderViewScreenTableStateWaitPickup'),
        'PICKED_UP': I18n.t('cellOrderViewScreenTableStatePickedUp'),
        'CANCELED': I18n.t('cellOrderViewScreenTableStateCanceled'),
        'RECALLED': I18n.t('cellOrderViewScreenTableStateRecalled'),
      }[order.state];
    }
  }

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('cellOrderDetailScreenTitle')}
    >
      <Card>
        <Row gutter={16}>
          <Col span={16}>
            <Descriptions
              bordered
              size="small"
              column={1}
            >
              <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderHumanId')}>
                {order.humanId}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderMerchantName')}>
                {merchant.displayName}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderLockerName')}>
                <Paragraph>
                  {locker.name[userData.locale]}
                  <blockquote>{locker.address[userData.locale]}</blockquote>
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderMerchantCellName')}>
                {lockerCell.name}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderState')}>
                <Badge
                  status={orderBadgeStatus}
                  text={orderBadgeText}
                />
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderTrackNumber')}>
                {order.trackNumber || '-'}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderRecipientPhone')}>
                {order.recipientPhone ? ToolPhone.simplify(order.recipientPhone) : '-'}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderMerchantRemark')}>
                {order.merchantRemark}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderCreatedAt')}>
                {moment(order.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderExpiredAt')}>
                {order.expiredAt ? moment(order.expiredAt).format('YYYY-MM-DD HH:mm:ss') : '-'}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('lockerDetailScreenCellOrderShortUrl')}>
                <Paragraph copyable={{text: order.shortUrl}}>
                  <a href={order.shortUrl} target="_blank">{order.shortUrl}</a>
                </Paragraph>
              </Descriptions.Item>
            </Descriptions>
            <Title level={5} style={{marginTop: 16}}>
              {I18n.t('lockerDetailScreenCellStatusTitle')}
            </Title>
            <Steps direction="vertical" current={stateStep} style={{padding: 16}}>
              <Step
                title={I18n.t('lockerDetailScreenCellStatusCreatedAtTitle')}
                description={`${I18n.t('lockerDetailScreenCellStatusCreatedAt')} ${moment(order.createdAt).format('YYYY-MM-DD HH:mm:ss')}`}
              />
              <Step
                title={I18n.t('lockerDetailScreenCellStatusDeliveredAtTitle')}
                description={order.replenishedAt ? `${I18n.t('lockerDetailScreenCellStatusDeliveredAt')} ${moment(order.replenishedAt).format('YYYY-MM-DD HH:mm:ss')}` : I18n.t('lockerDetailScreenCellStatusDeliveredAtEmpty')}
              />
              <Step
                title={I18n.t('lockerDetailScreenCellStatusPickupAtTitle')}
                description={order.pickedUpAt ? `${I18n.t('lockerDetailScreenCellStatusPickupAt')} ${moment(order.pickedUpAt).format('YYYY-MM-DD HH:mm:ss')}` : I18n.t('lockerDetailScreenCellStatusPickupAtEmpty')}
              />
            </Steps>
          </Col>

          <Col span={8}>
            <Row justify="center" align="center">
              <Col>
                <Row justify="center" align="center">
                  <Col>
                    <Title level={5}>{I18n.t('lockerDetailScreenCellOrderQrCodeTitlePickup')}</Title>
                  </Col>
                </Row>
                <Row justify="center" align="center">
                  <Col>
                    <QRCode
                      value={`PICKUP,${order.pickupKey}`}
                      type="PICKUP"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row justify="center" align="center">
              <Col>
                <Row justify="center" align="center">
                  <Col>
                    <Title level={5}>{order.state === 'WAIT_REPLENISHMENT' ? I18n.t('lockerDetailScreenCellOrderQrCodeTitleDeliver') : I18n.t('lockerDetailScreenCellOrderQrCodeTitleRecall')}</Title>
                  </Col>
                </Row>
                <Row justify="center" align="center">
                  <Col>
                    <QRCode
                      value={order.state === 'WAIT_REPLENISHMENT' ? `DELIVER,${order.replenishmentKey}` : `RECALL,${order.recallKey}`}
                      type={order.state === 'WAIT_REPLENISHMENT' ? 'DELIVER' : 'RECALL'}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </PageHeader>
  ) : <SpinFull />;
};
