import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { PageHeader, Form, Input, Button, Switch, Typography, Spin, Divider, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;

export default function LockerCellEditScreen(props) {
  const history = useHistory();
  const { lockerId } = useParams();
  const { cellId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cell, setCell] = useState(null);

  useEffect(() => {
    (async () => {
      const cellRes = await ToolRequest.request('GET', `/v1/cell/${cellId}`);

      const cellNew = {
        ...cellRes,
        price: (cellRes.price / 1000000),
      };
      setCell(cellNew);

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const data = {
        lockerlyCellId: values.lockerlyCellId || null,
        depth: parseInt(values.depth),
        width: parseInt(values.width),
        height: parseInt(values.height),
        price: values.price ? Math.floor(values.price * 1000000) : null,
        name: values.name,
        isEnabled: values.isEnabled,
      };

      const res = await ToolRequest.request('PUT', `/v1/cell/${cellId}`, data);

      message.success('Update Locker Cell successfully');

      history.push(`/locker/edit/${lockerId}/cell`);
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title="Edit Locker Cell"
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={cell}
          onFinish={onFinish}
        >
          <Form.Item
            label="Cell Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the cell name',
              },
            ]}
          >
            <Input
              placeholder="Cell Name"
            />
          </Form.Item>

          <Form.Item
            label="Enabled"
            name="isEnabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Price"
            name="price"
          >
            <Input
              placeholder="Price"
            />
          </Form.Item>

          <Divider />

          <Form.Item
            label="Width"
            name="width"
            rules={[
              {
                required: true,
                message: 'Please enter the width (mm)',
              },
            ]}
          >
            <Input
              placeholder="mm"
            />
          </Form.Item>

          <Form.Item
            label="Height"
            name="height"
            rules={[
              {
                required: true,
                message: 'Please enter the height (mm)',
              },
            ]}
          >
            <Input
              placeholder="mm"
            />
          </Form.Item>

          <Form.Item
            label="Depth"
            name="depth"
            rules={[
              {
                required: true,
                message: 'Please enter the depth (mm)',
              },
            ]}
          >
            <Input
              placeholder="mm"
            />
          </Form.Item>

          <Divider />

          <Form.Item
            label="Lockerly Cell ID"
            name="lockerlyCellId"
          >
            <Input
              placeholder="Lockerly Cell ID"
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
