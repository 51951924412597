import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

import {PageHeader, Dropdown, Menu, Button, Tooltip, Badge, Modal, message, Form, Input, Cascader, InputNumber} from 'antd';
import { EditOutlined, CloseOutlined, SearchOutlined, DownOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';
import SuperTable from '../../components/SuperTable';
import LockerOnline from '../../components/LockerOnline';
import LockerHistoricalTemperatureModal from './LockerHistoricalTemperatureModal';

export default function LockerViewScreen(props) {
  const history = useHistory();
  const userData = useSelector(state => state.user.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const [loaded, setLoaded] = useState(false);
  const [tableKey, setTableKey] = useState(Math.random().toString());
  const [loading, setLoading] = useState(false);
  const [regionOptions, setRegionOptions] = useState(null);
  const [regions, setRegions] = useState(null);
  const [lockerHistoricalTemperatureModal, setLockerHistoricalTemperatureModal] = useState({
    key: Math.random().toString(),
    lockerId: null,
    visible: false
  });

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = ToolPermission.hasPermission(userPermissionsData, 'locker', 'put');
        const canRemove = ToolPermission.hasPermission(userPermissionsData, 'locker', 'delete');
        const canCopy = ToolPermission.hasPermission(userPermissionsData, 'copy-locker', 'post');
        return canEdit || canRemove || canCopy ? (
          <Dropdown.Button
            type="primary"
            onClick={() => {
              history.push(`/locker/detail/${record.id}`);
            }}
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    history.push(`/locker/edit/${record.id}`);
                    break;
                  }
                  case 'editCell': {
                    history.push(`/locker/edit/${record.id}/cell`);
                    break;
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('lockerViewScreenActionRemoveConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/v1/locker/${record.id}`);
                            message.success(I18n.t('lockerViewScreenActionRemoveSuccessMessage'));
                            setTableKey(Math.random().toString());
                          } catch (err) {
                            message.error(I18n.t('lockerViewScreenActionRemoveErrorMessage'));
                          }
                        })();
                      },
                    });
                    break;
                  }
                  case 'copy': {
                    history.push(`/locker/copy/${record.id}`);
                    break;
                  }
                }
              }
            }>
              {canEdit && (
                <Menu.Item key="edit">
                  <EditOutlined />
                  {I18n.t('lockerViewScreenActionButtonEdit')}
                </Menu.Item>
              )}
              {canEdit && (
                <Menu.Item key="editCell">
                  <EditOutlined />
                  {I18n.t('lockerViewScreenActionButtonEditCells')}
                </Menu.Item>
              )}
              {canRemove && (
                <Menu.Item key="remove">
                  <CloseOutlined />
                  {I18n.t('lockerViewScreenActionButtonRemove')}
                </Menu.Item>
              )}
              {canCopy && (
                <Menu.Item key="copy">
                  <CopyOutlined />
                  {I18n.t('lockerViewScreenActionButtonCopy')}
                </Menu.Item>
              )}
            </Menu>
          }>
            <SearchOutlined />
            {I18n.t('lockerViewScreenActionButtonView')}
          </Dropdown.Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              history.push(`/locker/detail/${record.id}`);
            }}
          >
            <SearchOutlined />
            {I18n.t('lockerViewScreenActionButtonView')}
          </Button>
        );
      },
    },
    ...(ToolPermission.hasPermission(userPermissionsData, 'system-admin', 'get') ? [
      {
        template: 'id',
        key: 'id',
        width: 40,
        title: 'ID',
      },
    ] : []),
    {
      key: 'code',
      title: I18n.t('lockerViewScreenTableCode'),
      width: 150,
    },
    {
      key: 'name',
      title: I18n.t('lockerViewScreenTableName'),
      render: (value) => {
        return value[userData.locale];
      },
    },
    {
      key: 'address',
      title: I18n.t('lockerViewScreenTableAddress'),
      render: (value) => {
        return value[userData.locale];
      },
    },
    {
      key: 'serviceHour',
      title: I18n.t('lockerViewScreenTableServiceHour'),
      render: (value) => {
        return value ? value[userData.locale] : '-';
      },
    },
    {
      key: 'regionId',
      title: I18n.t('lockerViewScreenTableRegion'),
      render: (value) => {
        const region = regions.find(item=>item.id === value);
        return region && region.name[userData.locale];
      },
    },
    {
      key: 'latestTemperature',
      title: I18n.t('lockerViewScreenTableLatestTemperature'),
      width: 150,
      render: (value, row) => {
        return value ? (
          <Tooltip
            title={I18n.t('lockerViewScreenTableClickToViewHistoricalTemperature')}
          >
            <Button
              type="link"
              onClick={() =>{
                setLockerHistoricalTemperatureModal({
                  ...lockerHistoricalTemperatureModal,
                  key: Math.random().toString(),
                  visible: true,
                  lockerId: row.id,
                });
              }}
            >
              {value} °C
            </Button>
          </Tooltip>
        ) : <Tooltip title={I18n.t('lockerViewScreenTableNoTemperature')}>
          <Button
            type="link"
            onClick={() =>{
              setLockerHistoricalTemperatureModal({
                ...lockerHistoricalTemperatureModal,
                key: Math.random().toString(),
                visible: true,
                lockerId: row.id,
              });
            }}
          >
            {I18n.t('lockerViewScreenTableUnknown')}
          </Button>
        </Tooltip>;
      },
    },
    {
      key: 'state',
      title: I18n.t('lockerViewScreenTableState'),
      render: (value,row) => {
        if(row.lockerlyId){
          return <LockerOnline
            key={row.id}
            lockerId={row.id}
          />
        }else {
          return <Badge status="default" text={I18n.t('lockerViewScreenTableUnknown')} />
        }
      },
    },
    {
      template: 'isEnabled',
      key: 'isEnabled',
      title: I18n.t('lockerViewScreenTableIsEnabled'),
    }
  ];

  const searchColumns = [
    {
      template: 'select',
      key: 'regionId',
      title: I18n.t('lockerViewScreenTableSearchRegionAny'),
      options: regionOptions,
    },
    {
      template: 'switch',
      key: 'hasCellContract',
      title: I18n.t('lockerViewScreenTableSearchOnlyHasCellContract'),
      defaultValue: false,
    },
  ];

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
      hasCellContract: values.hasCellContract,
      regionId:values.regionId
    };
  };

  useEffect(() => {
    (async () => {
      const regionRes = await ToolRequest.request('GET', `/v1/region`);
      const regionOptions = regionRes.data.map(region=>{
        return {
          label: region.name[userData.locale],
          value: region.id,
        }
      });
      setRegions(regionRes.data);
      setRegionOptions(regionOptions);
      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const lockerRes = await ToolRequest.request('GET', '/v1/locker', {
      ...body,
    });
    for (let locker of lockerRes.data){
      const temperatureRes = await ToolRequest.request('GET', '/v1/locker-temperature-record', {
        capturedStartAt:moment().subtract(30,'minutes').toISOString(),
        lockerId:locker.id,
        count:1
      });
      let latestTemperature = null;
      if(temperatureRes.data.length && temperatureRes.data[0].temperature){
        latestTemperature = Number(temperatureRes.data[0].temperature).toFixed(1);
      }
      locker.latestTemperature = latestTemperature;
    }
    return lockerRes;
  };

  return loaded ? (
    <>
      <PageHeader
        title={I18n.t('lockerViewScreenTitle')}
        extra={[
          ToolPermission.hasPermission(userPermissionsData, 'locker', 'post') && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => history.push('/locker/add')}
            >
              {I18n.t('lockerViewScreenButtonAdd')}
            </Button>
          ),
        ]}
      >
        <SuperTable
          showSearchInput
          searchColumns={searchColumns}
          searchQueryParams={searchQueryParams}
          tableKey={tableKey}
          onTableKeyChange={key => setTableKey(key)}
          loadDataSource={loadDataSource}
          columns={columns}
        />
      </PageHeader>

      {lockerHistoricalTemperatureModal.lockerId && (
        <LockerHistoricalTemperatureModal
          key={lockerHistoricalTemperatureModal.key}
          visible={lockerHistoricalTemperatureModal.visible}
          lockerId={lockerHistoricalTemperatureModal.lockerId}
          onCancel={() => {
            setLockerHistoricalTemperatureModal({
              ...lockerHistoricalTemperatureModal,
              visible: false,
            });
          }}
        />
      )}
    </>
  ) : <SpinFull />;
};
