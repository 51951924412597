import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {I18n, setLocale} from 'react-redux-i18n';

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Divider,
} from 'antd'

import * as ToolRequest from '../../tools/ToolRequest';
import SpinFull from '../../components/SpinFull';
import * as ToolUser from "../../tools/ToolUser";
import {updateUser} from "../../reducers/userSlice";

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { TextArea } = Input;
const { Option } = Select;

export default function ChangePasswordModal(props) {
  const { visible, onOk, onCancel, userId=null } = props;
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.value);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {

      } catch (err) {
      }

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const data = {
        userId,
        password: values.oldPassword,
        passwordNew: values.newPassword,
      };
      console.dir(data)
      await ToolRequest.request('POST', `/v1/auth/password/change`, data);
      message.success(I18n.t('userEditScreenEditSuccessMessage'));

      onOk();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.dir(err.response.data)
      if (err?.response?.data) {
        switch (err.response.data) {
          case 'INCORRECT_PASSWORD': {
            return message.error(I18n.t('appHeaderChangePasswordModalIncorrectPassword'));
          }
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }
      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return (
    <Modal
      title={I18n.t('appHeaderChangePasswordModalTitle')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            onFinish={onFinish}
          >
            <Form.Item
              name="oldPassword"
              label={I18n.t('appHeaderChangePasswordModalOldPassword')}
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('userAddScreenFormPassword'),
                  })
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Divider />
            <Form.Item
              name="newPassword"
              label={I18n.t('appHeaderChangePasswordModalNewPassword')}
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('userAddScreenFormPassword'),
                  })
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label={I18n.t('appHeaderChangePasswordModalConfirmPassword')}
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('userAddScreenFormPassword'),
                  })
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(I18n.t('appHeaderChangePasswordModalConfirmPasswordErrorMessage')));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('appHeaderChangePasswordModalSubmit')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull />}
    </Modal>
  );
};