import { createSlice } from '@reduxjs/toolkit';

export const messageSlice = createSlice({
  name: 'message',
  initialState: {
    value: {
      systemMessageUnread: 0,
      messageMessageUnread: 0,
    },
  },
  reducers: {
    updateMessage: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
})

export const { updateMessage } = messageSlice.actions;

export default messageSlice.reducer;
