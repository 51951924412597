import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

import { Modal, Form, Input, Spin, Typography, InputNumber, message } from 'antd';
import { SwapRightOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formItemLayout = {
  labelCol: {
    md: { span: 5 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const { Text } = Typography;

export default function LockerCellContractAddModal(props) {
  const { cellId, merchantId, visible, onOk, onCancel } = props;

  const [loading, setLoading] = useState(false);

  const [selectedContractCount, setSelectedContractCount] = useState(1);
  const [estimatedContractStartAt, setEstimatedContractStartAt] = useState(moment());

  const getHour = (day) => {
    return Math.floor(day) * 24 + 8;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setEstimatedContractStartAt(moment());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const estimatedContractEndAt = estimatedContractStartAt && selectedContractCount > 0
    ? moment(estimatedContractStartAt).add(getHour(selectedContractCount, 'hour'))
    : null;

  return (
    <Modal
      title={I18n.t('lockerCellContractAddModalTitle')}
      visible={visible}
      onOk={() => {
        setLoading(true);

        (async () => {
          try {
            // contract dates
            let contractCount = Math.floor(selectedContractCount);

            const data = {
              merchantId,
              cellId,
              type: 'DAY_RENTAL',
              contractCount,
            };

            const res = await ToolRequest.request('POST', '/v1/cell-contract', data);

            message.success(I18n.t('lockerCellContractAddModalAddSuccessMessage'));

            onOk();
          } catch (err) {
            setLoading(false);

            if (err?.response?.data) {
              switch (err.response.data) {
                default:
                  return message.error(I18n.t('errorMessageUnknownError'));
              }
            }

            message.error(I18n.t('errorMessageUnknownError'));
          }
        })();
      }}
      onCancel={onCancel}
      width="80%"
      okText={I18n.t('lockerCellContractAddModalButtonConfirm')}
      cancelText={I18n.t('lockerCellContractAddModalButtonCancel')}
      maskClosable={false}
      okButtonProps={{
        disabled: loading,
      }}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={{
            selectedContractCount,
          }}
        >
          <Form.Item
            label={I18n.t('lockerCellContractAddModalNoOfDay')}
            name="selectedContractCount"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('lockerCellContractAddModalNoOfDay'),
                }),
              },
            ]}
          >
            <InputNumber
              placeholder={I18n.t('lockerCellContractAddModalNoOfDay')}
              min={1}
              onChange={value => setSelectedContractCount(value)}
              value={selectedContractCount}
              addonAfter={`= ${getHour(selectedContractCount)} ${I18n.t('lockerCellContractAddModalHourUnit')}`}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('lockerCellContractAddModalContractDates')}
          >
            <Text type="success">{estimatedContractStartAt.format('YYYY-MM-DD HH:mm')} ({I18n.t('lockerCellContractAddModalSubmissionTime')})</Text>
            <SwapRightOutlined style={{marginLeft: 8, marginRight: 8, color: '#999'}} />
            <Text type="success">{estimatedContractEndAt?.format('YYYY-MM-DD HH:mm')}</Text>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};