import React, { useState, useEffect } from 'react';
import * as ToolRequest from '../tools/ToolRequest'

import { Spin, Badge } from 'antd';
import { I18n } from 'react-redux-i18n'

export default function LockerOnline(props) {
  const {lockerId} = props;
  const [loading, setLoading] = useState(true);
  const [online, setOnline] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await ToolRequest.request('GET', `/v1/locker/${lockerId}/status`);
        setOnline(res.online ? 'ONLINE' : 'OFFLINE');
      } catch (err) {}
      setLoading(false);
    })();
  }, []);

  return loading ? (
    <Spin size="small" spinning={loading} />
  ) : (
    <Badge
      status={{
        'ONLINE': 'success',
        'OFFLINE': 'error',
        null: 'default',
      }[online]}
      text={{
        'ONLINE': I18n.t('lockerViewScreenTableOnline'),
        'OFFLINE': I18n.t('lockerViewScreenTableOffline'),
        null: I18n.t('lockerViewScreenTableUnknown'),
      }[online]}
    />
  );
};
