import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { PageHeader, Dropdown, Menu, Button, Spin, Badge, Tag, Modal, Typography, message } from 'antd';
import { EditOutlined, CloseOutlined, PlusOutlined, EllipsisOutlined, SearchOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';
import SuperTable from '../../components/SuperTable';

const { Text } = Typography;

export default function CellContractViewScreen(props) {
  const history = useHistory();
  const userData = useSelector(state => state.user.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const [loaded, setLoaded] = useState(false);
  const [tableKey, setTableKey] = useState(Math.random().toString());
  const [merchants, setMerchants] = useState([]);
  const [searchLockers, setSearchLockers] = useState([]);
  const [searchLockerLoading, setSearchLockerLoading] = useState(false);

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = ToolPermission.hasPermission(userPermissionsData, 'cell-contract', 'put');
        const canRemove = ToolPermission.hasPermission(userPermissionsData, 'cell-contract', 'delete');
        return canEdit || canRemove ? (
          <Dropdown.Button
            type="primary"
            onClick={() => {
              history.push(`/cell-contract/detail/${record.id}`);
            }}
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    history.push(`/cell-contract/edit/${record.id}`);
                    break;
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('cellContractViewScreenActionRemoveConfirm'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/v1/cell-contract/${record.id}`);
                            message.success(I18n.t('cellContractViewScreenActionRemoveSuccessMessage'));
                            setTableKey(Math.random().toString());
                          } catch (err) {
                            message.error(I18n.t('cellContractViewScreenActionRemoveErrorMessage'));
                          }
                        })();
                      },
                    });
                    break;
                  }
                }
              }
            }>
              {canEdit && (
                <Menu.Item key="edit">
                  <EditOutlined />
                  {I18n.t('cellContractViewScreenActionButtonEdit')}
                </Menu.Item>
              )}
              {canRemove && (
                <Menu.Item key="remove">
                  <CloseOutlined />
                  {I18n.t('cellContractViewScreenActionButtonRemove')}
                </Menu.Item>
              )}
            </Menu>
          }>
            <SearchOutlined />
            {I18n.t('cellContractViewScreenActionButtonView')}
          </Dropdown.Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              history.push(`/cell-contract/detail/${record.id}`);
            }}
          >
            <SearchOutlined />
            {I18n.t('cellContractViewScreenActionButtonView')}
          </Button>
        );
      },
    },
    ...(ToolPermission.hasPermission(userPermissionsData, 'system-admin', 'get') ? [
      {
        template: 'id',
        key: 'id',
        width: 40,
        title: 'ID',
      },
    ] : []),
    {
      key: 'humanId',
      title: I18n.t('cellContractViewScreenTableHumanId'),
    },
    {
      key: 'merchantName',
      title: I18n.t('cellContractViewScreenTableMerchantName'),
      render: (value, record) => {
        return record.merchant.displayName;
      },
    },
    {
      key: 'cell',
      title: I18n.t('cellContractViewScreenTableLockerCell'),
      render: (value, record) => {
        return record.locker && record.cell
          ? (
            <Button
              type="link"
              href={`#/locker/detail/${record.locker.id}`}
            >
              {record.locker.name[userData.locale]} / {record.cell.name}
            </Button>
          ) : <Text type="danger">({I18n.t('cellContractViewScreenTableLockerCellInvalid')})</Text>;
      },
    },
    {
      key: 'type',
      title: I18n.t('cellContractViewScreenTableType'),
      width: 120,
      render: (value, record) => {
        return {
          DAY_RENTAL: <Tag color="volcano">{I18n.t('cellContractViewScreenTableTypeDay')}</Tag>,
          MONTH_RENTAL: <Tag color="cyan">{I18n.t('cellContractViewScreenTableTypeMonth')}</Tag>,
        }[value];
      },
    },
    {
      key: 'status',
      title: I18n.t('cellContractViewScreenTableStatus'),
      width: 100,
      render: (value, record) => {
        const now = new Date().toISOString();
        if (now < record.contractStartAt) {
          return <Badge status="processing" text={I18n.t('cellContractViewScreenTableStatusPending')} />;
        } else if (now >= record.contractStartAt && now < record.contractEndAt) {
          return <Badge status="success" text={I18n.t('cellContractViewScreenTableStatusActive')} />;
        } else {
          return <Badge status="default" text={I18n.t('cellContractViewScreenTableStatusEnded')} />;
        }
      },
    },
    {
      template: 'datetime',
      key: 'contractStartAt',
      title: I18n.t('cellContractViewScreenTableStartAt'),
    },
    {
      template: 'datetime',
      key: 'contractEndAt',
      title: I18n.t('cellContractViewScreenTableEndAt'),
    },
    ...(ToolPermission.hasPermission(userPermissionsData, 'cell-contract', 'get:more') ? [
      {
        template: 'price',
        key: 'price',
        title: I18n.t('cellContractViewScreenTablePrice'),
        width: 120,
      },
    ] : []),
    {
      key: 'remark',
      title: I18n.t('cellContractViewScreenTableRemark'),
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('cellContractViewScreenTableCreatedAt'),
    },
  ];

  const searchColumns = [
    ...(ToolPermission.hasPermission(userPermissionsData, 'merchant', 'get:xm') ? [{
      template: 'select',
      key: 'merchantId',
      title: I18n.t('tableSearchMerchantAny'),
      options: merchants.map(merchant => ({
        label: `${merchant.displayName} (${merchant.name})`,
        value: merchant.id,
      })),
    }]: []),
    {
      template: 'select',
      key: 'lockerId',
      title: I18n.t('tableSearchLockerAny'),
      options: searchLockers.map(locker => ({
        label: `${locker.name[userData.locale]} (${locker.code})`,
        value: locker.id,
      })),
      style: {
        minWidth: 240,
      },
      showSearch: true,
      filterOption: false,
      notFoundContent: null,
      loading: searchLockerLoading,
      onSearch: (value) => {
        setSearchLockerLoading(true);

        (async () => {
          try {
            const lockerRes = await ToolRequest.request('GET', '/v1/locker', {
              searchText: `%${value}%`,
              count: 10,
            });
            setSearchLockers(lockerRes.data.sort((a, b) => {
              if (a.code < b.code) {
                return -1;
              } else if (a.code > b.code) {
                return 1;
              }
              return 0;
            }));
          } catch (err) {
          }

          setSearchLockerLoading(false);
        })();
      },
    },
    {
      template: 'select',
      key: 'type',
      title: I18n.t('tableSearchContractTypeAny'),
      options: [
        {
          label: <Tag color="volcano">{I18n.t('tableSearchContractTypeDay')}</Tag>,
          value: 'DAY_RENTAL',
        },
        {
          label: <Tag color="cyan">{I18n.t('tableSearchContractTypeMonth')}</Tag>,
          value: 'MONTH_RENTAL',
        },
      ],
    },
    {
      template: 'select',
      key: 'status',
      title: I18n.t('cellContractViewScreenTableSearchStatusAny'),
      options: [
        {
          label: <Badge status="processing" text={I18n.t('cellContractViewScreenTableSearchStatusPending')} />,
          value: 'PENDING',
        },
        {
          label: <Badge status="success" text={I18n.t('cellContractViewScreenTableSearchStatusOngoing')} />,
          value: 'ONGOING',
        },
        {
          label: <Badge status="default" text={I18n.t('cellContractViewScreenTableSearchStatusEnded')} />,
          value: 'ENDED',
        },
      ],
    },
  ];

  const searchQueryParams = values => {
    return {
      merchantId: values.merchantId ? values.merchantId : undefined,
      type: values.type ? values.type : undefined,
      status: values.status ? values.status : undefined,
      lockerId: values.lockerId ? values.lockerId : undefined,
    };
  };

  useEffect(() => {
    (async () => {
      try {
        const merchantRes = await ToolRequest.request('GET', '/v1/merchant', {
          count: 1000,
        });
        setMerchants(merchantRes.data);
      } catch (err) {
      }

      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const cellContractRes = await ToolRequest.request('GET', '/v1/cell-contract', {
      ...body,
    });

    const merchantIds = cellContractRes.data.map(contract => contract.merchantId)
      .filter((value, index, self) => self.indexOf(value) === index);

    const merchants = await Promise.all(merchantIds.map(async merchantId => await ToolRequest.request('GET', `/v1/merchant/${merchantId}`).catch(err => {})));

    const cellIds = cellContractRes.data.map(contract => contract.cellId)
      .filter((value, index, self) => self.indexOf(value) === index);

    const cells = (await Promise.all(cellIds.map(async cellId => await ToolRequest.request('GET', `/v1/cell/${cellId}`).catch(err => {}))))
      .filter(value => value);

    const lockerIds = cells.map(cell => cell.lockerId)
      .filter((value, index, self) => self.indexOf(value) === index);

    const lockers = await Promise.all(lockerIds.map(async lockerId => await ToolRequest.request('GET', `/v1/locker/${lockerId}`).catch(err => {})));

    return {
      data: cellContractRes.data.map(contract => {
        const merchant = merchants.find(merchantItem => merchantItem.id === contract.merchantId);
        const cell = cells.find(cellItem => cellItem.id === contract.cellId);
        const locker = cell ? lockers.find(lockerItem => lockerItem.id === cell.lockerId) : null;

        return {
          ...contract,
          merchant,
          locker,
          cell,
        };
      }),
      totalCount: cellContractRes.totalCount,
    };
  };

  return loaded ? (
    <PageHeader
      title={I18n.t('cellContractViewScreenTitle')}
      extra={[
        ToolPermission.hasPermission(userPermissionsData, 'cell-contract', 'post') && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => history.push('/cell-contract/add')}
          >
            {I18n.t('cellContractViewScreenButtonAdd')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        searchColumns={searchColumns}
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
    </PageHeader>
  ) : <SpinFull />;
};
