import React, { useState, useEffect }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';

import { Card, Row, Col, Collapse, Descriptions, List, Avatar, Statistic, Tooltip, Typography, Space, Button, Badge } from 'antd';
import { ArrowRightOutlined, SettingOutlined, FullscreenOutlined } from '@ant-design/icons';

import { updateMessage } from '../../reducers/messageSlice';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';

import '../../less/HomeScreen.less';
import SystemMessageModal from '../App/SystemMessageModal'
import MerchantMessageModal from '../App/MerchantMessageModal'

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

export default function HomeScreen(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector(state => state.user.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);
  const messageData = useSelector(state => state.message.value);

  const isSystemAdmin = ToolPermission.hasPermission(userPermissionsData, 'system-admin', 'get');

  const [loaded, setLoaded] = useState(false);
  const [merchant, setMerchant] = useState(null);

  const [contractDayCount, setContractDayCount] = useState(0);
  const [contractMonthCount, setContractMonthCount] = useState(0);
  const [contractDayPercentage, setContractDayPercentage] = useState(0);
  const [contractMonthPercentage, setContractMonthPercentage] = useState(0);

  const [orderTodayAllCount, setOrderTodayAllCount] = useState(0);
  const [orderTodayDeliveredCount, setOrderTodayDeliveredCount] = useState(0);
  const [orderTodayCompletedCount, setOrderTodayCompletedCount] = useState(0);
  const [orderTodayExpiredCount, setOrderTodayExpiredCount] = useState(0);
  const [systemMessages, setSystemMessages] = useState(null);
  const [merchantMessages, setMerchantMessages] = useState(null);

  const [systemMessageKey, setSystemMessageKey] = useState(Math.random().toString());
  const [merchantMessageKey, setMerchantMessageKey] = useState(Math.random().toString());

  const [systemMessageModal, setSystemMessageModal] = useState(null);

  const [merchantMessageModal, setMerchantMessageModal] = useState(null);

  const loadDataSystemAdmin = async () => {
    // TODO
  };

  const loadDataAdmin = async () => {
    const todayBeginAt = moment().startOf('day');
    const todayEndAt = moment(todayBeginAt).add(1, 'day');

    return Promise.all([
      (async () => {
        const contractRes = await ToolRequest.request('GET', `/v1/cell-contract`, {
          type: 'DAY_RENTAL',
          status: 'ONGOING',
        });
        setContractDayCount(contractRes.totalCount);

        if (contractRes.totalCount > 0) {
          let inUseCount = 0;
          for (let contract of contractRes.data) {
            const orderRes = await ToolRequest.request('GET', `/v1/cell-order`, {
              cellId: contract.cellId,
              count: 1,
            });
            const order = orderRes.data[0];
            if (order && order.state !== 'PICKED_UP' && order.state !== 'CANCELED') {
              ++inUseCount;
            }
          }
          setContractDayPercentage(inUseCount / contractRes.totalCount * 100);
        }
      })(),

      (async () => {
        const contractRes = await ToolRequest.request('GET', `/v1/cell-contract`, {
          type: 'MONTH_RENTAL',
          status: 'ONGOING',
        });
        setContractMonthCount(contractRes.totalCount);

        if (contractRes.totalCount > 0) {
          let inUseCount = 0;
          for (let contract of contractRes.data) {
            const orderRes = await ToolRequest.request('GET', `/v1/cell-order`, {
              cellId: contract.cellId,
              count: 1,
            });
            const order = orderRes.data[0];
            if (order && order.state !== 'PICKED_UP' && order.state !== 'CANCELED') {
              ++inUseCount;
            }
          }
          setContractMonthPercentage(inUseCount / contractRes.totalCount * 100);
        }
      })(),

      (async () => {
        const orderRes = await ToolRequest.request('GET', `/v1/cell-order`, {
          createdAtFrom: todayBeginAt.toISOString(),
          createdAtTo: todayEndAt.toISOString(),
          count: 0,
        });
        setOrderTodayAllCount(orderRes.totalCount);
      })(),

      (async () => {
        const orderRes = await ToolRequest.request('GET', `/v1/cell-order`, {
          createdAtFrom: todayBeginAt.toISOString(),
          createdAtTo: todayEndAt.toISOString(),
          count: 0,
          state: 'WAIT_PICKUP',
        });
        setOrderTodayDeliveredCount(orderRes.totalCount);
      })(),

      (async () => {
        const orderRes = await ToolRequest.request('GET', `/v1/cell-order`, {
          createdAtFrom: todayBeginAt.toISOString(),
          createdAtTo: todayEndAt.toISOString(),
          count: 0,
          state: 'PICKED_UP',
        });
        setOrderTodayCompletedCount(orderRes.totalCount);
      })(),

      (async () => {
        const orderRes = await ToolRequest.request('GET', `/v1/cell-order`, {
          count: 0,
          state: 'WAIT_PICKUP_EXPIRED',
        });
        setOrderTodayExpiredCount(orderRes.totalCount);
      })(),
    ]);
  };

  useEffect(() => {
    (async () => {
      try {
        const merchantRes = await ToolRequest.request('GET', `/v1/merchant/${userData.merchantId}`);
        setMerchant(merchantRes);
        const systemMessageRes = await ToolRequest.request('GET', `/v1/system-message`,{count:5});
        setSystemMessages(systemMessageRes.data);
        const merchantMessages = await ToolRequest.request('GET', `/v1/merchant-message`,{count:5});
        setMerchantMessages(merchantMessages.data);
      } catch (err) {
      }

      if (isSystemAdmin) {
        await loadDataSystemAdmin();
      } else {
        await loadDataAdmin();
      }

      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const systemMessageRes = await ToolRequest.request('GET', `/v1/system-message`, { onlyUnread: true });
      dispatch(updateMessage({
        systemMessageUnread: systemMessageRes.totalCount,
      }));
    })();
  }, [systemMessageKey]);

  useEffect(() => {
    (async () => {
      const merchantMessageRes = await ToolRequest.request('GET', `/v1/merchant-message`, { onlyUnread: true });
      dispatch(updateMessage({
        merchantMessageUnread: merchantMessageRes.totalCount,
      }));
    })();
  }, [merchantMessageKey]);

  const renderSystemAdmin = () => {
    return (
      <div>empty</div>
    );
  };

  const renderAdmin = () => {
    return (
      <>
        <Card>
          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                title={I18n.t('homeScreenAdminTitleMerchantDisplayName')}
                value={merchant.displayName}
              />
            </Col>
            {ToolPermission.hasPermission(userPermissionsData, 'merchant', 'get:more') && (
              <Col span={12}>
                <Statistic
                  title={I18n.t('homeScreenAdminTitleBalance')}
                  value={merchant.balance / 1000000}
                  precision={2}
                  prefix="$"
                />
              </Col>
            )}
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleDayContractCountTooltip')}>
                    {I18n.t('homeScreenAdminTitleDayContractCount')}
                  </Tooltip>
                )}
                value={contractDayCount}
              />
            </Col>

            <Col span={12}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleMonthContractCountTooltip')}>
                    {I18n.t('homeScreenAdminTitleMonthContractCount')}
                  </Tooltip>
                )}
                value={contractMonthCount}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleDayContractUsagePercentTooltip')}>
                    {I18n.t('homeScreenAdminTitleDayContractUsagePercent')}
                  </Tooltip>
                )}
                precision={1}
                value={contractDayPercentage}
                suffix="%"
              />
            </Col>

            <Col span={12}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleMonthContractUsagePercentTooltip')}>
                    {I18n.t('homeScreenAdminTitleMonthContractUsagePercent')}
                  </Tooltip>
                )}
                precision={1}
                value={contractMonthPercentage}
                suffix="%"
              />
            </Col>
          </Row>
        </Card>

        <Title level={5} style={{marginTop: 16}}>{I18n.t('homeScreenAdminTitleTodayOrder')}</Title>

        <Card>
          <Row gutter={16}>
            <Col span={8}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleTodayOrderCountTooltip')}>
                    {I18n.t('homeScreenAdminTitleTodayOrderCount')}
                  </Tooltip>
                )}
                value={orderTodayAllCount}
              />
            </Col>

            <Col span={8}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleTodayOrderDeliveredCountTooltip')}>
                    {I18n.t('homeScreenAdminTitleTodayOrderDeliveredCount')}
                  </Tooltip>
                )}
                value={orderTodayDeliveredCount}
              />
            </Col>

            <Col span={8}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleTodayOrderCompletedCountTooltip')}>
                    {I18n.t('homeScreenAdminTitleTodayOrderCompletedCount')}
                  </Tooltip>
                )}
                value={orderTodayCompletedCount}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleTodayExpiredCountTooltip')}>
                    {I18n.t('homeScreenAdminTitleTodayExpiredCount')}
                  </Tooltip>
                )}
                value={orderTodayExpiredCount}
              />
              <Button
                type="primary"
                style={{marginTop: 4}}
                href="#/cell-order?state=WAIT_PICKUP_EXPIRED"
              >
                {I18n.t('homeScreenAdminTitleTodayExpiredCountButtonView')}
                <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </Card>

        <Title level={5} style={{marginTop: 16}}>{I18n.t('homeScreenMessage')}</Title>
        <Row gutter={16}>
          <Col span={12}>
            <List
              header={
                <div>
                  {I18n.t('homeScreenSystemMessage')}
                  <Badge count={messageData.systemMessageUnread} style={{marginLeft: 8}} />
                </div>
              }
              bordered
              className="message-container"
              itemLayout="horizontal"
              dataSource={systemMessages}
              loadMore={<div
                style={{
                  textAlign: 'center',
                  margin: 16,
                  height: 32,
                  lineHeight: '32px',
                }}
              >
                <Button
                  type="primary"
                  icon={<FullscreenOutlined />}
                  onClick={()=>{
                    setSystemMessageModal({
                      ...systemMessageModal,
                      key: Math.random().toString(),
                      visible: true,
                      systemMessageId: null,
                    });
                  }}
                >
                  {I18n.t('homeScreenMessageMore')}
                </Button>
              </div>}
              renderItem={item => (
                <List.Item
                  actions={[
                    <Tooltip title={moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}>{moment(item.createdAt).fromNow()}</Tooltip>
                  ]}
                  onClick={() => {
                    setSystemMessageModal({
                      ...systemMessageModal,
                      key: Math.random().toString(),
                      visible: true,
                      systemMessageId: item.id,
                    });
                  }}
                >
                  <List.Item.Meta
                    title={<div>
                      <Text ellipsis={true}>{item.title}</Text>
                      {item.isUnread && <Badge status="error" style={{marginLeft: 8}} />}
                    </div>}
                    description={<Text ellipsis={true} type="secondary">{item.contentText.replace(/\r|\n/g, ' ')}</Text>}
                  />
                </List.Item>
              )}
            />
          </Col>
          <Col span={12}>
            <List
              header={
                <div>
                  {I18n.t('homeScreenMerchantMessage')}
                  <Badge count={messageData.merchantMessageUnread} style={{marginLeft: 8}} />
                </div>
              }
              bordered
              className="message-container"
              itemLayout="horizontal"
              dataSource={merchantMessages}
              loadMore={<div
                style={{
                  textAlign: 'center',
                  margin: 16,
                  height: 32,
                  lineHeight: '32px',
                }}
              >
                <Button
                  type="primary"
                  icon={<FullscreenOutlined />}
                  onClick={()=>{
                    setMerchantMessageModal({
                      ...merchantMessageModal,
                      key: Math.random().toString(),
                      visible: true,
                      merchantMessageId: null,
                    });
                  }}
                >
                  {I18n.t('homeScreenMessageMore')}
                </Button>
              </div>}
              renderItem={item => (
                <List.Item
                  actions={[
                    <Tooltip title={moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}>{moment(item.createdAt).fromNow()}</Tooltip>
                  ]}
                  onClick={() => {
                    setMerchantMessageModal({
                      ...merchantMessageModal,
                      key: Math.random().toString(),
                      visible: true,
                      merchantMessageId: item.id,
                    });
                  }}
                >
                  <List.Item.Meta
                    title={<div>
                      <Text ellipsis={true}>{item.title}</Text>
                      {item.isUnread && <Badge status="error" style={{marginLeft: 8}} />}
                    </div>}
                    description={<Text ellipsis={true} type="secondary">{item.contentText.replace(/\r|\n/g, ' ')}</Text>}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>

        {systemMessageModal && <SystemMessageModal
          key={systemMessageModal.key}
          visible={systemMessageModal.visible}
          systemMessageId={systemMessageModal.systemMessageId}
          onCancel={() => {
            setSystemMessageModal({
              ...systemMessageModal,
              visible: false,
            });
            setSystemMessageKey(Math.random().toString())
          }}
        />}

        {merchantMessageModal && <MerchantMessageModal
          key={merchantMessageModal.key}
          visible={merchantMessageModal.visible}
          merchantMessageId={merchantMessageModal.merchantMessageId}
          onCancel={() => {
            setMerchantMessageModal({
              ...merchantMessageModal,
              visible: false,
            });
            setMerchantMessageKey(Math.random().toString())
          }}
        />}
      </>
    );
  };

  return loaded ? (
    isSystemAdmin ? renderSystemAdmin() : renderAdmin()
  ) : <SpinFull />;
};
