import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useSound from 'use-sound';
import { I18n } from 'react-redux-i18n';
import { useLocalStorage } from 'react-use-storage';

import { updateMessage } from '../../reducers/messageSlice';

import * as ToolDevice from '../../tools/ToolDevice';
import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolFirebase from '../../tools/ToolFirebase';

import UserSettingModal from './UserSettingModal';
import ChangePasswordModal from './ChangePasswordModal';
import SystemMessageModal from './SystemMessageModal';
import MerchantMessageModal from './MerchantMessageModal';
import MerchantSettingModal from './MerchantSettingModal';

import { Layout, Dropdown, Menu, Button, message, Space, Badge, Avatar, Tooltip } from 'antd';
import { MenuUnfoldOutlined, NotificationOutlined, MailOutlined, MenuFoldOutlined, DownOutlined, LogoutOutlined, SettingOutlined, EditOutlined, ShopOutlined, LoadingOutlined, MessageFilled, CheckOutlined, CloseOutlined, MessageOutlined } from '@ant-design/icons';

import notificationSound from '../../sounds/notification.mp3';

const { Header } = Layout;

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default function AppHeader(props) {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user.value);
  const messageData = useSelector(state => state.message.value);

  const { collapsed, setCollapsed } = props;
  const [playNotification] = useSound(notificationSound);

  const isMobile = ToolDevice.isMobile();

  const systemMessageUnreadPrev = usePrevious(messageData.systemMessageUnread);
  const merchantMessageUnreadPrev = usePrevious(messageData.merchantMessageUnread);

  const [firebasePermissionAllowed, setFirebasePermissionAllowed, removeFirebasePermissionAllowed] = useLocalStorage('firebasePermissionAllowed', 'false');
  const [firebaseStatus, setFirebaseStatus] = useState('LOADING');

  const [merchant, setMerchant] = useState(null);

  const [systemMessageLoading, setSystemMessageLoading] = useState(true);
  const [systemMessageKey, setSystemMessageKey] = useState(Math.random().toString());
  const [systemMessageModal, setSystemMessageModal] = useState(null);

  const [merchantMessageLoading, setMerchantMessageLoading] = useState(true);
  const [merchantMessageKey, setMerchantMessageKey] = useState(Math.random().toString());
  const [merchantMessageModal, setMerchantMessageModal] = useState(null);

  const [userSettingModal, setUserSettingModal] = useState(null);
  const [changePasswordModal, setChangePasswordModal] = useState(null);
  const [merchantSettingModal, setMerchantSettingModal] = useState(null);

  useEffect(() => {
    (async () => {
      const merchantRes = await ToolRequest.request('GET', `/v1/merchant/${userData.merchantId}`);
      setMerchant(merchantRes);
    })();
  }, []);

  // firebase messaging
  useEffect(() => {
    if (!isMobile) {
      ToolFirebase.init();

      if (firebasePermissionAllowed === 'true') {
        askFirebasePermission();
      } else {
        setFirebaseStatus('ERROR');
      }
    }
  }, []);

  // message
  useEffect(() => {
    const count = messageData.systemMessageUnread + messageData.merchantMessageUnread;
    document.title = `WeCooler Merchant Portal${count > 0 ? ` (${count})` : ''}`;

    if (messageData.systemMessageUnread > systemMessageUnreadPrev || messageData.merchantMessageUnread > merchantMessageUnreadPrev) {
      playNotification();
      message.info(I18n.t('appHeaderNotificationMessageNewMessage'));
    }
  }, [messageData.systemMessageUnread, messageData.merchantMessageUnread]);

  useEffect(() => {
    setSystemMessageLoading(true);
    (async () => {
      const systemMessageRes = await ToolRequest.request('GET', `/v1/system-message`, { onlyUnread: true });
      dispatch(updateMessage({
        systemMessageUnread: systemMessageRes.totalCount,
      }));
      setSystemMessageLoading(false);
    })();
  }, [systemMessageKey]);

  useEffect(() => {
    setMerchantMessageLoading(true);
    (async () => {
      const merchantMessageRes = await ToolRequest.request('GET', `/v1/merchant-message`, { onlyUnread: true });
      dispatch(updateMessage({
        merchantMessageUnread: merchantMessageRes.totalCount,
      }));
      setMerchantMessageLoading(false);
    })();
  }, [merchantMessageKey]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMerchantMessageKey(Math.random().toString());
      setSystemMessageKey(Math.random().toString());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const askFirebasePermission = async () => {
    setFirebasePermissionAllowed('true');

    try {
      const token = await ToolFirebase.getPushToken();

      await ToolRequest.request('POST', `/v1/user-session-device`, {
        deviceId: token,
      });

      setFirebaseStatus('SUCCESS');
    } catch (err) {
      setFirebaseStatus('ERROR');
    }
  };

  const logout = () => {
    return ToolRequest.request('DELETE', '/v1/auth')
      .then(res => {
        window.location.reload();
      })
      .catch(err => {
        message.error(I18n.t('errorMessageUnknownError'));
      });
  };

  return (
    <Header id="mainLayoutHeader">
        <div className="rightContainer">
          <Space size="middle">
            {!isMobile && (
              <Tooltip
                title={firebasePermissionAllowed === 'false' ? I18n.t('appHeaderFirebaseStatusButtonLoadingLabel') : (
                  firebaseStatus === 'SUCCESS' ? I18n.t('appHeaderFirebaseStatusButtonSuccessLabel') : I18n.t('appHeaderFirebaseStatusButtonErrorLabel')
                )}
              >
                <Button
                  type={firebaseStatus === 'LOADING' ? 'default' : 'primary'}
                  shape="circle"
                  icon={firebaseStatus === 'LOADING' ? <LoadingOutlined /> : (firebaseStatus === 'SUCCESS' ? <CheckOutlined /> : <MessageOutlined />)}
                  onClick={() => {
                    if (firebasePermissionAllowed === 'false') {
                      askFirebasePermission();
                    } else if (firebaseStatus !== 'SUCCESS') {
                      message.info(I18n.t('appHeaderFirebaseStatusButtonErrorInfoMessage'));
                    }
                  }}
                />
              </Tooltip>
            )}

            <Badge count={messageData.systemMessageUnread}>
              <Button
                type="default"
                shape="circle"
                icon={systemMessageLoading ? <LoadingOutlined /> : <NotificationOutlined />}
                onClick={()=>{
                  setSystemMessageModal({
                    visible: true,
                    key: Math.random().toString(),
                  });
                }}
              />
            </Badge>

            <Badge count={messageData.merchantMessageUnread}>
              <Button
                type="default"
                shape="circle"
                icon={merchantMessageLoading ? <LoadingOutlined /> : <MailOutlined />}
                onClick={()=>{
                  setMerchantMessageModal({
                    visible: true,
                    key: Math.random().toString(),
                  });
                }}
              />
            </Badge>

            <Dropdown
              type="default"
              onClick={() => {
              }}
              overlay={
                <Menu onClick={value => {
                  switch (value.key) {
                    case 'user-setting': {
                      setUserSettingModal({
                        visible: true,
                        key: Math.random().toString(),
                      });
                      break;
                    }
                    case 'merchant-setting': {
                      setMerchantSettingModal({
                        visible: true,
                        key: Math.random().toString(),
                      });
                      break;
                    }
                    case 'change-password': {
                      setChangePasswordModal({
                        visible: true,
                        key: Math.random().toString(),
                      });
                      break;
                    }
                    case 'logout': {
                      logout();
                      break;
                    }
                  }
                }
                }>
                  <Menu.Item key="user-setting">
                    <SettingOutlined />
                    {I18n.t('appHeaderButtonUserSetting')}
                  </Menu.Item>
                  <Menu.Item key="merchant-setting">
                    <ShopOutlined />
                    {I18n.t('appHeaderButtonMerchantSetting')}
                  </Menu.Item>
                  <Menu.Item key="change-password">
                    <EditOutlined />
                    {I18n.t('appHeaderButtonChangePassword')}
                  </Menu.Item>
                  <Menu.Item key="logout">
                    <LogoutOutlined />
                    {I18n.t('appHeaderButtonLogout')}
                  </Menu.Item>
                </Menu>
              }>
              <Button>
                {I18n.t('appHeaderButtonMyAccount')} <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        </div>

      <div style={{float: 'left'}}>
        {collapsed ? (
          <MenuUnfoldOutlined
            className="trigger"
            onClick={() => setCollapsed(false)}
          />
        ) : (
          <MenuFoldOutlined
            className="trigger"
            onClick={() => setCollapsed(true)}
          />
        )}
      </div>

      <div className="user-message">
        <div>
          {I18n.t('appHeaderGreetings')}
        </div>
        <div>
          {I18n.t('appHeaderCurrentUser')}: {userData ? userData.displayName : ''} ({merchant ? merchant.displayName : '...'})
        </div>
      </div>

      {userSettingModal && <UserSettingModal
        key={userSettingModal.key}
        visible={userSettingModal.visible}
        onOk={() => {
          setUserSettingModal({
            ...userSettingModal,
            visible: false,
          });
        }}
        onCancel={() => {
          setUserSettingModal({
            ...userSettingModal,
            visible: false,
          });
        }}
      />}
      {changePasswordModal && <ChangePasswordModal
        key={changePasswordModal.key}
        visible={changePasswordModal.visible}
        onOk={() => {
          setChangePasswordModal({
            ...changePasswordModal,
            visible: false,
          });
        }}
        onCancel={() => {
          setChangePasswordModal({
            ...changePasswordModal,
            visible: false,
          });
        }}
      />}
      {merchantSettingModal && <MerchantSettingModal
        key={merchantSettingModal.key}
        visible={merchantSettingModal.visible}
        onOk={() => {
          setMerchantSettingModal({
            ...merchantSettingModal,
            visible: false,
          });
        }}
        onCancel={() => {
          setMerchantSettingModal({
            ...merchantSettingModal,
            visible: false,
          });
        }}
      />}
      {systemMessageModal && <SystemMessageModal
        key={systemMessageModal.key}
        visible={systemMessageModal.visible}
        onCancel={() => {
          setSystemMessageModal({
            ...systemMessageModal,
            visible: false,
          });
        }}
      />}
      {merchantMessageModal && <MerchantMessageModal
        key={merchantMessageModal.key}
        visible={merchantMessageModal.visible}
        onCancel={() => {
          setMerchantMessageModal({
            ...merchantMessageModal,
            visible: false,
          });
        }}
      />}
    </Header>
  );
};
