import React, { useState, useEffect } from 'react';

import { Modal, Cascader, Button, Space } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';

import ReactECharts from 'echarts-for-react';
import moment from 'moment';

import LockerHistoricalTemperatureChart from '../../components/LockerHistoricalTemperatureChart';

import * as ToolRequest from '../../tools/ToolRequest';
import { I18n } from 'react-redux-i18n'

export default function LockerHistoricalTemperatureModal(props) {
  const { visible, onCancel, lockerId } = props;

  const [chartKey, setChartKey] = useState(new Date().getTime());
  const [timespan, setTimespan] = useState(48);

  const api = window.getConfig().apiHost;

  const capturedEndAt = moment();
  const capturedStartAt = moment(capturedEndAt).subtract(timespan, 'hour');

  return (
    <Modal
      title={I18n.t('lockerHistoricalTemperatureModalTitle')}
      visible={visible}
      onCancel={onCancel}
      width="60%"
      maskClosable={false}
      footer={null}
    >
      <Space wrap>
        <Cascader
          allowClear={false}
          value={[timespan]}
          onChange={(values) => {
            setTimespan(values[0]);
            setChartKey(new Date().getTime());
          }}
          options={[
            {
              label: I18n.t('lockerHistoricalTemperatureModalTimespan48Label'),
              value: 48,
            },
            {
              label: I18n.t('lockerHistoricalTemperatureModalTimespan168Label'),
              value: 168,
            },
          ]}
        />

        <Button
          type="primary"
          target="_blank"
          icon={<CloudDownloadOutlined />}
          href={`${api}/v1/locker-temperature-record-download?lockerId=${lockerId}&capturedStartAt=${capturedStartAt.toISOString()}&capturedEndAt=${capturedEndAt.toISOString()}`}
        >
          {I18n.t('lockerHistoricalTemperatureModalButtonDownload')}
        </Button>
      </Space>

      <LockerHistoricalTemperatureChart
        key={chartKey}
        lockerId={lockerId}
        timespan={timespan}
      />
    </Modal>
  );
};
