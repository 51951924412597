import React, { useState, useEffect } from 'react';
import {I18n} from 'react-redux-i18n';
import { Modal, Form, Input, Button, Spin, Select, message, InputNumber } from 'antd';
import * as ToolRequest from '../../tools/ToolRequest';
import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { TextArea} = Input;
const { Option } = Select;

export default function MerchantAddTransactionScreen(props) {
  const {merchantId, visible, onOk, onCancel } = props;
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [merchant, setMerchant] = useState(null);

  useEffect(() => {
    (async () => {
      const merchantRes = await ToolRequest.request('GET', `/v1/merchant/${merchantId}`);
      setMerchant({
        ...merchantRes,
      });
      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const data = {
        merchantId,
        type:values.type,
        amount:values.amount * 1000000,
        remark:values.remark
      };
      await ToolRequest.request('POST', `/v1/merchant-transaction`, data);

      message.success(I18n.t('userEditScreenEditSuccessMessage'));

      onOk();
      setLoading(false);
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return (
    <Modal
      title={I18n.t('merchantAddTransactionModalTitle')}
      visible={visible}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={
              {
                name:merchant.name
              }
            }
            onFinish={onFinish}
          >
            <Form.Item
              label={I18n.t('merchantAddTransactionModalMerchantName')}
              name="name"
            >
              <Input
                placeholder={I18n.t('merchantAddTransactionModalMerchantName')}
                disabled
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('merchantAddTransactionModalType')}
              name="type"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredSelectAny', {
                    name: I18n.t('merchantAddTransactionModalType'),
                  }),
                },
              ]}
            >
              <Select placeholder={I18n.t('merchantAddTransactionModalType')}>
                <Option value="TRANSFER">
                  {I18n.t('merchantAddTransactionModalTypeTransfer')}
                </Option>
                <Option value="DAY_RENTAL">
                  {I18n.t('merchantAddTransactionModalTypeDayRental')}
                </Option>
                <Option value="MONTH_RENTAL">
                  {I18n.t('merchantAddTransactionModalTypeMonthRental')}
                </Option>
                <Option value="CREDIT">
                  {I18n.t('merchantAddTransactionModalTypeCredit')}
                </Option>
                <Option value="REFUND">
                  {I18n.t('merchantAddTransactionModalTypeRefund')}
                </Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={I18n.t('merchantAddTransactionModalAmount')}
              name="amount"
              rules={[
                {
                  required: true,
                }
              ]}
            >
              <InputNumber placeholder={I18n.t('merchantAddTransactionModalAmount')} defaultValue={1} step={0.01} style={{ width: 150 }} />
            </Form.Item>

            <Form.Item
              label={I18n.t('merchantAddTransactionModalRemark')}
              name="remark"
            >
              <TextArea
                placeholder={I18n.t('merchantAddTransactionModalRemark')}
              />
            </Form.Item>

            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('merchantAddTransactionModalSubmit')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull />}
    </Modal>
  );
};