import { createSlice } from '@reduxjs/toolkit';

export const userPermissionsSlice = createSlice({
  name: 'userPermissions',
  initialState: {
    value: {},
  },
  reducers: {
    updateUserPermissions: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
})

export const { updateUserPermissions } = userPermissionsSlice.actions;

export default userPermissionsSlice.reducer;
