import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, Divider, message } from 'antd';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';
import LockerCellLayoutModal from './LockerCellLayoutModal';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;
const { TextArea } = Input;

export default function LockerEditScreen(props) {
  const history = useHistory();
  const metadata = useSelector(state => state.metadata.value);
  const { lockerId } = useParams();
  const userData = useSelector(state => state.user.value);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locker, setLocker] = useState(null);

  const [cellLayout, setCellLayout] = useState([[[]]]);
  const [coordinateLat, setCoordinateLat] = useState(null);
  const [coordinateLng, setCoordinateLng] = useState(null);
  const [regionOptions, setRegionOptions] = useState(null);
  const [cellLayoutModal, setCellLayoutModal] = useState({
    key: Math.random().toString(),
    visible: false,
  });
  useEffect(() => {
    (async () => {
      const lockerRes = await ToolRequest.request('GET', `/v1/locker/${lockerId}`);

      const lockerNew = {
        ...lockerRes,
        regionId:[lockerRes.regionId]
      };
      for (let locale of metadata.supportedLocales) {
        lockerNew[`name[${locale}]`] = lockerRes.name[locale];
        lockerNew[`address[${locale}]`] = lockerRes.address[locale];
        lockerNew[`serviceHour[${locale}]`] = lockerRes.serviceHour ? lockerRes.serviceHour[locale] : null;
      }
      if (lockerRes.coordinate) {
        const tmp = lockerRes.coordinate.split(',');
        setCoordinateLat(tmp[0]);
        setCoordinateLng(tmp[1]);
      }
      setLocker(lockerNew);
      const regionRes = await ToolRequest.request('GET', `/v1/region`);
      const regionOptions = regionRes.data.map(region=>{
        return {
          label: region.name[userData.locale],
          value: region.id,
        }
      });
      setRegionOptions(regionOptions);
      setCellLayout(lockerRes.cellLayout.map(side => side.map(row => row.map(col => ({
        name: col,
      })))));

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const name = {};
      const address = {};
      const coordinate = coordinateLat && coordinateLng ? `${coordinateLat},${coordinateLng}` : null;

      for (let locale of metadata.supportedLocales) {
        name[locale] = values[`name[${locale}]`];
        address[locale] = values[`address[${locale}]`];
      }

      let serviceHour = null;
      if (metadata.supportedLocales.some(locale => values[`serviceHour[${locale}]`] && values[`serviceHour[${locale}]`].trim() !== '')) {
        serviceHour = {};
        for (let locale of metadata.supportedLocales) {
          serviceHour[locale] = values[`serviceHour[${locale}]`];
        }
      }

      const cellLayoutValue = cellLayout.map(side => side.map(row => row.map(col => col.name)));

      const data = {
        name,
        address,
        serviceHour,
        isEnabled: values.isEnabled,
        regionId:values.regionId ? values.regionId[0] : null,
        coordinate,
        cellLayout: cellLayoutValue,
        isVisible: values.isVisible,
        lockerlyId: values.lockerlyId || null,
      };

      const res = await ToolRequest.request('PUT', `/v1/locker/${lockerId}`, data);

      message.success(I18n.t('lockerEditScreenEditSuccessMessage'));

      history.push('/locker');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  let cellLayoutMaxWidth = 0;
  for (let row of cellLayout) {
    if (row.length > cellLayoutMaxWidth) {
      cellLayoutMaxWidth = row.length;
    }
  }

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('lockerEditScreenTitle')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={locker}
          onFinish={onFinish}
        >
          <Form.Item
            label={I18n.t('lockerAddScreenFormCode')}
            name="code"
            rules={[
              {
                required: true,
                message: 'Please enter the code',
              },
            ]}
          >
            <Input
              placeholder={I18n.t('lockerAddScreenFormCode')}
              disabled
            />
          </Form.Item>

          <Divider />
          <Form.Item
            label={I18n.t('lockerAddScreenFormRegion')}
            name="regionId"
            rules={[
              {
                required: true,
                message: 'Please select the region',
              },
            ]}
          >
            <Cascader
              allowClear={false}
              options={regionOptions}
              placeholder={I18n.t('lockerAddScreenFormRegion')}
            />
          </Form.Item>
          {metadata.supportedLocales.map(locale => {
            return (
              <Form.Item
                key={`name[${locale}]`}
                label={`${I18n.t('lockerAddScreenFormName')} (${locale})`}
                name={`name[${locale}]`}
                rules={[
                  {
                    required: true,
                    message: I18n.t('errorMessageRequiredInputAny', {
                      name: `${I18n.t('lockerAddScreenFormName')} (${locale})`,
                    }),
                  },
                ]}
              >
                <Input
                  placeholder={`${I18n.t('lockerAddScreenFormName')} (${locale})`}
                />
              </Form.Item>
            );
          })}

          <Divider />

          {metadata.supportedLocales.map(locale => {
            return (
              <Form.Item
                key={`address[${locale}]`}
                label={`${I18n.t('lockerAddScreenFormAddress')} (${locale})`}
                name={`address[${locale}]`}
                rules={[
                  {
                    required: true,
                    message: I18n.t('errorMessageRequiredInputAny', {
                      name: `${I18n.t('lockerAddScreenFormAddress')} (${locale})`,
                    }),
                  },
                ]}
              >
                <TextArea
                  placeholder={`${I18n.t('lockerAddScreenFormAddress')} (${locale})`}
                />
              </Form.Item>
            );
          })}

          <Divider />

          {metadata.supportedLocales.map(locale => {
            return (
              <Form.Item
                key={`serviceHour[${locale}]`}
                label={`${I18n.t('lockerAddScreenFormServiceHour')} (${locale})`}
                name={`serviceHour[${locale}]`}
              >
                <Input
                  placeholder={`${I18n.t('lockerAddScreenFormServiceHour')} (${locale})`}
                />
              </Form.Item>
            );
          })}

          <Divider />

          <Form.Item
            label={I18n.t('lockerAddScreenFormCoordinate')}
            name="coordinate"
          >
            <Input.Group compact>
              <Input
                style={{width: 100}}
                placeholder={I18n.t('lockerAddScreenFormCoordinateLatPlaceholder')}
                onChange={e => setCoordinateLat(e.target.value)}
                value={coordinateLat}
              />
              <Input
                style={{width: 100}}
                placeholder={I18n.t('lockerAddScreenFormCoordinateLngPlaceholder')}
                onChange={e => setCoordinateLng(e.target.value)}
                value={coordinateLng}
              />
            </Input.Group>
          </Form.Item>

          <Form.Item
            label={I18n.t('lockerAddScreenFormEnabled')}
            name="isEnabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label={I18n.t('lockerAddScreenFormVisible')}
            name="isVisible"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Divider />

          <Form.Item
            label={I18n.t('lockerAddScreenFormCellLayout')}
            name="cellLayout"
          >
            <Button onClick={() => {
              setCellLayoutModal({
                ...cellLayoutModal,
                key: Math.random().toString(),
                visible: true,
              });
            }}>
              <EditOutlined /> {cellLayout.length && cellLayout[0].length && cellLayout[0][0].length ? `${cellLayout.length} x ${cellLayoutMaxWidth} x ${cellLayout.length}` : 'Not yet set up'}
            </Button>
          </Form.Item>

          <Form.Item
            label={I18n.t('lockerAddScreenFormLockerlyLockerId')}
            name="lockerlyId"
          >
            <Input
              placeholder={I18n.t('lockerAddScreenFormLockerlyLockerId')}
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {I18n.t('lockerAddScreenButtonSubmit')}
            </Button>
          </Form.Item>
        </Form>

        <LockerCellLayoutModal
          key={cellLayoutModal.key}
          visible={cellLayoutModal.visible}
          cellLayout={cellLayout}
          onOk={(cellLayoutNew) => {
            setCellLayout(cellLayoutNew);

            setCellLayoutModal({
              ...cellLayoutModal,
              visible: false,
            });
          }}
          onCancel={() => {
            setCellLayoutModal({
              ...cellLayoutModal,
              visible: false,
            });
          }}
        />
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
