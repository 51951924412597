import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { PageHeader, Dropdown, Menu, Button, Spin, Badge, Modal, message } from 'antd';
import { EditOutlined, CloseOutlined, SearchOutlined, DownOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';
import SuperTable from '../../components/SuperTable';
import MerchantAddTransactionModalScreen from './MerchantAddTransactionModalScreen'

export default function MerchantViewScreen(props) {
  const history = useHistory();
  const userData = useSelector(state => state.user.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const [loaded, setLoaded] = useState(false);
  const [tableKey, setTableKey] = useState(Math.random().toString());

  const [merchantAddTransactionModal, setMerchantAddTransactionModal] = useState({
    key: Math.random().toString(),
    merchantId:'',
    visible: false
  });

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = ToolPermission.hasPermission(userPermissionsData, 'merchant', 'put');
        const canRemove = ToolPermission.hasPermission(userPermissionsData, 'merchant', 'delete');
        const canAddTransaction = ToolPermission.hasPermission(userPermissionsData, 'system-admin', 'get');
        return canEdit || canRemove ? (
          <Dropdown.Button
            type="primary"
            onClick={() => {
              if(ToolPermission.hasPermission(userPermissionsData, 'merchant', 'put')){
                history.push(`/merchant/detail/${record.id}`);
              }
            }}
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    history.push(`/merchant/edit/${record.id}`);
                    break;
                  }
                  case 'add-transaction': {
                    setMerchantAddTransactionModal({
                      ...merchantAddTransactionModal,
                      merchantId:record.id,
                      visible: true
                    });
                    break;
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('merchantViewScreenActionRemoveConfirm'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/v1/merchant/${record.id}`);
                            message.success(I18n.t('merchantViewScreenActionRemoveSuccessMessage'));
                            setTableKey(Math.random().toString());
                          } catch (err) {
                            message.error(I18n.t('merchantViewScreenActionRemoveErrorMessage'));
                          }
                        })();
                      },
                    });
                    break;
                  }
                }
              }
            }>
              {canEdit && (
                <Menu.Item key="edit">
                  <EditOutlined />
                  {I18n.t('merchantViewScreenActionButtonEdit')}
                </Menu.Item>
              )}
                {canAddTransaction && (
                  <Menu.Item key="add-transaction">
                    <EditOutlined />
                    {I18n.t('merchantViewScreenActionButtonAddTransaction')}
                  </Menu.Item>
                )}
              {canRemove && (
                <Menu.Item key="remove">
                  <CloseOutlined />
                  {I18n.t('merchantViewScreenActionButtonRemove')}
                </Menu.Item>
              )}
            </Menu>
          }>
            <SearchOutlined />
            {I18n.t('merchantViewScreenActionButtonView')}
          </Dropdown.Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              history.push(`/merchant/detail/${record.id}`);
            }}
          >
            <SearchOutlined />
            {I18n.t('merchantViewScreenActionButtonView')}
          </Button>
        );
      },
    },
    ...(ToolPermission.hasPermission(userPermissionsData, 'system-admin', 'get') ? [
      {
        template: 'id',
        key: 'id',
        width: 40,
        title: 'ID',
      },
    ] : []),
    {
      key: 'displayName',
      title: I18n.t('merchantViewScreenTableDisplayName'),
    },
    {
      key: 'name',
      title: I18n.t('merchantViewScreenTableName'),
      width: 150,
    },
    {
      template: 'price',
      key: 'balance',
      title: I18n.t('merchantViewScreenTableBalance'),
      width: 120,
    },
    {
      template: 'contactPhone',
      key: 'contactPhone',
      title: I18n.t('merchantViewScreenTableContactPhone'),
      width: 120,
    },
    {
      template: 'isLocked',
      key: 'isLocked',
      title: I18n.t('merchantViewScreenIsLocked'),
      width: 80,
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('merchantViewScreenCreatedAt'),
    },
  ];

  const searchColumns = [
  ];

  const searchQueryParams = values => {
    return {
    };
  };

  useEffect(() => {
    (async () => {
      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const merchantRes = await ToolRequest.request('GET', '/v1/merchant', {
      ...body,
    });

    return merchantRes;
  };

  return loaded ? (
    <PageHeader
      title={I18n.t('merchantViewScreenTitle')}
      extra={[
        ToolPermission.hasPermission(userPermissionsData, 'merchant', 'post') && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => history.push('/merchant/add')}
          >
            {I18n.t('merchantViewScreenButtonAdd')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        searchColumns={searchColumns}
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
      {merchantAddTransactionModal.merchantId && <MerchantAddTransactionModalScreen
        key={merchantAddTransactionModal.key}
        merchantId={merchantAddTransactionModal.merchantId}
        visible={merchantAddTransactionModal.visible}
        onOk={() => {
          setMerchantAddTransactionModal({
            ...merchantAddTransactionModal,
            visible: false,
          });
        }}
        onCancel={() => {
          setMerchantAddTransactionModal({
            ...merchantAddTransactionModal,
            visible: false,
          });
        }}
      />}
    </PageHeader>
  ) : <SpinFull />;
};
