import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import {
  PageHeader,
  Form,
  Input,
  Button,
  InputNumber,
  Spin,
  Divider,
  message,
  Select,
} from 'antd'

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Option } = Select;
const { TextArea } = Input;

export default function CellContractEditScreen(props) {
  const history = useHistory();
  const userData = useSelector(state => state.user.value);
  const { cellOrderId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cellOrder, setCellOrder] = useState(null);
  const [selectedPhonePrefix, setSelectedPhonePrefix] = useState('+852');

  useEffect(() => {
    (async () => {
      const cellOrderRes = await ToolRequest.request('GET', `/v1/cell-order/${cellOrderId}`);
      const cellRes = await ToolRequest.request('GET', `/v1/cell/${cellOrderRes.cellId}`);
      const lockerRes = await ToolRequest.request('GET', `/v1/locker/${cellRes.lockerId}`);
      cellOrderRes.lockerAndCellName = `${lockerRes.name[userData.locale]} / ${cellRes.name}`;
      if(cellOrderRes.recipientPhone){
        cellOrderRes.recipientPhone = cellOrderRes.recipientPhone.split('+852-')[1];
      }
      setCellOrder(cellOrderRes);
      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      let data = {
        recipientPhone: values.recipientPhone ? `${selectedPhonePrefix}-${values.recipientPhone}` : null,
        merchantRemark: values.merchantRemark,
        trackNumber: values.trackNumber,
        recipientExpiryHour: values.recipientExpiryHour,
      };

      if(cellOrder.state !== 'WAIT_REPLENISHMENT'){
        data = {
          merchantRemark: values.merchantRemark
        };
      }

      await ToolRequest.request('PUT', `/v1/cell-order/${cellOrderId}`, data);
      message.success(I18n.t('cellOrderEditScreenEditSuccessMessage'));
      history.push('/cell-order');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('cellOrderEditScreenTitle')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={cellOrder}
          onFinish={onFinish}
        >
          <Form.Item
            label={I18n.t('cellOrderEditScreenCell')}
            name="lockerAndCellName"
          >
            <Input
              disabled={true}
              placeholder={I18n.t('lockerCellOrderAddModalTrackNumber')}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('cellOrderEditScreenTrackNumber')}
            name="trackNumber"
          >
            <Input
              disabled={cellOrder.state !== 'WAIT_REPLENISHMENT'}
              placeholder={I18n.t('cellOrderEditScreenTrackNumber')}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('cellOrderEditScreenRecipientPhone')}
            name="recipientPhone"
          >
            <Input
              disabled={cellOrder.state !== 'WAIT_REPLENISHMENT'}
              addonBefore={(
                <Select value={selectedPhonePrefix} onChange={value => setSelectedPhonePrefix(value)}>
                  <Option value="+852">+852</Option>
                </Select>
              )}
              placeholder={I18n.t('cellOrderEditScreenRecipientPhone')}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('cellOrderEditScreenRecipientExpiryHour')}
            name="recipientExpiryHour"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              disabled={cellOrder.state !== 'WAIT_REPLENISHMENT'}
              min={1}
              defaultValue={1}
              placeholder={I18n.t('cellOrderEditScreenRecipientExpiryHour')}
            />
          </Form.Item>

          <Divider />

          <Form.Item
            label={I18n.t('cellOrderEditScreenRemark')}
            name="merchantRemark"
          >
              <TextArea
                placeholder={I18n.t('cellOrderEditScreenRemark')}
              />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {I18n.t('cellOrderEditScreenSubmit')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
