export default {
  "appHeaderButtonChangePassword": "修改密码",
  "appHeaderButtonLogout": "登出",
  "appHeaderButtonMerchantSetting": "商户设定",
  "appHeaderButtonMyAccount": "我的帐号",
  "appHeaderButtonUserSetting": "我的设定",
  "appHeaderChangePasswordModalConfirmPassword": "确认密码",
  "appHeaderChangePasswordModalConfirmPasswordErrorMessage": "你输入的两个新密码不一致！",
  "appHeaderChangePasswordModalIncorrectPassword": "旧密码不正确，请重新输入",
  "appHeaderChangePasswordModalNewPassword": "新密码",
  "appHeaderChangePasswordModalOldPassword": "您的密码",
  "appHeaderChangePasswordModalSubmit": "提交",
  "appHeaderChangePasswordModalTitle": "修改密码",
  "appHeaderCurrentUser": "已登入",
  "appHeaderFirebaseStatusButtonErrorInfoMessage": "您可以在浏览器网址栏附近, 打开设置允许推送通知",
  "appHeaderFirebaseStatusButtonErrorLabel": "推送通知未开启",
  "appHeaderFirebaseStatusButtonLoadingLabel": "按此允许推送通知",
  "appHeaderFirebaseStatusButtonSuccessLabel": "已开启推送通知",
  "appHeaderGreetings": "您好!",
  "appHeaderMerchantMessageModalTableCreatedAt": "建立日期",
  "appHeaderMerchantMessageModalTableState": "状态",
  "appHeaderMerchantMessageModalTableStateRead": "已读",
  "appHeaderMerchantMessageModalTableStateUnread": "未读",
  "appHeaderMerchantMessageModalTableTitle": "标题",
  "appHeaderMerchantMessageModalTitle": "商户消息",
  "appHeaderMerchantSettingModalTitle": "修改商户设定",
  "appHeaderNotificationMessageNewMessage": "您有新讯息",
  "appHeaderSystemMessageModalTableCreatedAt": "建立日期",
  "appHeaderSystemMessageModalTableState": "状态",
  "appHeaderSystemMessageModalTableStateRead": "已读",
  "appHeaderSystemMessageModalTableStateUnread": "未读",
  "appHeaderSystemMessageModalTableTitle": "标题",
  "appHeaderSystemMessageModalTitle": "系统消息",
  "appHeaderUserSettingModalTitle": "编辑",

  "appMenuHome": "主页",
  "appMenuHomeContractList": "合约列表",
  "appMenuHomeMerchantList": "商户列表",
  "appMenuHomeOrderList": "订单列表",
  "appMenuLocker": "自提柜",
  "appMenuLockerList": "自提柜列表",
  "appMenuMerchant": "商户",
  "appMenuMyBill": "我的帐单",
  "appMenuMyContract": "我的合约",
  "appMenuMyOrder": "我的订单",
  "appMenuRegionList": "地区列表",
  "appMenuTransactionList": "我的交易记录",
  "appMenuUser": "用户",

  "cellContractAddScreenAddSuccessMessage": "添加 %{count} 合约成功",
  "cellContractAddScreenButtonSubmit": "提交",
  "cellContractAddScreenContractDates": "合约有效时间",
  "cellContractAddScreenFormCells": "自提柜格子",
  "cellContractAddScreenFormContractMonths": "合约有效月份",
  "cellContractAddScreenFormHourUnit": "小时",
  "cellContractAddScreenFormMerchant": "商户",
  "cellContractAddScreenFormNoOfDay": "日数",
  "cellContractAddScreenFormRemark": "备注",
  "cellContractAddScreenFormSubmissionTime": "提交时间",
  "cellContractAddScreenFormType": "类别",
  "cellContractAddScreenFormTypeDay": "日租",
  "cellContractAddScreenFormTypeMonth": "月租",
  "cellContractAddScreenTitle": "添加合约",
  "cellContractCellPriceSelectButtonAdd": "选择自提柜格子",
  "cellContractCellPriceSelectButtonBatchAdd": "批量选择自提柜格子",
  "cellContractCellPriceSelectButtonRemove": "删除",
  "cellContractCellPriceSelectModalButtonSubmit": "提交",
  "cellContractCellPriceSelectModalFormLockerCell": "自提柜格子",
  "cellContractCellPriceSelectModalFormPrice": "价钱",
  "cellContractCellPriceSelectModalFormPriceErrorInvalidMessage": "请输入有效的价钱",
  "cellContractCellPriceSelectModalTitle": "选择自提柜格子",
  "cellContractDetailScreenCellName": "格子",
  "cellContractDetailScreenCellPrice": "格子价钱",
  "cellContractDetailScreenCreatedAt": "建立时间",
  "cellContractDetailScreenEndAt": "结束时间",
  "cellContractDetailScreenHumanId": "合约号",
  "cellContractDetailScreenLockerName": "自提柜",
  "cellContractDetailScreenMerchantName": "商户",
  "cellContractDetailScreenMonthRental": "月租",
  "cellContractDetailScreenRemark": "备注",
  "cellContractDetailScreenStartAt": "开始时间",
  "cellContractDetailScreenStatus": "状态",
  "cellContractDetailScreenStatusActive": "生效中",
  "cellContractDetailScreenStatusEnded": "已结束",
  "cellContractDetailScreenStatusPending": "未开始",
  "cellContractDetailScreenTitle": "合约详情",
  "cellContractDetailScreenTotalPrice": "总价钱",
  "cellContractDetailScreenType": "类别",
  "cellContractDetailScreenTypeDayRental": "日租",
  "cellContractEditScreenEditSuccessMessage": "修改合约成功",
  "cellContractEditScreenPrice": "价钱",
  "cellContractEditScreenTitle": "编辑合约",
  "cellContractViewScreenActionButtonEdit": "编辑",
  "cellContractViewScreenActionButtonRemove": "删除",
  "cellContractViewScreenActionButtonView": "查看",
  "cellContractViewScreenActionRemoveConfirm": "您确定要删除此合约?",
  "cellContractViewScreenActionRemoveErrorMessage": "删除合约失败",
  "cellContractViewScreenActionRemoveSuccessMessage": "删除合约成功",
  "cellContractViewScreenButtonAdd": "添加合约",
  "cellContractViewScreenTableCreatedAt": "建立时间",
  "cellContractViewScreenTableEndAt": "结束时间",
  "cellContractViewScreenTableHumanId": "合约号",
  "cellContractViewScreenTableLockerCell": "自提柜格子",
  "cellContractViewScreenTableLockerCellInvalid": "无效格子",
  "cellContractViewScreenTableMerchantName": "商户",
  "cellContractViewScreenTablePrice": "价钱",
  "cellContractViewScreenTableRemark": "备注",
  "cellContractViewScreenTableSearchStatusAny": "任何状态",
  "cellContractViewScreenTableSearchStatusEnded": "已结束",
  "cellContractViewScreenTableSearchStatusOngoing": "生效中",
  "cellContractViewScreenTableSearchStatusPending": "未开始",
  "cellContractViewScreenTableStartAt": "开始时间",
  "cellContractViewScreenTableStatus": "状态",
  "cellContractViewScreenTableStatusActive": "生效中",
  "cellContractViewScreenTableStatusEnded": "已结束",
  "cellContractViewScreenTableStatusPending": "未开始",
  "cellContractViewScreenTableType": "类别",
  "cellContractViewScreenTableTypeDay": "日租",
  "cellContractViewScreenTableTypeMonth": "月租",
  "cellContractViewScreenTitle": "合约列表",

  "cellOrderDetailScreenTitle": "订单详情",
  "cellOrderEditScreenCell": "自提柜格子",
  "cellOrderEditScreenEditSuccessMessage": "修改订单成功",
  "cellOrderEditScreenRecipientExpiryHour": "取货期限 (小时)",
  "cellOrderEditScreenRecipientPhone": "收货人电话",
  "cellOrderEditScreenRemark": "备注",
  "cellOrderEditScreenSubmit": "提交",
  "cellOrderEditScreenTitle": "编辑订单",
  "cellOrderEditScreenTrackNumber": "运单号",
  "cellOrderViewScreenActionButtonCancelOrder": "取消订单",
  "cellOrderViewScreenActionButtonEdit": "编辑",
  "cellOrderViewScreenActionButtonView": "查看",
  "cellOrderViewScreenActionCancelOrderConfirmMessage": "你确定要取消此订单吗？",
  "cellOrderViewScreenActionCancelOrderErrorMessage": "取消订单失败",
  "cellOrderViewScreenActionCancelOrderSuccessMessage": "取消订单成功",
  "cellOrderViewScreenButtonPrintReplenishment": "打印待送货订单",
  "cellOrderViewScreenTableCreatedAt": "建立日期",
  "cellOrderViewScreenTableExpiredAt": "取货过期时间",
  "cellOrderViewScreenTableHumanId": "订单号",
  "cellOrderViewScreenTableLockerCell": "自提柜格子",
  "cellOrderViewScreenTableMerchantName": "商户",
  "cellOrderViewScreenTablePickedUpAt": "取货时间",
  "cellOrderViewScreenTableRecipientExpiryHour": "取货期限 (小时)",
  "cellOrderViewScreenTableRecipientPhone": "收货人电话",
  "cellOrderViewScreenTableRemark": "备注",
  "cellOrderViewScreenTableReplenishedAt": "送达时间",
  "cellOrderViewScreenTableState": "状态",
  "cellOrderViewScreenTableStateCanceled": "已取消",
  "cellOrderViewScreenTableStatePickedUp": "已取货",
  "cellOrderViewScreenTableStateRecalled": "已回收",
  "cellOrderViewScreenTableStateWaitPickup": "等待取货",
  "cellOrderViewScreenTableStateWaitPickupExpired": "等待取货 (已过期)",
  "cellOrderViewScreenTableStateWaitReplenishment": "等待送货",
  "cellOrderViewScreenTableTrackNumber": "运单号",
  "cellOrderViewScreenTitle": "订单列表",

  "errorMessageRequiredInputAny": "请输入%{name}",
  "errorMessageRequiredSelectAny": "请选择%{name}",
  "errorMessageUnknownError": "未知错误, 请稍后重试",

  "homeScreenAdminTitleBalance": "当前结余",
  "homeScreenAdminTitleDayContractCount": "日租合约数目",
  "homeScreenAdminTitleDayContractCountTooltip": "当前有效的日租合约",
  "homeScreenAdminTitleDayContractUsagePercent": "日租使用率",
  "homeScreenAdminTitleDayContractUsagePercentTooltip": "当前日租格子的使用率",
  "homeScreenAdminTitleMerchantDisplayName": "您的商户",
  "homeScreenAdminTitleMonthContractCount": "月租合约数目",
  "homeScreenAdminTitleMonthContractCountTooltip": "当前有效的月租合约",
  "homeScreenAdminTitleMonthContractUsagePercent": "月租使用率",
  "homeScreenAdminTitleMonthContractUsagePercentTooltip": "当前月租格子的使用率",
  "homeScreenAdminTitleTodayExpiredCount": "已过期订单数目",
  "homeScreenAdminTitleTodayExpiredCountButtonView": "查看过期订单",
  "homeScreenAdminTitleTodayExpiredCountTooltip": "当前待取货而已过取时间的订单数目",
  "homeScreenAdminTitleTodayOrder": "今天的订单",
  "homeScreenAdminTitleTodayOrderCompletedCount": "已完成订单数目",
  "homeScreenAdminTitleTodayOrderCompletedCountTooltip": "今天已取货的订单总数",
  "homeScreenAdminTitleTodayOrderCount": "订单数目",
  "homeScreenAdminTitleTodayOrderCountTooltip": "今天的订单总数",
  "homeScreenAdminTitleTodayOrderDeliveredCount": "已运送订单数目",
  "homeScreenAdminTitleTodayOrderDeliveredCountTooltip": "今天已运送的订单总数",
  "homeScreenMerchantMessage": "商户消息",
  "homeScreenMessage": "消息",
  "homeScreenMessageMore": "更多",
  "homeScreenSystemMessage": "系统消息",

  "lockerAddScreenAddSuccessMessage": "添加自提柜成功",
  "lockerAddScreenButtonSubmit": "提交",
  "lockerAddScreenCellLayoutPlaceholder": "未设置",
  "lockerAddScreenFormAddress": "地址",
  "lockerAddScreenFormCellLayout": "格子排列",
  "lockerAddScreenFormCode": "编号",
  "lockerAddScreenFormCoordinate": "座标",
  "lockerAddScreenFormCoordinateLatPlaceholder": "纬度",
  "lockerAddScreenFormCoordinateLngPlaceholder": "经度",
  "lockerAddScreenFormEnabled": "启用",
  "lockerAddScreenFormLockerlyLockerId": "Lockerly ID",
  "lockerAddScreenFormName": "名称",
  "lockerAddScreenFormRegion": "地区",
  "lockerAddScreenFormServiceHour": "服务时间",
  "lockerAddScreenFormVisible": "可见",
  "lockerAddScreenTitle": "添加自提柜",

  "lockerCellContractAddModalAddSuccessMessage": "建立日租合约成功",
  "lockerCellContractAddModalButtonCancel": "取消",
  "lockerCellContractAddModalButtonConfirm": "确定",
  "lockerCellContractAddModalContractDates": "合约有效时间",
  "lockerCellContractAddModalHourUnit": "小时",
  "lockerCellContractAddModalNoOfDay": "租用日数",
  "lockerCellContractAddModalSubmissionTime": "提交时间",
  "lockerCellContractAddModalTitle": "建立日租合约",
  "lockerCellLayoutButtonCancel": "取消",
  "lockerCellLayoutButtonConfirm": "确定",
  "lockerCellLayoutModalBatchEditInstruction": "每种小区的一行 (名称); 一个新行的新行; ---- 为新的一面",
  "lockerCellLayoutModalBatchEditTitle": "批量编辑格子",
  "lockerCellLayoutModalButtonBatchEdit": "批量编辑",
  "lockerCellLayoutModalEditCellName": "格子名称",
  "lockerCellLayoutModalEditCellTitle": "编辑格子",
  "lockerCellLayoutModalNoOfCol": "格子数目",
  "lockerCellLayoutModalNoOfRow": "层数目",
  "lockerCellLayoutModalNoOfSide": "柜子数目",
  "lockerCellLayoutModalTitle": "编辑格子排列",
  "lockerCellOrderAddModalAddSuccessMessage": "添加订单成功",
  "lockerCellOrderAddModalButtonSubmit": "提交",
  "lockerCellOrderAddModalConfirmContent": "请再次确认运单号和收货人电话是否正确",
  "lockerCellOrderAddModalConfirmContentRecipientPhone": "收货人电话: %{recipientPhone}",
  "lockerCellOrderAddModalConfirmContentTrackNumber": "运单号: %{trackNumber}",
  "lockerCellOrderAddModalConfirmTitle": "确认文件",
  "lockerCellOrderAddModalLockerCell": "自提柜格子",
  "lockerCellOrderAddModalMerchantRemark": "商户备注",
  "lockerCellOrderAddModalRecipientExpiryHour": "取货期限 (小时)",
  "lockerCellOrderAddModalRecipientPhone": "收货人电话",
  "lockerCellOrderAddModalRecipientPhoneInvalidMessage": "请输入正确格式的电话号码",
  "lockerCellOrderAddModalRecipientPhoneReminderMessage": "请确保运单号和收货人电话正确, 填写错误会导致不能送货或客人收不到取货短讯",
  "lockerCellOrderAddModalTitle": "添加订单",
  "lockerCellOrderAddModalTrackNumber": "运单号",

  "lockerDetailCellOrderForceModalSmsAlertMessage": "如选择不经短讯通知, 您需另外通知收货人",
  "lockerDetailCellOrderForceModalSmsLabel": "发短讯通知收货人",
  "lockerDetailScreenActionButtonCancelContract": "取消合约",
  "lockerDetailScreenActionButtonCancelOrder": "取消订单",
  "lockerDetailScreenActionButtonEditOrder": "编辑订单",
  "lockerDetailScreenActionButtonForcePickupOrder": "更新为已取货",
  "lockerDetailScreenActionButtonForceRecallOrder": "更新为已回收",
  "lockerDetailScreenActionButtonForceReplenishOrder": "更改为已送货",
  "lockerDetailScreenActionCancelContractConfirmMessage": "你确定要取消此合约吗？",
  "lockerDetailScreenActionCancelContractErrorMessage": "取消合约失败",
  "lockerDetailScreenActionCancelContractNoWaitReplenishmentOrder": "有待送货的订单的合约才可以取消",
  "lockerDetailScreenActionCancelContractSuccessMessage": "取消合约成功",
  "lockerDetailScreenActionCancelOrderConfirmMessage": "你确定要取消此订单吗？",
  "lockerDetailScreenActionCancelOrderErrorMessage": "取消订单失败",
  "lockerDetailScreenActionCancelOrderSuccessMessage": "取消订单成功",
  "lockerDetailScreenActionForcePickupOrderConfirmMessage": "确定要强制更新为已取货?",
  "lockerDetailScreenActionForcePickupOrderErrorMessage": "强制更新为已取货失败",
  "lockerDetailScreenActionForcePickupOrderSuccessMessage": "强制更新为已取货成功",
  "lockerDetailScreenActionForceRecallOrderConfirmTitle": "确定要强制更新为已回收?",
  "lockerDetailScreenActionForceRecallOrderConfirmWithSmsMessage": "确定要强制更新为已回收? (发短讯通知)",
  "lockerDetailScreenActionForceRecallOrderConfirmWithoutSmsMessage": "确定要强制更新为已回收? (不会发短讯通知)",
  "lockerDetailScreenActionForceRecallOrderErrorMessage": "强制更新为已回收失败",
  "lockerDetailScreenActionForceRecallOrderSuccessMessage": "强制更新为已回收成功",
  "lockerDetailScreenActionForceReplenishOrderConfirmTitle": "确定要强制更新为已送货?",
  "lockerDetailScreenActionForceReplenishOrderConfirmWithSmsMessage": "确定要强制更新为已送货? (发短讯通知)",
  "lockerDetailScreenActionForceReplenishOrderConfirmWithoutSmsMessage": "确定要强制更新为已送货? (不会发短讯通知)",
  "lockerDetailScreenActionForceReplenishOrderErrorMessage": "强制更新为已送货失败",
  "lockerDetailScreenActionForceReplenishOrderSuccessMessage": "强制更新为已送货成功",
  "lockerDetailScreenAdminButtonForceOpenSlot": "强制打开格子",
  "lockerDetailScreenAdminButtonForceOpenSlotErrorMessageSystemError": "请求失败, 请稍后重试",
  "lockerDetailScreenAdminButtonForceOpenSlotModalTitle": "确定要强制打开格子?",
  "lockerDetailScreenAdminButtonForceOpenSlotSuccessMessage": "成功发出打开指令",
  "lockerDetailScreenAdminButtonForceOpenSlotTooltip": "不理会大门有没有打开, 直接打开格子",
  "lockerDetailScreenAdminButtonOpenSlot": "解锁打开格子",
  "lockerDetailScreenAdminButtonOpenSlotErrorMessageSystemError": "请求失败, 请稍后重试",
  "lockerDetailScreenAdminButtonOpenSlotModalTitle": "确定要解锁打开格子?",
  "lockerDetailScreenAdminButtonOpenSlotSuccessMessage": "成功发出解锁指令",
  "lockerDetailScreenAdminButtonOpenSlotTooltip": "解锁大门, 等待拉开门后自动打开格子",
  "lockerDetailScreenAdminButtonReboot": "立即重启机器",
  "lockerDetailScreenAdminButtonRebootErrorMessageSystemError": "请求失败, 请稍后重试",
  "lockerDetailScreenAdminButtonRebootModalTitle": "确定要立即重启机器?",
  "lockerDetailScreenAdminButtonRebootSuccessMessage": "成功发出重启机器指令",
  "lockerDetailScreenAdminTitle": "管理员操作",
  "lockerDetailScreenCellContractCreatedAt": "建立日期",
  "lockerDetailScreenCellContractEmpty": "暂时没有合约",
  "lockerDetailScreenCellContractEndAt": "结束日期",
  "lockerDetailScreenCellContractHumanId": "合约号",
  "lockerDetailScreenCellContractMerchantName": "商户",
  "lockerDetailScreenCellContractPrice": "价钱",
  "lockerDetailScreenCellContractStartAt": "开始日期",
  "lockerDetailScreenCellContractTitle": "当前生效合约",
  "lockerDetailScreenCellContractType": "类别",
  "lockerDetailScreenCellContractTypeDay": "日租合约",
  "lockerDetailScreenCellContractTypeMonth": "月租合约",
  "lockerDetailScreenCellOrderCreatedAt": "建立日期",
  "lockerDetailScreenCellOrderEmpty": "暂时没有订单",
  "lockerDetailScreenCellOrderExpiredAt": "取货过期时间",
  "lockerDetailScreenCellOrderHumanId": "订单号",
  "lockerDetailScreenCellOrderLockerName": "自提柜",
  "lockerDetailScreenCellOrderMerchantCellName": "格子",
  "lockerDetailScreenCellOrderMerchantLockerCellName": "自提柜格子",
  "lockerDetailScreenCellOrderMerchantName": "商户",
  "lockerDetailScreenCellOrderMerchantRemark": "商家备注",
  "lockerDetailScreenCellOrderQrCodeTitleDeliver": "送货二维码",
  "lockerDetailScreenCellOrderQrCodeTitleDeliverShort": "送货",
  "lockerDetailScreenCellOrderQrCodeTitlePickup": "取货二维码",
  "lockerDetailScreenCellOrderQrCodeTitleRecall": "回收二维码",
  "lockerDetailScreenCellOrderQrCodeTitleRecallShort": "回收",
  "lockerDetailScreenCellOrderRecipientExpiryHour": "取货期限 (小时)",
  "lockerDetailScreenCellOrderRecipientPhone": "收货人电话",
  "lockerDetailScreenCellOrderShortUrl": "客户订单连结",
  "lockerDetailScreenCellOrderState": "状态",
  "lockerDetailScreenCellOrderStateCanceled": "取消订单",
  "lockerDetailScreenCellOrderStatePickedUp": "已取货",
  "lockerDetailScreenCellOrderStateRecalled": "已回收",
  "lockerDetailScreenCellOrderStateWaitPickup": "等待取货",
  "lockerDetailScreenCellOrderStateWaitReplenishment": "等待送货",
  "lockerDetailScreenCellOrderTabNew": "当前",
  "lockerDetailScreenCellOrderTitle": "当前有效订单",
  "lockerDetailScreenCellOrderTrackNumber": "运单号",
  "lockerDetailScreenCellPrice": "价钱 (日租)",
  "lockerDetailScreenCellSize": "尺寸 (mm)",
  "lockerDetailScreenCellStatusCreatedAt": "订单建立于",
  "lockerDetailScreenCellStatusCreatedAtTitle": "建立订单",
  "lockerDetailScreenCellStatusDeliveredAt": "送达于",
  "lockerDetailScreenCellStatusDeliveredAtEmpty": "等待送货中",
  "lockerDetailScreenCellStatusDeliveredAtTitle": "货物送达",
  "lockerDetailScreenCellStatusPickupAt": "取货于",
  "lockerDetailScreenCellStatusPickupAtEmpty": "等待取货中",
  "lockerDetailScreenCellStatusPickupAtTitle": "取货",
  "lockerDetailScreenCellStatusTitle": "物流状态",
  "lockerDetailScreenLockerAddress": "地址",
  "lockerDetailScreenLockerAlertCanOrder": "您有生效合约, 可以使用",
  "lockerDetailScreenLockerAlertCanOrderSystemAdmin": "商户有生效合约, 可以使用",
  "lockerDetailScreenLockerAlertCanRent": "目前可以租用",
  "lockerDetailScreenLockerAlertHaveOrder": "您已有订单",
  "lockerDetailScreenLockerAlertHaveOrderSystemAdmin": "商户已有订单",
  "lockerDetailScreenLockerAlertOccupied": "已有另一个商户使用中",
  "lockerDetailScreenLockerButtonDayRental": "要求日租",
  "lockerDetailScreenLockerButtonOrder": "添加订单",
  "lockerDetailScreenLockerCellError": "错误",
  "lockerDetailScreenLockerLatestTemperature": "最新温度",
  "lockerDetailScreenLockerNoLatestTemperature": "过去三十分钟无温度数据",
  "lockerDetailScreenLockerNoLatestTemperatureLabel": "未知",
  "lockerDetailScreenLockerNoOfCell": "格子数目",
  "lockerDetailScreenLockerSelectCellLabel": "请选择格子",
  "lockerDetailScreenLockerServiceHour": "服务时间",
  "lockerDetailScreenLockerStatus": "状态",
  "lockerDetailScreenLockerStatusRunning": "运作中",
  "lockerDetailScreenLockerStatusUnknown": "未知",
  "lockerDetailScreenTitle": "自提柜详情",

  "lockerEditScreenEditSuccessMessage": "修改自提柜成功",
  "lockerEditScreenTitle": "编辑自提柜",

  "lockerHistoricalTemperatureModalButtonDownload": "下载 xlsx",
  "lockerHistoricalTemperatureModalTimespan168Label": "过去 7 天",
  "lockerHistoricalTemperatureModalTimespan48Label": "过去 48 小时",
  "lockerHistoricalTemperatureModalTitle": "自提柜历史温度",

  "lockerViewScreenActionButtonCopy": "复制此柜",
  "lockerViewScreenActionButtonEdit": "编辑",
  "lockerViewScreenActionButtonEditCells": "编辑格子",
  "lockerViewScreenActionButtonRemove": "删除",
  "lockerViewScreenActionButtonView": "查看",
  "lockerViewScreenActionRemoveConfirmMessage": "您确定要删除此自提柜?",
  "lockerViewScreenActionRemoveErrorMessage": "删除自提柜失败",
  "lockerViewScreenActionRemoveSuccessMessage": "删除自提柜成功",
  "lockerViewScreenButtonAdd": "添加自提柜",
  "lockerViewScreenCopySuccessMessage": "自提柜成功复制",
  "lockerViewScreenTableAddress": "地址",
  "lockerViewScreenTableClickToViewHistoricalTemperature": "点击查看历史温度",
  "lockerViewScreenTableCode": "编号",
  "lockerViewScreenTableIsEnabled": "启用",
  "lockerViewScreenTableLatestTemperature": "最新温度",
  "lockerViewScreenTableName": "名称",
  "lockerViewScreenTableNoTemperature": "过去三十分钟无温度数据",
  "lockerViewScreenTableOffline": "离线",
  "lockerViewScreenTableOnline": "在线",
  "lockerViewScreenTableRegion": "地区",
  "lockerViewScreenTableSearchOnlyHasCellContract": "只显示租用中",
  "lockerViewScreenTableSearchRegionAny": "任何地区",
  "lockerViewScreenTableServiceHour": "服务时间",
  "lockerViewScreenTableState": "状态",
  "lockerViewScreenTableStatus": "状态",
  "lockerViewScreenTableUnknown": "未知",
  "lockerViewScreenTitle": "自提柜列表",

  "loginScreenFormLoginButton": "登入",
  "loginScreenFormMerchantName": "商户",
  "loginScreenFormPassword": "密码",
  "loginScreenFormUserName": "用户",
  "loginScreenLoginFailedMessageIncorrectPassword": "密码错误",
  "loginScreenLoginFailedMessageUserNotFound": "找不到此用户",
  "loginScreenLoginSuccessMessage": "登入成功",

  "merchantAddScreenAddSuccessMessage": "添加商户成功",
  "merchantAddScreenButtonSubmit": "提交",
  "merchantAddScreenFormContactPhone": "联络电话",
  "merchantAddScreenFormDisplayName": "显示名称",
  "merchantAddScreenFormLocale": "语言",
  "merchantAddScreenFormLocaleEn": "英文",
  "merchantAddScreenFormLocaleZhCn": "简体中文",
  "merchantAddScreenFormLocaleZhTw": "繁体中文",
  "merchantAddScreenFormName": "登入名称",
  "merchantAddScreenTitle": "添加商户",
  "merchantAddTransactionModalAmount": "金额",
  "merchantAddTransactionModalMerchantName": "商户",
  "merchantAddTransactionModalRemark": "备注",
  "merchantAddTransactionModalSubmit": "提交",
  "merchantAddTransactionModalTitle": "添加交易记录",
  "merchantAddTransactionModalType": "类别",
  "merchantAddTransactionModalTypeCredit": "积分/赠送",
  "merchantAddTransactionModalTypeDayRental": "日租",
  "merchantAddTransactionModalTypeMonthRental": "月租",
  "merchantAddTransactionModalTypeRefund": "退款",
  "merchantAddTransactionModalTypeTransfer": "银行过帐",

  "merchantBillDetailScreenBillCellContractPanelHeaderDayType": "%{lockerName} / %{cellName} (费用: $%{price}) (合约期: %{duration}小时)",
  "merchantBillDetailScreenBillCellContractPanelHeaderMonthType": "%{lockerName} / %{cellName} (费用: $%{price})",
  "merchantBillDetailScreenCreatedAt": "建立日期",
  "merchantBillDetailScreenDecscription": "描述",
  "merchantBillDetailScreenMerchantName": "商户",
  "merchantBillDetailScreenPrice": "金额",
  "merchantBillDetailScreenReceiptNo": "帐单编号",
  "merchantBillDetailScreenRemark": "备注",
  "merchantBillDetailScreenTitle": "帐单详情",
  "merchantBillDetailScreenTypeDay": "日租",
  "merchantBillDetailScreenTypeMonth": "月租",
  "merchantBillViewScreenActionButtonView": "查看",
  "merchantBillViewScreenDescription": "描述",
  "merchantBillViewScreenTableCreatedAt": "建立日期",
  "merchantBillViewScreenTableMerchantName": "商户",
  "merchantBillViewScreenTablePrice": "金额",
  "merchantBillViewScreenTableReceiptNo": "帐单编号",
  "merchantBillViewScreenTableRemark": "备注",
  "merchantBillViewScreenTableType": "类别",
  "merchantBillViewScreenTitle": "帐单列表",
  "merchantBillViewScreenTypeDay": "日租帐单",
  "merchantBillViewScreenTypeMonth": "月租帐单",

  "merchantDetailScreenBalance": "当前结余",
  "merchantDetailScreenCreatedAt": "建立日期",
  "merchantDetailScreenDisplayLocale": "语言",
  "merchantDetailScreenDisplayName": "显示名称",
  "merchantDetailScreenMerchantName": "商户",
  "merchantDetailScreenTitle": "商户详情",

  "merchantEditScreenEditSuccessMessage": "修改商户成功",
  "merchantEditScreenTitle": "编辑商户",

  "merchantTransactionViewScreenTableAmount": "金额",
  "merchantTransactionViewScreenTableCreatedAt": "建立日期",
  "merchantTransactionViewScreenTableMerchantName": "商户",
  "merchantTransactionViewScreenTableRemark": "备注",
  "merchantTransactionViewScreenTableType": "类别",
  "merchantTransactionViewScreenTableTypeCredit": "积分/赠送",
  "merchantTransactionViewScreenTableTypeDayRental": "日租",
  "merchantTransactionViewScreenTableTypeMonthRental": "月租",
  "merchantTransactionViewScreenTableTypeRefund": "退款",
  "merchantTransactionViewScreenTableTypeTransfer": "银行过帐",
  "merchantTransactionViewScreenTitle": "交易列表",

  "merchantViewScreenActionButtonAddTransaction": "添加交易记录",
  "merchantViewScreenActionButtonEdit": "编辑",
  "merchantViewScreenActionButtonRemove": "删除",
  "merchantViewScreenActionButtonView": "查看",
  "merchantViewScreenActionRemoveConfirm": "您确定要删除此商户?",
  "merchantViewScreenActionRemoveErrorMessage": "删除商户失败",
  "merchantViewScreenActionRemoveSuccessMessage": "删除商户成功",
  "merchantViewScreenButtonAdd": "添加商户",
  "merchantViewScreenCreatedAt": "建立日期",
  "merchantViewScreenIsLocked": "锁定",
  "merchantViewScreenTableBalance": "结余",
  "merchantViewScreenTableContactPhone": "联络电话",
  "merchantViewScreenTableDisplayName": "显示名称",
  "merchantViewScreenTableName": "名称",
  "merchantViewScreenTitle": "商户列表",

  "regionAddScreenAddSuccessMessage": "添加地区成功",
  "regionAddScreenButtonSubmit": "提交",
  "regionAddScreenFormName": "名称",
  "regionAddScreenTitle": "添加地区",

  "regionEditScreenEditSuccessMessage": "修改地区成功",
  "regionEditScreenTitle": "编辑地区",

  "regionViewScreenActionButtonEdit": "编辑",
  "regionViewScreenActionButtonRemove": "删除",
  "regionViewScreenActionRemoveConfirmMessage": "您确定要删除此区域?",
  "regionViewScreenActionRemoveErrorMessage": "删除地区失败",
  "regionViewScreenActionRemoveSuccessMessage": "删除地区成功",
  "regionViewScreenButtonAdd": "添加地区",
  "regionViewScreenTableName": "名称",
  "regionViewScreenTitle": "地区列表",

  "tablePaginationTotal": "总共 %{total} 个记录",

  "tableSearchBillTypeAny": "任何类别",
  "tableSearchBillTypeDay": "日租帐单",
  "tableSearchBillTypeMonth": "月租帐单",
  "tableSearchContractTypeAny": "任何类别",
  "tableSearchContractTypeDay": "日租",
  "tableSearchContractTypeMonth": "月租",
  "tableSearchLockerAny": "任何自提柜",
  "tableSearchMerchantAny": "任何商户",
  "tableSearchMerchantTransactionTypeCredit": "积分/赠送",
  "tableSearchMerchantTransactionTypeDayRental": "日租",
  "tableSearchMerchantTransactionTypeMonthRental": "月租",
  "tableSearchMerchantTransactionTypeRefund": "退款",
  "tableSearchMerchantTransactionTypeTransfer": "银行过帐",
  "tableSearchOrderStateAny": "任何状态",
  "tableSearchOrderStateCanceled": "已取消",
  "tableSearchOrderStatePickedUp": "已取货",
  "tableSearchOrderStateRecalled": "已回收",
  "tableSearchOrderStateWaitPickup": "等待取货",
  "tableSearchOrderStateWaitReplenishment": "等待送货",
  "tableSearchOrderStateWatiPickupExpired": "待取货并过期",
  "tableSearchOrderTypeAny": "任何类别",
  "tableSearchOrderTypeDay": "日租",
  "tableSearchOrderTypeMonth": "月租",
  "tableSearchPlaceholder": "搜索...",
  "tableSearchTransactionTypeAny": "任何类别",

  "userAddScreenAddSuccessMessage": "添加用户成功",
  "userAddScreenButtonSubmit": "提交",
  "userAddScreenFormDisplayLocale": "语言",
  "userAddScreenFormDisplayName": "显示名称",
  "userAddScreenFormEmail": "电邮",
  "userAddScreenFormLocaleEn": "英文",
  "userAddScreenFormLocaleZhCn": "简体中文",
  "userAddScreenFormLocaleZhTw": "繁体中文",
  "userAddScreenFormMerchant": "商户",
  "userAddScreenFormName": "登入名称",
  "userAddScreenFormPassword": "密码",
  "userAddScreenFormPhone": "电话",
  "userAddScreenFormRoles": "角色",
  "userAddScreenTitle": "添加用户",

  "userEditScreenEditSuccessMessage": "修改用户成功",
  "userEditScreenTitle": "编辑用户",

  "userViewScreenActionButtonChangePassword": "修改密码",
  "userViewScreenActionButtonEdit": "编辑",
  "userViewScreenActionButtonRemove": "删除",
  "userViewScreenActionRemoveConfirm": "您确定要删除此用户?",
  "userViewScreenActionRemoveErrorMessage": "删除用户失败",
  "userViewScreenActionRemoveSuccessMessage": "删除用户成功",
  "userViewScreenButtonAdd": "添加用户",
  "userViewScreenLocaleZhCn": "简体中文",
  "userViewScreenLocaleZhEn": "英语句子",
  "userViewScreenLocaleZhTw": "繁体中文",
  "userViewScreenTableDisplayName": "显示名称",
  "userViewScreenTableEmail": "电邮",
  "userViewScreenTableLocale": "语言",
  "userViewScreenTableName": "登入名称",
  "userViewScreenTablePhone": "电话",
  "userViewScreenTableRoles": "角色",
  "userViewScreenTitle": "用户列表"
}