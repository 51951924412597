import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import {Form, Input, Button, Typography, Spin, message, PageHeader} from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';
import {useSelector} from "react-redux";

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;

export default function RegionAddScreen(props) {
  const history = useHistory();
  const metadata = useSelector(state => state.metadata.value);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      let name = {};
      for (let locale of metadata.supportedLocales) {
        name[locale] = values[`name[${locale}]`];
      }
      const data = {
        name
      };
      const res = await ToolRequest.request('POST', '/v1/region', data);

      message.success(I18n.t('regionAddScreenAddSuccessMessage'));

      history.push('/region');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('regionAddScreenTitle')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={{
            locale: ['zh-tw'],
          }}
          onFinish={onFinish}
        >
          {metadata.supportedLocales.map(locale => {
            return (
              <Form.Item
                key={`name[${locale}]`}
                label={`${I18n.t('regionAddScreenFormName')} (${locale})`}
                name={`name[${locale}]`}
                rules={[
                  {
                    required: true,
                    message: I18n.t('errorMessageRequiredInputAny', {
                      name: `${I18n.t('regionAddScreenFormName')} (${locale})`,
                    }),
                  },
                ]}
              >
                <Input
                  placeholder={`${I18n.t('regionAddScreenFormName')} (${locale})`}
                />
              </Form.Item>
            );
          })}

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {I18n.t('regionAddScreenButtonSubmit')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
