import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';

import { PageHeader, Button, Typography, Descriptions, Spin, Card, Badge, Row, Col, Tooltip, Tag, Alert, Steps, Collapse, message } from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title, Text } = Typography;

export default function CellContractDetailScreen(props) {
  const history = useHistory();
  const { cellContractId } = useParams();

  const userData = useSelector(state => state.user.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const [loaded, setLoaded] = useState(false);
  const [contract, setContract] = useState(null);
  const [lockerCell, setLockerCell] = useState(null);
  const [locker, setLocker] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [statusBadge, setStatusBadge] = useState(null);

  useEffect(() => {
    (async () => {
      const contractRes = await ToolRequest.request('GET', `/v1/cell-contract/${cellContractId}`);
      console.dir(contractRes)
      setContract(contractRes);
      let statusBadge = '';
      const now = new Date().toISOString();
      if (now < contractRes.contractStartAt) {
        statusBadge =  <Badge status="processing" text={I18n.t('cellContractDetailScreenStatusPending')} />;
      } else if (now >= contractRes.contractStartAt && now < contractRes.contractEndAt) {
        statusBadge = <Badge status="success" text={I18n.t('cellContractDetailScreenStatusActive')} />;
      } else {
        statusBadge = <Badge status="default" text={I18n.t('cellContractDetailScreenStatusEnded')} />;
      }
      setStatusBadge(statusBadge);
      const lockerCellRes = await ToolRequest.request('GET', `/v1/cell/${contractRes.cellId}`);
      setLockerCell(lockerCellRes);
      const lockerRes = await ToolRequest.request('GET', `/v1/locker/${lockerCellRes.lockerId}`);
      setLocker(lockerRes);
      const merchantRes = await ToolRequest.request('GET', `/v1/merchant/${contractRes.merchantId}`);
      setMerchant({
        ...merchantRes,
        locale: [merchantRes.locale],
      });
      setLoaded(true);
    })();
  }, []);

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('cellContractDetailScreenTitle')}
    >
      <Card>
        <Title level={5}></Title>
        <Row gutter={16}>
          <Col span={12}>
            <Descriptions
              size="small"
              column={1}
            >
              <Descriptions.Item label={I18n.t('cellContractDetailScreenHumanId')}>
                {contract.humanId}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('cellContractDetailScreenMerchantName')}>
                {merchant.displayName}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('cellContractDetailScreenLockerName')}>
                {locker.name[userData.locale]}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('cellContractDetailScreenCellName')}>
                {lockerCell.name}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('cellContractDetailScreenType')}>
                {{
                  'MONTH_RENTAL': I18n.t('cellContractDetailScreenMonthRental'),
                  'DAY_RENTAL': I18n.t('cellContractDetailScreenTypeDayRental'),
                }[contract.type]}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('cellContractDetailScreenStatus')}>
                {statusBadge}
              </Descriptions.Item>
              {ToolPermission.hasPermission(userPermissionsData, 'cell-contract', 'get:more') && (
                <>
                  <Descriptions.Item label={I18n.t('cellContractDetailScreenCellPrice')}>
                    {'$'+(lockerCell.price / 1000000).toFixed(1)}
                  </Descriptions.Item>
                  <Descriptions.Item label={I18n.t('cellContractDetailScreenTotalPrice')}>
                    {'$'+(contract.price / 1000000).toFixed(1)}
                  </Descriptions.Item>
                </>
              )}
              <Descriptions.Item label={I18n.t('cellContractDetailScreenStartAt')}>
                {moment(contract.contractStartAt).format('YYYY-MM-DD HH:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('cellContractDetailScreenEndAt')}>
                {moment(contract.contractEndAt).format('YYYY-MM-DD HH:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('cellContractDetailScreenRemark')}>
                {contract.remark}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('cellContractDetailScreenCreatedAt')}>
                {moment(contract.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>
    </PageHeader>
  ) : <SpinFull />;
};
