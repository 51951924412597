import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, Divider, DatePicker, InputNumber, message } from 'antd';
import { ArrowLeftOutlined, EditOutlined, SwapRightOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';
import CellContractCellPriceSelect from '../../components/CellContractCellPriceSelect';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

export default function CellContractAddScreen(props) {
  const history = useHistory();
  const userData = useSelector(state => state.user.value);

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [merchants, setMerchants] = useState(null);

  const [selectedType, setSelectedType] = useState(null);
  const [selectedContractCount, setSelectedContractCount] = useState(1);
  const [estimatedContractStartAt, setEstimatedContractStartAt] = useState(moment());

  const getHour = (day) => {
    return Math.floor(day) * 24 + 8;
  };

  useEffect(() => {
    (async () => {
      try {
        const merchantRes = await ToolRequest.request('GET', '/v1/merchant', {
          count: 1000,
        });
        setMerchants(merchantRes.data);
      } catch (err) {
      }

      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setEstimatedContractStartAt(moment());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    console.log(values);

    try {
      // TODO check if cellIds is empty

      // contract dates
      let contractMonthStartAt;
      let contractCount;

      if (values.type[0] === 'DAY_RENTAL') {
        contractCount = Math.floor(selectedContractCount);
      } else if (values.type[0] === 'MONTH_RENTAL') {
        contractMonthStartAt = moment(values.contractDates[0]).format('YYYYMM');
        contractCount = values.contractDates[1].diff(values.contractDates[0], 'month') + 1;
      }

      let successCount = 0;

      for (let lockerCell of values.lockerCells) {
        try {
          const data = {
            merchantId: values.merchantId[0],
            cellId: lockerCell.cellId,
            type: values.type[0],
            contractCount,
            remark: values.remark,
          };

          if (values.type[0] === 'DAY_RENTAL') {
          } else if (values.type[0] === 'MONTH_RENTAL') {
            data.contractMonthStartAt = contractMonthStartAt;
            data.price = lockerCell.price;
          }

          const res = await ToolRequest.request('POST', '/v1/cell-contract', data);

          ++successCount;
        } catch (err2) {
        }
      }

      if (!successCount) {
        throw new Error();
      }

      message.success(I18n.t('cellContractAddScreenAddSuccessMessage', {
        count: successCount,
      }));

      history.push('/cell-contract');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  const estimatedContractEndAt = estimatedContractStartAt && selectedContractCount > 0
    ? moment(estimatedContractStartAt).add(getHour(selectedContractCount), 'hour')
    : null;

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('cellContractAddScreenTitle')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={{
            selectedContractCount,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={I18n.t('cellContractAddScreenFormMerchant')}
            name="merchantId"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('cellContractAddScreenFormMerchant'),
                }),
              },
            ]}
          >
            <Cascader
              options={merchants.map(merchant => ({
                label: `${merchant.displayName} (${merchant.name})`,
                value: merchant.id,
              }))}
              placeholder={I18n.t('cellContractAddScreenFormMerchant')}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('cellContractAddScreenFormType')}
            name="type"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('cellContractAddScreenFormType'),
                }),
              },
            ]}
          >
            <Cascader
              options={[
                {
                  label: I18n.t('cellContractAddScreenFormTypeDay'),
                  value: 'DAY_RENTAL',
                },
                {
                  label: I18n.t('cellContractAddScreenFormTypeMonth'),
                  value: 'MONTH_RENTAL',
                },
              ]}
              placeholder={I18n.t('cellContractAddScreenFormType')}
              onChange={value => setSelectedType(value[0])}
              value={[selectedType]}
            />
          </Form.Item>
          {selectedType === 'DAY_RENTAL' ? (
            <>
              <Form.Item
                label={I18n.t('cellContractAddScreenFormNoOfDay')}
                name="selectedContractCount"
                rules={[
                  {
                    required: true,
                    message: I18n.t('errorMessageRequiredInputAny', {
                      name: I18n.t('cellContractAddScreenFormNoOfDay'),
                    }),
                  },
                ]}
              >
                <InputNumber
                  placeholder={I18n.t('cellContractAddScreenFormNoOfDay')}
                  min={1}
                  onChange={value => setSelectedContractCount(value)}
                  value={selectedContractCount}
                  addonAfter={`= ${getHour(selectedContractCount)} ${I18n.t('cellContractAddScreenFormHourUnit')}`}
                />
              </Form.Item>

              <Form.Item
                label={I18n.t('cellContractAddScreenContractDates')}
              >
                <Text type="success">{estimatedContractStartAt.format('YYYY-MM-DD HH:mm')} ({I18n.t('cellContractAddScreenFormSubmissionTime')})</Text>
                <SwapRightOutlined style={{marginLeft: 8, marginRight: 8, color: '#999'}} />
                <Text type="success">{estimatedContractEndAt?.format('YYYY-MM-DD HH:mm')}</Text>
              </Form.Item>
            </>
          ) : (selectedType === 'MONTH_RENTAL' ? (
            <>
              <Form.Item
                label={I18n.t('cellContractAddScreenFormContractMonths')}
                name="contractDates"
                rules={[
                  {
                    required: true,
                    message: I18n.t('errorMessageRequiredInputAny', {
                      name: I18n.t('cellContractAddScreenFormContractMonths'),
                    }),
                  },
                ]}
              >
                <RangePicker
                  picker="month"
                />
              </Form.Item>
            </>
          ) : null)}

          <Divider />

          <Form.Item
            label={I18n.t('cellContractAddScreenFormCells')}
            name="lockerCells"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('cellContractAddScreenFormCells'),
                }),
              },
            ]}
          >
            <CellContractCellPriceSelect />
          </Form.Item>

          <Divider />

          <Form.Item
            label={I18n.t('cellContractAddScreenFormRemark')}
            name="remark"
          >
            <TextArea
              placeholder={I18n.t('cellContractAddScreenFormRemark')}
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {I18n.t('cellContractAddScreenButtonSubmit')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
