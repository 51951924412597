import React from 'react';

import { Switch, Select, Cascader } from 'antd';

const { Option } = Select;

export default function SuperTableSearchColumn(props) {
  const {
    template,
    key,
    title = '',
    render,
    value,
    onChange = () => {},
  } = props;

  switch (template) {
    case 'switch': {
      return (
        <div className="search-form-switch-container">
          <Switch
            {...props}
            defaultChecked={props.defaultValue !== undefined ? props.defaultValue : null}
            checked={value}
            onChange={onChange}
          />
          <span>{title}</span>
        </div>
      );
    }

    case 'select': {
      const { options, allowClear = true } = props;

      return (
        <Select
          {...props}
          className="select"
          placeholder={title}
          onChange={onChange}
          allowClear={allowClear}
          style={{
            minWidth: 150,
            ...props?.style,
          }}
        >
          {options.map(option => (
            <Option key={option.value} value={option.value}>{option.label}</Option>
          ))}
        </Select>
      );
    }

    case 'cascader': {
      const { options } = props;

      return (
        <Cascader
          {...props}
          className="select"
          placeholder={title}
          onChange={onChange}
          options={options}
          style={{
            minWidth: 150,
            width: 'auto',
            ...props?.style,
          }}
        />
      );
    }

    default:
      return title;
  }

  return null;
};