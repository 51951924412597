import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {I18n, setLocale} from 'react-redux-i18n';

import { Modal, Form, Input, Button, Spin, Select, message } from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';
import SpinFull from '../../components/SpinFull';
import * as ToolUser from "../../tools/ToolUser";
import {updateUser} from "../../reducers/userSlice";

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { TextArea } = Input;
const { Option } = Select;

export default function UserSettingModal(props) {
  const { visible, onOk, onCancel } = props;
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.value);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {

      } catch (err) {
      }

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const data = {
        displayName:values.displayName,
        email:values.email || null,
        phone:values.phone || null,
        locale:values.locale
      };
      await ToolRequest.request('PUT', `/v1/user/${user.id}`, data);

      const userData = await ToolRequest.request('GET', '/v1/user/me');
      ToolUser.updateLocale(userData.locale);

      dispatch(setLocale(userData.locale));

      dispatch(updateUser(userData));

      message.success(I18n.t('userEditScreenEditSuccessMessage'));

      onOk();
      setLoading(false);
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return (
    <Modal
      title={I18n.t('appHeaderUserSettingModalTitle')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={user}
            onFinish={onFinish}
          >
            <Form.Item
              label={I18n.t('userAddScreenFormDisplayName')}
              name="displayName"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('userAddScreenFormDisplayName'),
                  }),
                },
              ]}
            >
              <Input
                placeholder={I18n.t('userAddScreenFormDisplayName')}
              />
            </Form.Item>

            <Form.Item
              label={I18n.t('userAddScreenFormDisplayLocale')}
              name="locale"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredSelectAny', {
                    name: I18n.t('userAddScreenFormDisplayLocale'),
                  }),
                },
              ]}
            >
              <Select placeholder={I18n.t('userAddScreenFormLocale')}>
                <Option value="en">
                  {I18n.t('userAddScreenFormLocaleEn')}
                </Option>
                <Option value="zh-tw">
                  {I18n.t('userAddScreenFormLocaleZhTw')}
                </Option>
                <Option value="zh-cn">
                  {I18n.t('userAddScreenFormLocaleZhCn')}
                </Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={I18n.t('userAddScreenFormEmail')}
              name="email"
            >
              <Input type="email" placeholder={I18n.t('userAddScreenFormEmail')} />
            </Form.Item>

            <Form.Item
              label={I18n.t('userAddScreenFormPhone')}
              name="phone"
            >
              <Input placeholder={I18n.t('userAddScreenFormPhone')} />
            </Form.Item>

            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('userAddScreenButtonSubmit')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull />}
    </Modal>
  );
};