export default {
  "appHeaderButtonChangePassword": "Change Password",
  "appHeaderButtonLogout": "Logout",
  "appHeaderButtonMerchantSetting": "Merchant Settings",
  "appHeaderButtonMyAccount": "My Account",
  "appHeaderButtonUserSetting": "My Settings",
  "appHeaderChangePasswordModalConfirmPassword": "Confirm Password",
  "appHeaderChangePasswordModalConfirmPasswordErrorMessage": "The two passwords that you entered do not match!",
  "appHeaderChangePasswordModalIncorrectPassword": "The old password is incorrect. Please re-enter it",
  "appHeaderChangePasswordModalNewPassword": "New Password",
  "appHeaderChangePasswordModalOldPassword": "Your Password",
  "appHeaderChangePasswordModalSubmit": "Submit",
  "appHeaderChangePasswordModalTitle": "Change Password",
  "appHeaderCurrentUser": "Logged in as",
  "appHeaderFirebaseStatusButtonErrorInfoMessage": "You can open the settings to allow push notifications near the browser URL bar",
  "appHeaderFirebaseStatusButtonErrorLabel": "Push Notification is OFF",
  "appHeaderFirebaseStatusButtonLoadingLabel": "Click to allow Push Notification",
  "appHeaderFirebaseStatusButtonSuccessLabel": "Push Notification is ON",
  "appHeaderGreetings": "Good Day!",
  "appHeaderMerchantMessageModalTableCreatedAt": "Date Created",
  "appHeaderMerchantMessageModalTableState": "State",
  "appHeaderMerchantMessageModalTableStateRead": "Read",
  "appHeaderMerchantMessageModalTableStateUnread": "Unread",
  "appHeaderMerchantMessageModalTableTitle": "Title",
  "appHeaderMerchantMessageModalTitle": "Merchant Message",
  "appHeaderMerchantSettingModalTitle": "Edit Merchant Settings",
  "appHeaderNotificationMessageNewMessage": "You have new messages",
  "appHeaderSystemMessageModalTableCreatedAt": "Date Created",
  "appHeaderSystemMessageModalTableState": "State",
  "appHeaderSystemMessageModalTableStateRead": "Read",
  "appHeaderSystemMessageModalTableStateUnread": "Unread",
  "appHeaderSystemMessageModalTableTitle": "Title",
  "appHeaderSystemMessageModalTitle": "System Message",
  "appHeaderUserSettingModalTitle": "Edit",

  "appMenuHome": "Home",
  "appMenuHomeContractList": "Contract List",
  "appMenuHomeMerchantList": "Merchant List",
  "appMenuHomeOrderList": "Order List",
  "appMenuLocker": "Locker",
  "appMenuLockerList": "Locker List",
  "appMenuMerchant": "Merchant",
  "appMenuMyBill": "My Bills",
  "appMenuMyContract": "My Contract",
  "appMenuMyOrder": "My Orders",
  "appMenuRegionList": "Region List",
  "appMenuTransactionList": "My Transactions",
  "appMenuUser": "User",

  "cellContractAddScreenAddSuccessMessage": "Added %{count} Contract(s) Successfully",
  "cellContractAddScreenButtonSubmit": "Submit",
  "cellContractAddScreenContractDates": "Contract Dates",
  "cellContractAddScreenFormCells": "Locker Cells",
  "cellContractAddScreenFormContractMonths": "Contract Months",
  "cellContractAddScreenFormHourUnit": "hours",
  "cellContractAddScreenFormMerchant": "Merchant",
  "cellContractAddScreenFormNoOfDay": "No. of Days",
  "cellContractAddScreenFormRemark": "Remarks",
  "cellContractAddScreenFormSubmissionTime": "Submission time",
  "cellContractAddScreenFormType": "Type",
  "cellContractAddScreenFormTypeDay": "Day Rental",
  "cellContractAddScreenFormTypeMonth": "Month Rent",
  "cellContractAddScreenTitle": "Add a Contract",
  "cellContractCellPriceSelectButtonAdd": "Select Locker Cell",
  "cellContractCellPriceSelectButtonBatchAdd": "Batch Select Locker Cell",
  "cellContractCellPriceSelectButtonRemove": "Remove",
  "cellContractCellPriceSelectModalButtonSubmit": "Submit",
  "cellContractCellPriceSelectModalFormLockerCell": "Locker Cell",
  "cellContractCellPriceSelectModalFormPrice": "Price",
  "cellContractCellPriceSelectModalFormPriceErrorInvalidMessage": "Please enter a valid price",
  "cellContractCellPriceSelectModalTitle": "Select a Locker Cell",
  "cellContractDetailScreenCellName": "Cell Name",
  "cellContractDetailScreenCellPrice": "Cell Price",
  "cellContractDetailScreenCreatedAt": "Date Created",
  "cellContractDetailScreenEndAt": "End Time",
  "cellContractDetailScreenHumanId": "Contract No.",
  "cellContractDetailScreenLockerName": "Locker Name",
  "cellContractDetailScreenMerchantName": "Merchant",
  "cellContractDetailScreenMonthRental": "Month Rental",
  "cellContractDetailScreenRemark": "Remarks",
  "cellContractDetailScreenStartAt": "Start Time",
  "cellContractDetailScreenStatus": "Status",
  "cellContractDetailScreenStatusActive": "Active",
  "cellContractDetailScreenStatusEnded": "Ended",
  "cellContractDetailScreenStatusPending": "Pending",
  "cellContractDetailScreenTitle": "Cell Contract Detail",
  "cellContractDetailScreenTotalPrice": "Total Price",
  "cellContractDetailScreenType": "Type",
  "cellContractDetailScreenTypeDayRental": "Day Rental",
  "cellContractEditScreenEditSuccessMessage": "Edited Contract Successfully",
  "cellContractEditScreenPrice": "Price",
  "cellContractEditScreenTitle": "Edit a Contract",
  "cellContractViewScreenActionButtonEdit": "Edit",
  "cellContractViewScreenActionButtonRemove": "Remove",
  "cellContractViewScreenActionButtonView": "View",
  "cellContractViewScreenActionRemoveConfirm": "Are you sure to remove this Contract?",
  "cellContractViewScreenActionRemoveErrorMessage": "Failed to remove Contract",
  "cellContractViewScreenActionRemoveSuccessMessage": "Removed Contract Successfully",
  "cellContractViewScreenButtonAdd": "Add Contract",
  "cellContractViewScreenTableCreatedAt": "Date Created",
  "cellContractViewScreenTableEndAt": "End Time",
  "cellContractViewScreenTableHumanId": "Contract No.",
  "cellContractViewScreenTableLockerCell": "Locker Cell",
  "cellContractViewScreenTableLockerCellInvalid": "Invalid Cell",
  "cellContractViewScreenTableMerchantName": "Merchant",
  "cellContractViewScreenTablePrice": "Price",
  "cellContractViewScreenTableRemark": "Remarks",
  "cellContractViewScreenTableSearchStatusAny": "Any Status",
  "cellContractViewScreenTableSearchStatusEnded": "Ended",
  "cellContractViewScreenTableSearchStatusOngoing": "Active",
  "cellContractViewScreenTableSearchStatusPending": "Pending",
  "cellContractViewScreenTableStartAt": "Start Time",
  "cellContractViewScreenTableStatus": "Status",
  "cellContractViewScreenTableStatusActive": "Active",
  "cellContractViewScreenTableStatusEnded": "Ended",
  "cellContractViewScreenTableStatusPending": "Pending",
  "cellContractViewScreenTableType": "Type",
  "cellContractViewScreenTableTypeDay": "Day Rental",
  "cellContractViewScreenTableTypeMonth": "Month Rental",
  "cellContractViewScreenTitle": "Contract List",

  "cellOrderDetailScreenTitle": "Order Details",
  "cellOrderEditScreenCell": "Locker And Cell",
  "cellOrderEditScreenEditSuccessMessage": "Edit Order Successfully",
  "cellOrderEditScreenRecipientExpiryHour": "Pickup Time Limit (in hour)",
  "cellOrderEditScreenRecipientPhone": "Recipient Phone No.",
  "cellOrderEditScreenRemark": "Remarks",
  "cellOrderEditScreenSubmit": "Submit",
  "cellOrderEditScreenTitle": "Edit Order",
  "cellOrderEditScreenTrackNumber": "Waybill Number",
  "cellOrderViewScreenActionButtonCancelOrder": "Cancel Order",
  "cellOrderViewScreenActionButtonEdit": "Edit",
  "cellOrderViewScreenActionButtonView": "View",
  "cellOrderViewScreenActionCancelOrderConfirmMessage": "Are you sure to cancel this order?",
  "cellOrderViewScreenActionCancelOrderErrorMessage": "Failed To Cancel Order",
  "cellOrderViewScreenActionCancelOrderSuccessMessage": "Cancel Order Successfully",
  "cellOrderViewScreenButtonPrintReplenishment": "Print Delivery Orders",
  "cellOrderViewScreenTableCreatedAt": "Date Created",
  "cellOrderViewScreenTableExpiredAt": "Pickup Expiry Time",
  "cellOrderViewScreenTableHumanId": "Order No.",
  "cellOrderViewScreenTableLockerCell": "Locker Cell",
  "cellOrderViewScreenTableMerchantName": "Merchant",
  "cellOrderViewScreenTablePickedUpAt": "Pickup At",
  "cellOrderViewScreenTableRecipientExpiryHour": "Pickup Time Limit (in hour)",
  "cellOrderViewScreenTableRecipientPhone": "Recipient Phone No.",
  "cellOrderViewScreenTableRemark": "Remarks",
  "cellOrderViewScreenTableReplenishedAt": "Delivered At",
  "cellOrderViewScreenTableState": "Status",
  "cellOrderViewScreenTableStateCanceled": "Canceled",
  "cellOrderViewScreenTableStatePickedUp": "Picked Up",
  "cellOrderViewScreenTableStateRecalled": "Recalled",
  "cellOrderViewScreenTableStateWaitPickup": "Waiting for pickup",
  "cellOrderViewScreenTableStateWaitPickupExpired": "Waiting for pickup (Expired)",
  "cellOrderViewScreenTableStateWaitReplenishment": "Wait Replenishment",
  "cellOrderViewScreenTableTrackNumber": "Waybill Number",
  "cellOrderViewScreenTitle": "Order List",

  "errorMessageRequiredInputAny": "Please enter %{name}",
  "errorMessageRequiredSelectAny": "Please select %{name}",
  "errorMessageUnknownError": "Unknown errors. Please try again",

  "homeScreenAdminTitleBalance": "Current Balance",
  "homeScreenAdminTitleDayContractCount": "No. of Day Rental Contracts",
  "homeScreenAdminTitleDayContractCountTooltip": "Currently effective Day Rental Contracts",
  "homeScreenAdminTitleDayContractUsagePercent": "Usage of Day Rental",
  "homeScreenAdminTitleDayContractUsagePercentTooltip": "Current usage of Day Rental",
  "homeScreenAdminTitleMerchantDisplayName": "Your Merchant",
  "homeScreenAdminTitleMonthContractCount": "No. of Month Rental Contracts",
  "homeScreenAdminTitleMonthContractCountTooltip": "Currently effective Month Rental Contracts",
  "homeScreenAdminTitleMonthContractUsagePercent": "Usage of Month Rental",
  "homeScreenAdminTitleMonthContractUsagePercentTooltip": "Current Usage of Month Rental",
  "homeScreenAdminTitleTodayExpiredCount": "No. of Expired Orders",
  "homeScreenAdminTitleTodayExpiredCountButtonView": "View Expired Orders",
  "homeScreenAdminTitleTodayExpiredCountTooltip": "No. of Expired Delivered Orders currently",
  "homeScreenAdminTitleTodayOrder": "Today's Orders",
  "homeScreenAdminTitleTodayOrderCompletedCount": "No. of Completed Orders",
  "homeScreenAdminTitleTodayOrderCompletedCountTooltip": "Total No. of Picked up Orders today",
  "homeScreenAdminTitleTodayOrderCount": "No. of Orders",
  "homeScreenAdminTitleTodayOrderCountTooltip": "Total No. of Orders today",
  "homeScreenAdminTitleTodayOrderDeliveredCount": "No. of Delivered Orders",
  "homeScreenAdminTitleTodayOrderDeliveredCountTooltip": "Total No. of Delivered Orders today",
  "homeScreenMerchantMessage": "Merchant Message",
  "homeScreenMessage": "Message",
  "homeScreenMessageMore": "More",
  "homeScreenSystemMessage": "System Message",

  "lockerAddScreenAddSuccessMessage": "Added Locker Successfully",
  "lockerAddScreenButtonSubmit": "Submit",
  "lockerAddScreenCellLayoutPlaceholder": "Not set",
  "lockerAddScreenFormAddress": "Address",
  "lockerAddScreenFormCellLayout": "Cell Layout",
  "lockerAddScreenFormCode": "Code",
  "lockerAddScreenFormCoordinate": "Coordinate",
  "lockerAddScreenFormCoordinateLatPlaceholder": "Latitude",
  "lockerAddScreenFormCoordinateLngPlaceholder": "Longitude",
  "lockerAddScreenFormEnabled": "Enabled",
  "lockerAddScreenFormLockerlyLockerId": "Lockerly ID",
  "lockerAddScreenFormName": "Name",
  "lockerAddScreenFormRegion": "Region",
  "lockerAddScreenFormServiceHour": "Service hours",
  "lockerAddScreenFormVisible": "Visible",
  "lockerAddScreenTitle": "Add Locker",

  "lockerCellContractAddModalAddSuccessMessage": "Created Day Rental Contract Successfully",
  "lockerCellContractAddModalButtonCancel": "Cancel",
  "lockerCellContractAddModalButtonConfirm": "Confirm",
  "lockerCellContractAddModalContractDates": "Contract Dates",
  "lockerCellContractAddModalHourUnit": "hours",
  "lockerCellContractAddModalNoOfDay": "No. of rental days",
  "lockerCellContractAddModalSubmissionTime": "Submission time",
  "lockerCellContractAddModalTitle": "Create a Day Rental Contract",
  "lockerCellLayoutButtonCancel": "Cancel",
  "lockerCellLayoutButtonConfirm": "Confirm",
  "lockerCellLayoutModalBatchEditInstruction": "One line for each cell (name); One more new lines for a new row; ---- for a new side",
  "lockerCellLayoutModalBatchEditTitle": "Batch Edit",
  "lockerCellLayoutModalButtonBatchEdit": "Batch Edit",
  "lockerCellLayoutModalEditCellName": "Cell Name",
  "lockerCellLayoutModalEditCellTitle": "Edit Cell",
  "lockerCellLayoutModalNoOfCol": "No. of Cell",
  "lockerCellLayoutModalNoOfRow": "No. of Layer",
  "lockerCellLayoutModalNoOfSide": "No. of Cabinet",
  "lockerCellLayoutModalTitle": "Edit Cell Layout",
  "lockerCellOrderAddModalAddSuccessMessage": "Added Order Successfully",
  "lockerCellOrderAddModalButtonSubmit": "Submit",
  "lockerCellOrderAddModalConfirmContent": "Please confirm these Waybill Number and Recipient Phone No. are correct",
  "lockerCellOrderAddModalConfirmContentRecipientPhone": "Recipient Phone No.: %{recipientPhone}",
  "lockerCellOrderAddModalConfirmContentTrackNumber": "Waybill Number: %{tracknumber}",
  "lockerCellOrderAddModalConfirmTitle": "Info Confirmation",
  "lockerCellOrderAddModalLockerCell": "Locker Cell",
  "lockerCellOrderAddModalMerchantRemark": "Merchant Remarks",
  "lockerCellOrderAddModalRecipientExpiryHour": "Pickup Time Limit (in hour)",
  "lockerCellOrderAddModalRecipientPhone": "Recipient Phone No.",
  "lockerCellOrderAddModalRecipientPhoneInvalidMessage": "Please enter phone number in the correct format",
  "lockerCellOrderAddModalRecipientPhoneReminderMessage": "Please make sure the Waybill number and the Recipient Phone No. are correct. Incorrect entry can cause replenishment failure or recipient will not receive SMS for pickup",
  "lockerCellOrderAddModalTitle": "Add an Order",
  "lockerCellOrderAddModalTrackNumber": "Waybill Number",

  "lockerDetailCellOrderForceModalSmsAlertMessage": "If you choose not to notify by SMS, you need to notify the recipient separately",
  "lockerDetailCellOrderForceModalSmsLabel": "Send SMS to notify recipient",
  "lockerDetailScreenActionButtonCancelContract": "Cancel Contract",
  "lockerDetailScreenActionButtonCancelOrder": "Cancel Order",
  "lockerDetailScreenActionButtonEditOrder": "Edit Order",
  "lockerDetailScreenActionButtonForcePickupOrder": "Change to Picked up",
  "lockerDetailScreenActionButtonForceRecallOrder": "Change to Recalled",
  "lockerDetailScreenActionButtonForceReplenishOrder": "Change to Delivered",
  "lockerDetailScreenActionCancelContractConfirmMessage": "Are you sure to cancel this contract ?",
  "lockerDetailScreenActionCancelContractErrorMessage": "Failed To Cancel Contract",
  "lockerDetailScreenActionCancelContractNoWaitReplenishmentOrder": "Contract can be canceled only when delivery is not yet made",
  "lockerDetailScreenActionCancelContractSuccessMessage": "Cancel Contract Successfully",
  "lockerDetailScreenActionCancelOrderConfirmMessage": "Are you sure to cancel this order ?",
  "lockerDetailScreenActionCancelOrderErrorMessage": "Failed To Cancel Order",
  "lockerDetailScreenActionCancelOrderSuccessMessage": "Cancel Order Successfully",
  "lockerDetailScreenActionForcePickupOrderConfirmMessage": "Confirm to force changing to Picked up?",
  "lockerDetailScreenActionForcePickupOrderErrorMessage": "Failed to force chaning to Picked up",
  "lockerDetailScreenActionForcePickupOrderSuccessMessage": "Force changing to Picked up Successfully",
  "lockerDetailScreenActionForceRecallOrderConfirmTitle": "Confirm to force changing to Recalled?",
  "lockerDetailScreenActionForceRecallOrderConfirmWithSmsMessage": "Confirm to force change to Recalled? (notify by SMS)",
  "lockerDetailScreenActionForceRecallOrderConfirmWithoutSmsMessage": "Confirm to force change to Recalled? (will NOT notify by SMS)",
  "lockerDetailScreenActionForceRecallOrderErrorMessage": "Failed to force chaning to Recalled",
  "lockerDetailScreenActionForceRecallOrderSuccessMessage": "Force changing to Recalled Successfully",
  "lockerDetailScreenActionForceReplenishOrderConfirmTitle": "Confirm to force changing to Delivered?",
  "lockerDetailScreenActionForceReplenishOrderConfirmWithSmsMessage": "Confirm to force change to Delivered? (notify by SMS)",
  "lockerDetailScreenActionForceReplenishOrderConfirmWithoutSmsMessage": "Confirm to force change to Delivered? (will NOT notify by SMS)",
  "lockerDetailScreenActionForceReplenishOrderErrorMessage": "Failed to Force changing order to Delivered",
  "lockerDetailScreenActionForceReplenishOrderSuccessMessage": "Force changing order to Delivered Successfully",
  "lockerDetailScreenAdminButtonForceOpenSlot": "Force Opening Cell",
  "lockerDetailScreenAdminButtonForceOpenSlotErrorMessageSystemError": "Request failed. Please try again",
  "lockerDetailScreenAdminButtonForceOpenSlotModalTitle": "Are you sure to force opening the cell?",
  "lockerDetailScreenAdminButtonForceOpenSlotSuccessMessage": "Sent opening command successfully",
  "lockerDetailScreenAdminButtonForceOpenSlotTooltip": "Ignoring if the door is opened, open the cell directly",
  "lockerDetailScreenAdminButtonOpenSlot": "Unlock and Open Cell",
  "lockerDetailScreenAdminButtonOpenSlotErrorMessageSystemError": "Request failed. Please try again",
  "lockerDetailScreenAdminButtonOpenSlotModalTitle": "Are you sure to unlock and open the cell?",
  "lockerDetailScreenAdminButtonOpenSlotSuccessMessage": "Sent unlock command successfully",
  "lockerDetailScreenAdminButtonOpenSlotTooltip": "Unlock the door, wait for the door to open the cell automatically",
  "lockerDetailScreenAdminButtonReboot": "Reboot machine",
  "lockerDetailScreenAdminButtonRebootErrorMessageSystemError": "Request failed. Please try again",
  "lockerDetailScreenAdminButtonRebootModalTitle": "Are you sure to restart the machine immediately?",
  "lockerDetailScreenAdminButtonRebootSuccessMessage": "Sent restart command successfully",
  "lockerDetailScreenAdminTitle": "Admin ops",
  "lockerDetailScreenCellContractCreatedAt": "Date Created",
  "lockerDetailScreenCellContractEmpty": "No contract yet",
  "lockerDetailScreenCellContractEndAt": "End Date",
  "lockerDetailScreenCellContractHumanId": "Contract No.",
  "lockerDetailScreenCellContractMerchantName": "Merchant",
  "lockerDetailScreenCellContractPrice": "Price",
  "lockerDetailScreenCellContractStartAt": "Start Date",
  "lockerDetailScreenCellContractTitle": "Current Active Contract",
  "lockerDetailScreenCellContractType": "Type",
  "lockerDetailScreenCellContractTypeDay": "Day Rental",
  "lockerDetailScreenCellContractTypeMonth": "Month Rental",
  "lockerDetailScreenCellOrderCreatedAt": "Date Created",
  "lockerDetailScreenCellOrderEmpty": "No order yet",
  "lockerDetailScreenCellOrderExpiredAt": "Pickup Expiry Time",
  "lockerDetailScreenCellOrderHumanId": "Order No.",
  "lockerDetailScreenCellOrderLockerName": "Locker",
  "lockerDetailScreenCellOrderMerchantCellName": "Cell",
  "lockerDetailScreenCellOrderMerchantLockerCellName": "Locker Cell",
  "lockerDetailScreenCellOrderMerchantName": "Merchant",
  "lockerDetailScreenCellOrderMerchantRemark": "Merchant Remarks",
  "lockerDetailScreenCellOrderQrCodeTitleDeliver": "Delivery QR code",
  "lockerDetailScreenCellOrderQrCodeTitleDeliverShort": "Deliver",
  "lockerDetailScreenCellOrderQrCodeTitlePickup": "Pickup QR Code",
  "lockerDetailScreenCellOrderQrCodeTitleRecall": "Recall QR Code",
  "lockerDetailScreenCellOrderQrCodeTitleRecallShort": "Recall",
  "lockerDetailScreenCellOrderRecipientExpiryHour": "Pickup Time Limit (in hour)",
  "lockerDetailScreenCellOrderRecipientPhone": "Recipient Phone No.",
  "lockerDetailScreenCellOrderShortUrl": "Customer Order Link",
  "lockerDetailScreenCellOrderState": "Status",
  "lockerDetailScreenCellOrderStateCanceled": "Canceled",
  "lockerDetailScreenCellOrderStatePickedUp": "Picked Up",
  "lockerDetailScreenCellOrderStateRecalled": "Recalled",
  "lockerDetailScreenCellOrderStateWaitPickup": "Waiting for pickup",
  "lockerDetailScreenCellOrderStateWaitReplenishment": "Wait Replenishment",
  "lockerDetailScreenCellOrderTabNew": "Current",
  "lockerDetailScreenCellOrderTitle": "Current Active Order",
  "lockerDetailScreenCellOrderTrackNumber": "Waybill Number",
  "lockerDetailScreenCellPrice": "Price (day rental)",
  "lockerDetailScreenCellSize": "Size (mm)",
  "lockerDetailScreenCellStatusCreatedAt": "Order created at",
  "lockerDetailScreenCellStatusCreatedAtTitle": "Order created",
  "lockerDetailScreenCellStatusDeliveredAt": "Delivered at",
  "lockerDetailScreenCellStatusDeliveredAtEmpty": "Waiting for delivery",
  "lockerDetailScreenCellStatusDeliveredAtTitle": "Delivered",
  "lockerDetailScreenCellStatusPickupAt": "Picked up at",
  "lockerDetailScreenCellStatusPickupAtEmpty": "Waiting for pickup",
  "lockerDetailScreenCellStatusPickupAtTitle": "Pickup",
  "lockerDetailScreenCellStatusTitle": "Logistics Status",
  "lockerDetailScreenLockerAddress": "Address",
  "lockerDetailScreenLockerAlertCanOrder": "Ready to use. You have an active contract",
  "lockerDetailScreenLockerAlertCanOrderSystemAdmin": "Ready to use. Merchant has an active contract",
  "lockerDetailScreenLockerAlertCanRent": "Available for rental",
  "lockerDetailScreenLockerAlertHaveOrder": "You have an order currently",
  "lockerDetailScreenLockerAlertHaveOrderSystemAdmin": "Merchant already has an order",
  "lockerDetailScreenLockerAlertOccupied": "Occupied by another merchant",
  "lockerDetailScreenLockerButtonDayRental": "Request Day Rental",
  "lockerDetailScreenLockerButtonOrder": "Create Order",
  "lockerDetailScreenLockerCellError": "Error",
  "lockerDetailScreenLockerLatestTemperature": "Latest Temperature",
  "lockerDetailScreenLockerNoLatestTemperature": "No temperature data in the past 30 minutes",
  "lockerDetailScreenLockerNoLatestTemperatureLabel": "Unknown",
  "lockerDetailScreenLockerNoOfCell": "No. of Cell",
  "lockerDetailScreenLockerSelectCellLabel": "Please select a Cell",
  "lockerDetailScreenLockerServiceHour": "Service hours",
  "lockerDetailScreenLockerStatus": "Status",
  "lockerDetailScreenLockerStatusRunning": "Running",
  "lockerDetailScreenLockerStatusUnknown": "Unknown",
  "lockerDetailScreenTitle": "Locker Detail",

  "lockerEditScreenEditSuccessMessage": "Edited Locker Successfully",
  "lockerEditScreenTitle": "Edit Locker",

  "lockerHistoricalTemperatureModalButtonDownload": "Download xlsx",
  "lockerHistoricalTemperatureModalTimespan168Label": "past 7 days",
  "lockerHistoricalTemperatureModalTimespan48Label": "past 48 hours",
  "lockerHistoricalTemperatureModalTitle": "Historical Temperature of Locker",

  "lockerViewScreenActionButtonCopy": "Copy Locker",
  "lockerViewScreenActionButtonEdit": "Edit",
  "lockerViewScreenActionButtonEditCells": "Edit Cells",
  "lockerViewScreenActionButtonRemove": "Remove",
  "lockerViewScreenActionButtonView": "View",
  "lockerViewScreenActionRemoveConfirmMessage": "Are you sure to remove this Locker?",
  "lockerViewScreenActionRemoveErrorMessage": "Failed to remove Locker",
  "lockerViewScreenActionRemoveSuccessMessage": "Removed Locker Successfully",
  "lockerViewScreenButtonAdd": "Add Locker",
  "lockerViewScreenCopySuccessMessage": "Copied Locker Successfully",
  "lockerViewScreenTableAddress": "Address",
  "lockerViewScreenTableClickToViewHistoricalTemperature": "Click to view the historical temperature",
  "lockerViewScreenTableCode": "Code",
  "lockerViewScreenTableIsEnabled": "Enabled",
  "lockerViewScreenTableLatestTemperature": "Latest Temperature",
  "lockerViewScreenTableName": "Name",
  "lockerViewScreenTableNoTemperature": "No temperature data in the past 30 minutes",
  "lockerViewScreenTableOffline": "Offline",
  "lockerViewScreenTableOnline": "Online",
  "lockerViewScreenTableRegion": "Region",
  "lockerViewScreenTableSearchOnlyHasCellContract": "Only Rented",
  "lockerViewScreenTableSearchRegionAny": "Any Region",
  "lockerViewScreenTableServiceHour": "Service hours",
  "lockerViewScreenTableState": "State",
  "lockerViewScreenTableStatus": "Status",
  "lockerViewScreenTableUnknown": "Unknown",
  "lockerViewScreenTitle": "Locker List",

  "loginScreenFormLoginButton": "Login",
  "loginScreenFormMerchantName": "Merchant Name",
  "loginScreenFormPassword": "Password",
  "loginScreenFormUserName": "User Name",
  "loginScreenLoginFailedMessageIncorrectPassword": "Incorrect Password",
  "loginScreenLoginFailedMessageUserNotFound": "User not found",
  "loginScreenLoginSuccessMessage": "Login Successfully",

  "merchantAddScreenAddSuccessMessage": "Added Merchant Successfully",
  "merchantAddScreenButtonSubmit": "Submit",
  "merchantAddScreenFormContactPhone": "Contact Phone",
  "merchantAddScreenFormDisplayName": "Display Name",
  "merchantAddScreenFormLocale": "Locale",
  "merchantAddScreenFormLocaleEn": "English",
  "merchantAddScreenFormLocaleZhCn": "Simplified Chinese",
  "merchantAddScreenFormLocaleZhTw": "Traditional Chinese",
  "merchantAddScreenFormName": "Login Name",
  "merchantAddScreenTitle": "Add a Merchant",
  "merchantAddTransactionModalAmount": "Amount",
  "merchantAddTransactionModalMerchantName": "Merchant",
  "merchantAddTransactionModalRemark": "Remark",
  "merchantAddTransactionModalSubmit": "Submit",
  "merchantAddTransactionModalTitle": "Add Transaction",
  "merchantAddTransactionModalType": "Type",
  "merchantAddTransactionModalTypeCredit": "Credit",
  "merchantAddTransactionModalTypeDayRental": "Day Rental",
  "merchantAddTransactionModalTypeMonthRental": "Month Rental",
  "merchantAddTransactionModalTypeRefund": "Refund",
  "merchantAddTransactionModalTypeTransfer": "Bank Transfer",

  "merchantBillDetailScreenBillCellContractPanelHeader": "",
  "merchantBillDetailScreenBillCellContractPanelHeaderDayType": "%{lockerName} / %{cellName} (Price: $%{price}) (Contract Period: %{duration}hours)",
  "merchantBillDetailScreenBillCellContractPanelHeaderMonthType": "%{lockerName} / %{cellName} (Price: $%{price})",
  "merchantBillDetailScreenCreatedAt": "Date Created",
  "merchantBillDetailScreenDecscription": "Description",
  "merchantBillDetailScreenMerchantName": "Merchant",
  "merchantBillDetailScreenPrice": "Amount",
  "merchantBillDetailScreenReceiptNo": "Bill No.",
  "merchantBillDetailScreenRemark": "Remarks",
  "merchantBillDetailScreenTitle": "Bill Details",
  "merchantBillDetailScreenTypeDay": "Day Rental",
  "merchantBillDetailScreenTypeMonth": "Month Rental",
  "merchantBillViewScreenActionButtonView": "View",
  "merchantBillViewScreenDescription": "Description",
  "merchantBillViewScreenTableCreatedAt": "Date Created",
  "merchantBillViewScreenTableMerchantName": "Merchant",
  "merchantBillViewScreenTablePrice": "Amount",
  "merchantBillViewScreenTableReceiptNo": "Bill No.",
  "merchantBillViewScreenTableRemark": "Remarks",
  "merchantBillViewScreenTableType": "Type",
  "merchantBillViewScreenTitle": "Bill List",
  "merchantBillViewScreenTypeDay": "Day Rental Bill",
  "merchantBillViewScreenTypeMonth": "Month Rental Bill",

  "merchantDetailScreenBalance": "Current Balance",
  "merchantDetailScreenCreatedAt": "Date Created",
  "merchantDetailScreenDisplayLocale": "Locale",
  "merchantDetailScreenDisplayName": "Display Name",
  "merchantDetailScreenMerchantName": "Merchant",
  "merchantDetailScreenTitle": "Merchant Detail",

  "merchantEditScreenEditSuccessMessage": "Edited Merchant Successfully",
  "merchantEditScreenTitle": "Edit a Merchant",

  "merchantTransactionViewScreenTableAmount": "Amount",
  "merchantTransactionViewScreenTableCreatedAt": "Date Created",
  "merchantTransactionViewScreenTableMerchantName": "Merchant",
  "merchantTransactionViewScreenTableRemark": "Remarks",
  "merchantTransactionViewScreenTableType": "Type",
  "merchantTransactionViewScreenTableTypeCredit": "Credit",
  "merchantTransactionViewScreenTableTypeDayRental": "Day Rental",
  "merchantTransactionViewScreenTableTypeMonthRental": "Month Rental",
  "merchantTransactionViewScreenTableTypeRefund": "Refund",
  "merchantTransactionViewScreenTableTypeTransfer": "Bank Transfer",
  "merchantTransactionViewScreenTitle": "Transaction List",

  "merchantViewScreenActionButtonAddTransaction": "Add Transaction",
  "merchantViewScreenActionButtonEdit": "Edit",
  "merchantViewScreenActionButtonRemove": "Remove",
  "merchantViewScreenActionButtonView": "View",
  "merchantViewScreenActionRemoveConfirm": "Are you sure to remove this Merchant?",
  "merchantViewScreenActionRemoveErrorMessage": "Failed to remove Merchant",
  "merchantViewScreenActionRemoveSuccessMessage": "Removed Merchant Successfully",
  "merchantViewScreenButtonAdd": "Add Merchant",
  "merchantViewScreenCreatedAt": "Created At",
  "merchantViewScreenIsLocked": "Locked",
  "merchantViewScreenTableBalance": "Balance",
  "merchantViewScreenTableContactPhone": "Contact Phone",
  "merchantViewScreenTableDisplayName": "Display Name",
  "merchantViewScreenTableName": "Name",
  "merchantViewScreenTitle": "Merchant List",

  "regionAddScreenAddSuccessMessage": "Added Region Successfully",
  "regionAddScreenButtonSubmit": "Submit",
  "regionAddScreenFormName": "Name",
  "regionAddScreenTitle": "Add a Region",

  "regionEditScreenEditSuccessMessage": "Edited Region Successfully",
  "regionEditScreenTitle": "Edit Region",

  "regionViewScreenActionButtonEdit": "Edit",
  "regionViewScreenActionButtonRemove": "Remove",
  "regionViewScreenActionRemoveConfirmMessage": "Are you sure to remove this region?",
  "regionViewScreenActionRemoveErrorMessage": "Failed to remove Region",
  "regionViewScreenActionRemoveSuccessMessage": "Removed Region Successfully",
  "regionViewScreenButtonAdd": "Add Region",
  "regionViewScreenTableName": "Name",
  "regionViewScreenTitle": "Region List",

  "tablePaginationTotal": "Total %{total} records",

  "tableSearchBillTypeAny": "Any Type",
  "tableSearchBillTypeDay": "Day Rental Bill",
  "tableSearchBillTypeMonth": "Month Rental Bill",
  "tableSearchContractTypeAny": "Any Type",
  "tableSearchContractTypeDay": "Day Rental",
  "tableSearchContractTypeMonth": "Month Rental",
  "tableSearchLockerAny": "Any Locker",
  "tableSearchMerchantAny": "Any Merchant",
  "tableSearchMerchantTransactionTypeCredit": "Credit",
  "tableSearchMerchantTransactionTypeDayRental": "Day Rental",
  "tableSearchMerchantTransactionTypeMonthRental": "Month Rental",
  "tableSearchMerchantTransactionTypeRefund": "Refund",
  "tableSearchMerchantTransactionTypeTransfer": "Bank Transfer",
  "tableSearchOrderStateAny": "Any State",
  "tableSearchOrderStateCanceled": "Canceled",
  "tableSearchOrderStatePickedUp": "Picked Up",
  "tableSearchOrderStateRecalled": "Recalled",
  "tableSearchOrderStateWaitPickup": "Waiting for pickup",
  "tableSearchOrderStateWaitReplenishment": "Wait Replenishment",
  "tableSearchOrderStateWatiPickupExpired": "Waiting for pickup and Expired",
  "tableSearchOrderTypeAny": "Any Type",
  "tableSearchOrderTypeDay": "Day Rental",
  "tableSearchOrderTypeMonth": "Month Rental",
  "tableSearchPlaceholder": "Search...",
  "tableSearchTransactionTypeAny": "Any Type",

  "userAddScreenAddSuccessMessage": "Added User Successfully",
  "userAddScreenButtonSubmit": "Submit",
  "userAddScreenFormDisplayLocale": "Locale",
  "userAddScreenFormDisplayName": "Display Name",
  "userAddScreenFormEmail": "Email",
  "userAddScreenFormLocaleEn": "English",
  "userAddScreenFormLocaleZhCn": "Simplified Chinese",
  "userAddScreenFormLocaleZhTw": "Traditional Chinese",
  "userAddScreenFormMerchant": "Merchant",
  "userAddScreenFormName": "Login Name",
  "userAddScreenFormPassword": "Password",
  "userAddScreenFormPhone": "Phone",
  "userAddScreenFormRoles": "Roles",
  "userAddScreenTitle": "Add a User",

  "userEditScreenEditSuccessMessage": "Edited User Successfullay",
  "userEditScreenTitle": "Edit a User",

  "userViewScreenActionButtonChangePassword": "Change Password",
  "userViewScreenActionButtonEdit": "Edit",
  "userViewScreenActionButtonRemove": "Remove",
  "userViewScreenActionRemoveConfirm": "Are you sure to remove this User?",
  "userViewScreenActionRemoveErrorMessage": "Failed to remove User",
  "userViewScreenActionRemoveSuccessMessage": "Removed User Successfully",
  "userViewScreenButtonAdd": "Add User",
  "userViewScreenLocaleZhCn": "Simplified Chinese",
  "userViewScreenLocaleZhEn": "English",
  "userViewScreenLocaleZhTw": "Traditional Chinese",
  "userViewScreenTableDisplayName": "Display Name",
  "userViewScreenTableEmail": "Email",
  "userViewScreenTableLocale": "Locale",
  "userViewScreenTableName": "Login Name",
  "userViewScreenTablePhone": "Phone",
  "userViewScreenTableRoles": "Roles",
  "userViewScreenTitle": "User List"
}