import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';

import logoDeliver from '../images/qrcode-logo-deliver.png';
import logoRecall from '../images/qrcode-logo-recall.png';
import logoPickup from '../images/qrcode-logo-pickup.png';

export default function CellOrderPrintScreen(props) {
  const {value, type, ...rest} = props;

  let logo;
  switch (type) {
    case 'DELIVER':
      logo = logoDeliver;
      break;
    case 'RECALL':
      logo = logoRecall;
      break;
    case 'PICKUP':
      logo = logoPickup;
      break;
  }

  return (
    <QRCode
      size={128}
      renderAs="svg"
      value={value}
      includeMargin={false}
      level="H"
      imageSettings={{
        width: (rest.size || 128) / 128 * 32,
        height: (rest.size || 128) / 128 * 32,
        src: logo,
        excavate: true,
        ...rest?.imageSettings,
      }}
      {...rest}
    />
  );
};
