import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { PageHeader, Form, Input, Button, Switch, Typography, Spin, Divider, message, Tag } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;
const { CheckableTag } = Tag;

const CellNames = (props) => {
  const { options, onChange, value=[] } = props;

  return (
    <>
      <div>
        {options.map(option => (
          <CheckableTag
            key={option}
            checked={value.some(value => value === option)}
            onChange={checked => {
              if (checked) {
                onChange([...value, option].sort());
              } else {
                onChange([...value.filter(value => value !== option)].sort());
              }
            }}
          >
            {option}
          </CheckableTag>
        ))}
      </div>
      <div>
        ({value.length} selected cell names)
      </div>
    </>
  );
};

export default function LockerCellAutoAddScreen(props) {
  const history = useHistory();
  const { lockerId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cellNames, setCellNames] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const lockerRes = await ToolRequest.request('GET', `/v1/locker/${lockerId}`);
        const cellRes = await ToolRequest.request('GET', `/v1/cell`, {
          lockerId,
          count: 1000,
        });

        const missingCellNames = [];
        for (let sideLayout of lockerRes.cellLayout) {
          for (let row of sideLayout) {
            for (let cellName of row) {
              if (!cellRes.data.find(cell => cell.name === cellName)) {
                missingCellNames.push(cellName);
              }
            }
          }
        }

        setCellNames(missingCellNames.sort());

        setLoaded(true);
      } catch (err) {
        console.error(err);
        // TODO
      }
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    let successCount = 0;

    for (let cellName of values.cellNames) {
      try {
        const data = {
          lockerId,
          lockerlyCellId: null,   // TODO
          depth: parseInt(values.depth),
          width: parseInt(values.width),
          height: parseInt(values.height),
          price: values.price ? Math.floor(values.price * 1000000) : null,
          name: cellName,
          isEnabled: values.isEnabled,
        };

        const res = await ToolRequest.request('POST', '/v1/cell', data);

        ++successCount;
      } catch (err) {
      }
    }

    if (successCount) {
      message.success('Create Locker Cell successfully');

      history.push(`/locker/edit/${lockerId}/cell`);
    } else {
      setLoading(false);

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title="Auto Add Cells"
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={{
            isEnabled: true,
            cellNames,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Cell Names"
            name="cellNames"
            rules={[
              {
                required: true,
                type: 'array',
                min: 1,
                message: 'Please select the cell names',
              },
            ]}
          >
            <CellNames
              options={cellNames}
            />
          </Form.Item>

          <Form.Item
            label="Enabled"
            name="isEnabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Price"
            name="price"
            rules={[
              {
                required: true,
                message: 'Please enter the price',
              },
            ]}
          >
            <Input
              placeholder="Price"
            />
          </Form.Item>

          <Divider />

          <Form.Item
            label="Width"
            name="width"
            rules={[
              {
                required: true,
                message: 'Please enter the width (mm)',
              },
            ]}
          >
            <Input
              placeholder="mm"
            />
          </Form.Item>

          <Form.Item
            label="Height"
            name="height"
            rules={[
              {
                required: true,
                message: 'Please enter the height (mm)',
              },
            ]}
          >
            <Input
              placeholder="mm"
            />
          </Form.Item>

          <Form.Item
            label="Depth"
            name="depth"
            rules={[
              {
                required: true,
                message: 'Please enter the depth (mm)',
              },
            ]}
          >
            <Input
              placeholder="mm"
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
