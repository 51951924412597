import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import {Form, Input, Button, Typography, Spin, message, PageHeader} from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;

export default function RegionEditScreen(props) {
  const history = useHistory();
  const metadata = useSelector(state => state.metadata.value);
  const { regionId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState(null);

  useEffect(() => {
    (async () => {
      const regionRes = await ToolRequest.request('GET', `/v1/region/${regionId}`);

      const regionNew = {...regionRes};
      for (let locale of metadata.supportedLocales) {
        regionNew[`name[${locale}]`] = regionRes.name[locale];
      }
      setRegion(regionNew);

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      let name = {};
      for (let locale of metadata.supportedLocales) {
        name[locale] = values[`name[${locale}]`];
      }
      const data = {
        name
      };

      const res = await ToolRequest.request('PUT', `/v1/region/${regionId}`, data);

      message.success(I18n.t('regionEditScreenEditSuccessMessage'));

      history.push('/region');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('regionEditScreenTitle')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={region}
          onFinish={onFinish}
        >
          {metadata.supportedLocales.map(locale => {
            return (
              <Form.Item
                key={`name[${locale}]`}
                label={`${I18n.t('lockerAddScreenFormAddress')} (${locale})`}
                name={`name[${locale}]`}
                rules={[
                  {
                    required: true,
                    message: I18n.t('errorMessageRequiredInputAny', {
                      name: `${I18n.t('lockerAddScreenFormAddress')} (${locale})`,
                    }),
                  },
                ]}
              >
                <Input
                  label={`${I18n.t('lockerAddScreenFormAddress')} (${locale})`}
                />
              </Form.Item>
            );
          })}

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {I18n.t('lockerAddScreenButtonSubmit')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
