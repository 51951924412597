import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

import { Form, Input, InputNumber, Button, Cascader, Spin, Divider, List, Modal, Typography, message, Space, Tree } from 'antd';
import { ArrowLeftOutlined, EditOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import * as ToolRequest from '../tools/ToolRequest';

import SpinFull from './SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Text } = Typography;

export default function CellContractCellPriceSelect(props) {
  const { value = [], onChange } = props;
  const userData = useSelector(state => state.user.value);

  const formRef = React.createRef();

  const [loaded, setLoaded] = useState(false);
  const [lockerCellsLoaded, setLockerCellsLoaded] = useState(false);
  const [lockerOptions, setLockerOptions] = useState(null);
  const [lockerSelectedOption, setLockerSelectedOption] = useState(null);
  const [lockerCellsTreeData, setLockerCellsTreeData] = useState(null);

  const [editModal, setEditModal] = useState({
    key: Math.random().toString(),
    visible: false,
  });

  const [batchEditModal, setBatchEditModal] = useState({
    key: Math.random().toString(),
    visible: false,
  });

  useEffect(() => {
    (async () => {
      try {
        const lockerRes = await ToolRequest.request('GET', '/v1/locker', {
          count: 1000,
        });
        setLockerOptions(lockerRes.data.map(locker => ({
          label: `(${locker.code}) ${locker.name[userData.locale]}`,
          value: locker.id,
          isLeaf: false,
        })));
      } catch (err) {
      }

      setLoaded(true);
    })();
  }, [editModal.key]);

  useEffect(() => {
    setLockerCellsLoaded(false);

    (async () => {
      try {
        const lockerRes = await ToolRequest.request('GET', '/v1/locker', {
          count: 1000,
        });

        const lockerCellsTreeData = [];

        for (let locker of lockerRes.data) {
          const cellRes = await ToolRequest.request('GET', '/v1/cell', {
            lockerId: locker.id,
            count: 1000,
          });

          lockerCellsTreeData.push({
            title: `(${locker.code}) ${locker.name[userData.locale]}`,
            key: locker.id,
            children: cellRes.data.map(cell => ({
              title: `${cell.name} ($${(cell.price / 1000000).toFixed(1)})`,
              key: cell.id,
              value: cell.id,
              lockerName: locker.name[userData.locale],
              cellName: cell.name,
              price: cell.price,
            })),
          });
        }

        setLockerCellsTreeData(lockerCellsTreeData);
        setLockerCellsLoaded(true);
      } catch (err) {
      }

      setLoaded(true);
    })();
  }, [batchEditModal.key]);

  return (
    <>
      <List
        bordered
        dataSource={value}
        renderItem={cellContract => (
          <List.Item
            actions={[
              <Text strong>${(cellContract.price / 1000000).toFixed(1)}</Text>,
              <Button
                danger
                type="link"
                size="small"
                onClick={() => {
                  onChange(value.filter(cellContractItem => cellContractItem !== cellContract));
                }}
              >
                {I18n.t('cellContractCellPriceSelectButtonRemove')}
              </Button>
            ]}
          >
            {cellContract.lockerName} / {cellContract.cellName}
          </List.Item>
        )}
        footer={
          <Space direction="vertical" style={{ width: '100%' }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              block
              onClick={() => {
                setEditModal({
                  key: Math.random().toString(),
                  visible: true,
                });
              }}
            >
              {I18n.t('cellContractCellPriceSelectButtonAdd')}
            </Button>
            <Button
              type="primary"
              icon={<AppstoreAddOutlined />}
              block
              onClick={() => {
                setBatchEditModal({
                  key: Math.random().toString(),
                  visible: true,
                });
              }}
            >
              {I18n.t('cellContractCellPriceSelectButtonBatchAdd')}
            </Button>
          </Space>
        }
      />

      <Modal
        key={editModal.key}
        title={I18n.t('cellContractCellPriceSelectModalTitle')}
        visible={editModal.visible}
        onOk={() => {

        }}
        onCancel={() => {
          setEditModal({
            ...editModal,
            visible: false,
          });
        }}
        width="80%"
        maskClosable={false}
        footer={null}
      >
        {loaded ? (
          <Form
            {...formLayout}
            ref={formRef}
            onFinish={(values) => {
              setEditModal({
                ...editModal,
                visible: false,
              });

              onChange([
                ...value,
                {
                  ...lockerSelectedOption,
                  cellId: values.lockerCell[1],
                },
              ]);
            }}
          >
            <Form.Item
              label={I18n.t('cellContractCellPriceSelectModalFormLockerCell')}
              name="lockerCell"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredSelectAny', {
                    name: I18n.t('cellContractCellPriceSelectModalFormLockerCell'),
                  }),
                },
              ]}
            >
              <Cascader
                options={lockerOptions}
                placeholder={I18n.t('cellContractCellPriceSelectModalFormLockerCell')}
                loadData={selectedOptions => {
                  const targetOption = selectedOptions[selectedOptions.length - 1];
                  targetOption.loading = true;

                  (async () => {
                    try {
                      const cellRes = await ToolRequest.request('GET', `/v1/cell`, {
                        lockerId: targetOption.value,
                        count: 1000,
                      });

                      targetOption.loading = false;
                      targetOption.children = cellRes.data.map(cell => ({
                        label: `${cell.name} ($${(cell.price / 1000000).toFixed(1)})`,
                        value: cell.id,
                        cell,
                        disabled: value.find(cellContract => cellContract.cellId === cell.id),
                      })).sort((a, b) => {
                        if (a.cell.name < b.cell.name) {
                          return -1;
                        } else if (a.cell.name > b.cell.name) {
                          return 1;
                        }
                        return 0;
                      });
                      setLockerOptions([...lockerOptions]);
                    } catch (err) {
                      targetOption.loading = false;
                    }
                  })();
                }}
                onChange={(_, selectedOptions) => {
                  if (selectedOptions[1]) {
                    formRef.current.setFieldsValue({
                      price: (selectedOptions[1].cell.price / 1000000).toFixed(1),
                    });

                    setLockerSelectedOption({
                      lockerName: selectedOptions[0].label,
                      cellName: selectedOptions[1].cell.name,
                      price: selectedOptions[1].cell.price,
                    });
                  } else {
                    setLockerSelectedOption(null);
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              label={I18n.t('cellContractCellPriceSelectModalFormPrice')}
              name="price"
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    return value > 0 ? Promise.resolve() : Promise.reject();
                  },
                  message: I18n.t('cellContractCellPriceSelectModalFormPriceErrorInvalidMessage'),
                },
              ]}
            >
              <InputNumber
                placeholder={I18n.t('cellContractCellPriceSelectModalFormPrice')}
              />
            </Form.Item>

            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('cellContractCellPriceSelectModalButtonSubmit')}
              </Button>
            </Form.Item>
          </Form>
        ) : <SpinFull />}
      </Modal>

      <Modal
        key={batchEditModal.key}
        title={I18n.t('cellContractCellPriceSelectModalTitle')}
        visible={batchEditModal.visible}
        onOk={() => {

        }}
        onCancel={() => {
          setBatchEditModal({
            ...batchEditModal,
            visible: false,
          });
        }}
        width="80%"
        maskClosable={false}
        footer={null}
      >
        {loaded && lockerCellsLoaded ? (
          <Form
            {...formLayout}
            ref={formRef}
            onFinish={(values) => {
              setBatchEditModal({
                ...batchEditModal,
                visible: false,
              });

              const valueNew = [];

              for (let selectedCellId of values.lockerCells) {
                for (let locker of lockerCellsTreeData) {
                  for (let lockerCell of locker.children) {
                    if (lockerCell.value === selectedCellId) {
                      valueNew.push({
                        lockerName: lockerCell.lockerName,
                        cellName: lockerCell.cellName,
                        price: lockerCell.price,
                        cellId: lockerCell.value,
                      });
                    }
                  }
                }
              }

              onChange(valueNew);
            }}
          >
            <Form.Item
              label={I18n.t('cellContractCellPriceSelectModalFormLockerCell')}
              name="lockerCells"
              valuePropName="checkedKeys"
              trigger="onCheck"
            >
              <Tree
                checkable
                treeData={lockerCellsTreeData}
              />
            </Form.Item>

            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('cellContractCellPriceSelectModalButtonSubmit')}
              </Button>
            </Form.Item>
          </Form>
        ) : <SpinFull />}
      </Modal>
    </>
  );
};
