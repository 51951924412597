import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import {Dropdown, Menu, Button, Modal, message, PageHeader} from 'antd';
import { EditOutlined, CloseOutlined, PlusOutlined ,EllipsisOutlined} from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';
import SuperTable from '../../components/SuperTable';

export default function RegionViewScreen(props) {
  const history = useHistory();
  const userData = useSelector(state => state.user.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const [loaded, setLoaded] = useState(false);
  const [tableKey, setTableKey] = useState(Math.random().toString());

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = ToolPermission.hasPermission(userPermissionsData, 'region', 'put');
        const canRemove = ToolPermission.hasPermission(userPermissionsData, 'region', 'delete');
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    history.push(`/region/edit/${record.id}`);
                    break;
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('regionViewScreenActionRemoveConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE', `/v1/region/${record.id}`);
                            message.success(I18n.t('regionViewScreenActionRemoveSuccessMessage'));
                            setTableKey(Math.random().toString());
                          } catch (err) {
                            message.error(I18n.t('regionViewScreenActionRemoveErrorMessage'));
                          }
                        })();
                      },
                    });
                    break;
                  }
                }
              }
            }>
              {canEdit && (
                <Menu.Item key="edit">
                  <EditOutlined />
                  {I18n.t('regionViewScreenActionButtonEdit')}
                </Menu.Item>
              )}
              {canRemove && (
                <Menu.Item key="remove">
                  <CloseOutlined />
                  {I18n.t('regionViewScreenActionButtonRemove')}
                </Menu.Item>
              )}
            </Menu>
          }>
            <Button
              type="primary"
              icon=<EllipsisOutlined  />
            />
          </Dropdown>
        ) : null;
      },
    },
    ...(ToolPermission.hasPermission(userPermissionsData, 'system-admin', 'get') ? [
      {
        template: 'id',
        key: 'id',
        width: 40,
        title: 'ID',
      },
    ] : []),
    {
      key: 'name',
      title: I18n.t('regionViewScreenTableName'),
      render: (value, record) => {
        return value[userData.locale];
      },
    }
  ];

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
    };
  };

  useEffect(() => {
    (async () => {
      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const regionRes = await ToolRequest.request('GET', '/v1/region', {
      ...body,
    });

    return regionRes;
  };

  return loaded ? (
    <PageHeader
      title={I18n.t('regionViewScreenTitle')}
      extra={[
        ToolPermission.hasPermission(userPermissionsData, 'region', 'post') && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => history.push('/region/add')}
          >
            {I18n.t('regionViewScreenButtonAdd')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
    </PageHeader>
  ) : <SpinFull />;
};
