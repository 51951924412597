import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { Modal, Switch, Space, Alert } from 'antd';

export default function LockerDetailCellOrderForceModal(props) {
  const { title, visible, onOk, onCancel } = props;

  const [includeSms, setIncludeSms] = useState(true);

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={() => onOk(includeSms)}
      onCancel={onCancel}
      width="40%"
      maskClosable={false}
    >
      <Space>
        <span>{I18n.t('lockerDetailCellOrderForceModalSmsLabel')}:</span>
        <Switch checked={includeSms} onChange={checked => setIncludeSms(checked)}/>
      </Space>

      <Alert
        showIcon
        type="warning"
        message={I18n.t('lockerDetailCellOrderForceModalSmsAlertMessage')}
        style={{marginTop: 8}}
      />
    </Modal>
  );
};