import React, { useState, useEffect } from 'react';

import { Spin } from 'antd'

import ReactECharts from 'echarts-for-react';
import moment from 'moment';

import * as ToolRequest from '../tools/ToolRequest';
import { I18n } from 'react-redux-i18n'

export default function LockerHistoricalTemperatureChart(props) {
  const { lockerId, timespan, simple, ...rest } = props;

  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [capturedAtDates, setCapturedAtDates] = useState([moment().subtract(timespan, 'hour'), moment()]);

  useEffect(() => {
    (async () => {
      const temperatureRecordRes = await ToolRequest.request('GET', '/v1/locker-temperature-record', {
        lockerId,
        count: 1000,
        capturedStartAt: capturedAtDates[0].toISOString(),
        capturedEndAt: capturedAtDates[1].toISOString(),
      });

      console.dir(temperatureRecordRes)

      const chartData = temperatureRecordRes.data.map(record => {
        return [
          moment(record.capturedAt).format('YYYY-MM-DD HH:mm:ss'),
          record.temperature,
        ];
      });

      if (chartData.length) {
        chartData.unshift([
          capturedAtDates[1].format('YYYY-MM-DD HH:mm:ss'),
          chartData[0][1],
        ]);
        chartData.push([
          capturedAtDates[0].format('YYYY-MM-DD HH:mm:ss'),
          chartData[chartData.length - 1][1],
        ]);
      }

      setChartData(chartData);

      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <div {...rest}>
        <ReactECharts
          {...props}
          option={{
            grid: {
              top:'10%',
              left: '5%',
              right: '4%',
              bottom: '3%',
              containLabel: true,
            },
            xAxis: {
              type: 'time',
              boundaryGap: false,
            },
            yAxis: {
              type: 'value',
              min: -10,
              max: 30,
              show: !simple,
            },
            tooltip: {
              trigger: 'axis',
              formatter: (params) => {
                return `${moment(params[0].value[0]).format('YYYY-MM-DD HH:mm:ss')}<br>${params[0].marker}<b>${params[0].value[1].toFixed(1)}°C</b>`;
              },
              axisPointer: {
                animation: false
              },
            },
            series: [{
              type: 'line',
              symbolSize: 2,
              data: chartData,
              lineStyle: {
                color: '#35A7FF',
              },
              itemStyle: {
                color: '#35A7FF'
              },
              ...(!simple && {
                markPoint: {
                  data: [
                    { type: 'max', name: 'Highest' },
                    { type: 'min', name: 'Lowest' },
                  ],
                  label: {
                    color: '#FFFFFF',
                    formatter: (param) => {
                      return param.data.value.toFixed(1);
                    },
                  },
                },
                markLine: {
                  data: [
                    { type: 'average', name: 'Average' },
                  ],
                  label: {
                    position: 'insideEndTop',
                    formatter: (param) => {
                      return param.data.value.toFixed(1);
                    },
                  },
                },
              }),
            }],
          }}
        />
      </div>
    </Spin>
  );
};
