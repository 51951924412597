import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import {Form, Input, Button, Typography, Spin, message, PageHeader, Select} from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';
import * as ToolPermission from "../../tools/ToolPermission";

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;
const { Option } = Select;

export default function UserEditScreen(props) {
  const history = useHistory();
  const metadata = useSelector(state => state.metadata.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);
  const userData = useSelector(state => state.user.value);
  const { userId } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const canSetRoles = ToolPermission.hasPermission(userPermissionsData, 'user', 'delete');
  const roles = ['SUPER_ADMIN','ADMIN'].map(item=>{
    return <Option key={item}>{item}</Option>
  });
  useEffect(() => {
    (async () => {
      try {
        const userRes = await ToolRequest.request('GET', `/v1/user/${userId}`);
        const userNew = {...userRes};
        for (let locale of metadata.supportedLocales) {
          userNew[`name[${locale}]`] = userRes.name[locale];
        }

        const merchantRes = await ToolRequest.request('GET', `/v1/merchant/${userRes.merchantId}`);
        userNew.merchant = merchantRes;
        setUser(userNew);
      } catch (e) {
        console.dir(e);
      }

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const data = {
        displayName:values.displayName,
        email:values.email || null,
        phone:values.phone || null,
        locale:values.locale,
        roles:values.roles
      };

      const res = await ToolRequest.request('PUT', `/v1/user/${userId}`, data);

      message.success(I18n.t('userEditScreenEditSuccessMessage'));

      history.push('/user');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('userEditScreenTitle')}
    >

      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={user}
          onFinish={onFinish}
        >
          <Form.Item
            label={I18n.t('userAddScreenFormMerchant')}
            name="merchantId"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              disabled
            >
              <Option value={user.merchant.id}>
                {user.merchant.displayName} ({user.merchant.name})
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={I18n.t('userAddScreenFormName')}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('userAddScreenFormDisplayName')}
            name="displayName"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('userAddScreenFormDisplayName'),
                }),
              },
            ]}
          >
            <Input
              placeholder={I18n.t('userAddScreenFormDisplayName')}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('userAddScreenFormDisplayLocale')}
            name="locale"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredSelectAny', {
                  name: I18n.t('userAddScreenFormDisplayLocale'),
                }),
              },
            ]}
          >
            <Select placeholder={I18n.t('userAddScreenFormLocale')}>
              <Option value="en">
                {I18n.t('userAddScreenFormLocaleEn')}
              </Option>
              <Option value="zh-tw">
                {I18n.t('userAddScreenFormLocaleZhTw')}
              </Option>
              <Option value="zh-cn">
                {I18n.t('userAddScreenFormLocaleZhCn')}
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={I18n.t('userAddScreenFormEmail')}
            name="email"
          >
            <Input type="email" placeholder={I18n.t('userAddScreenFormEmail')} />
          </Form.Item>

          <Form.Item
            label={I18n.t('userAddScreenFormPhone')}
            name="phone"
          >
            <Input placeholder={I18n.t('userAddScreenFormPhone')} />
          </Form.Item>

          <Form.Item
            label={I18n.t('userAddScreenFormRoles')}
            name="roles"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredSelectAny', {
                  name: I18n.t('userAddScreenFormRoles'),
                }),
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              disabled={!canSetRoles}
              placeholder={I18n.t('userAddScreenFormRoles')}
            >
              {roles}
            </Select>
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {I18n.t('userAddScreenButtonSubmit')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
