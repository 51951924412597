import React, { useState, useEffect }  from 'react';
import {useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

import { Card, Row, Col, Statistic, Tooltip, Typography} from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

import '../../less/HomeScreen.less';

const { Title } = Typography;

export default function HomeScreen(props) {
  const { merchantId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [merchant, setMerchant] = useState(null);

  const [contractDayCount, setContractDayCount] = useState(0);
  const [contractMonthCount, setContractMonthCount] = useState(0);
  const [contractDayPercentage, setContractDayPercentage] = useState(0);
  const [contractMonthPercentage, setContractMonthPercentage] = useState(0);

  const [orderTodayAllCount, setOrderTodayAllCount] = useState(0);
  const [orderTodayDeliveredCount, setOrderTodayDeliveredCount] = useState(0);
  const [orderTodayCompletedCount, setOrderTodayCompletedCount] = useState(0);

  const loadDataAdmin = async () => {
    const todayBeginAt = moment().startOf('day');
    const todayEndAt = moment(todayBeginAt).add(1, 'day');

    return Promise.all([
      (async () => {
        const contractRes = await ToolRequest.request('GET', `/v1/cell-contract`, {
          merchantId,
          status: 'ONGOING',
          type: 'DAY_RENTAL',
        });
        setContractDayCount(contractRes.totalCount);

        if (contractRes.totalCount > 0) {
          let inUseCount = 0;
          for (let contract of contractRes.data) {
            const orderRes = await ToolRequest.request('GET', `/v1/cell-order`, {
              merchantId,
              cellId: contract.cellId,
              count: 1,
            });
            const order = orderRes.data[0];
            if (order && order.state !== 'PICKED_UP' && order.state !== 'CANCELED') {
              ++inUseCount;
            }
          }
          setContractDayPercentage(inUseCount / contractRes.totalCount * 100);
        }
      })(),

      (async () => {
        const contractRes = await ToolRequest.request('GET', `/v1/cell-contract`, {
          merchantId,
          status: 'ONGOING',
          type: 'MONTH_RENTAL',
        });
        setContractMonthCount(contractRes.totalCount);

        if (contractRes.totalCount > 0) {
          let inUseCount = 0;
          for (let contract of contractRes.data) {
            const orderRes = await ToolRequest.request('GET', `/v1/cell-order`, {
              merchantId,
              cellId: contract.cellId,
              count: 1,
            });
            const order = orderRes.data[0];
            if (order && order.state !== 'PICKED_UP' && order.state !== 'CANCELED') {
              ++inUseCount;
            }
          }
          setContractMonthPercentage(inUseCount / contractRes.totalCount * 100);
        }
      })(),

      (async () => {
        const orderRes = await ToolRequest.request('GET', `/v1/cell-order`, {
          merchantId,
          createdAtFrom: todayBeginAt.toISOString(),
          createdAtTo: todayEndAt.toISOString(),
          count: 0,
        });
        setOrderTodayAllCount(orderRes.totalCount);
      })(),

      (async () => {
        const orderRes = await ToolRequest.request('GET', `/v1/cell-order`, {
          merchantId,
          createdAtFrom: todayBeginAt.toISOString(),
          createdAtTo: todayEndAt.toISOString(),
          count: 0,
          state: 'WAIT_PICKUP',
        });
        setOrderTodayDeliveredCount(orderRes.totalCount);
      })(),

      (async () => {
        const orderRes = await ToolRequest.request('GET', `/v1/cell-order`, {
          merchantId,
          createdAtFrom: todayBeginAt.toISOString(),
          createdAtTo: todayEndAt.toISOString(),
          count: 0,
          state: 'PICKED_UP',
        });
        setOrderTodayCompletedCount(orderRes.totalCount);
      })(),
    ]);
  };

  useEffect(() => {
    (async () => {
      try {
        const merchantRes = await ToolRequest.request('GET', `/v1/merchant/${merchantId}`);
        setMerchant(merchantRes);
      } catch (err) {
      }

      await loadDataAdmin();
      setLoaded(true);
    })();
  }, []);

  const renderAdmin = () => {
    return (
      <>
        <Card>
          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                title={I18n.t('homeScreenAdminTitleMerchantDisplayName')}
                value={merchant.displayName}
              />
            </Col>

            <Col span={12}>
              <Statistic
                title={I18n.t('homeScreenAdminTitleBalance')}
                value={merchant.balance / 1000000}
                precision={2}
                prefix="$"
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleDayContractCountTooltip')}>
                    {I18n.t('homeScreenAdminTitleDayContractCount')}
                  </Tooltip>
                )}
                value={contractDayCount}
              />
            </Col>

            <Col span={12}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleMonthContractCountTooltip')}>
                    {I18n.t('homeScreenAdminTitleMonthContractCount')}
                  </Tooltip>
                )}
                value={contractMonthCount}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleDayContractUsagePercentTooltip')}>
                    {I18n.t('homeScreenAdminTitleDayContractUsagePercent')}
                  </Tooltip>
                )}
                precision={1}
                value={contractDayPercentage}
                suffix="%"
              />
            </Col>

            <Col span={12}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleMonthContractUsagePercentTooltip')}>
                    {I18n.t('homeScreenAdminTitleMonthContractUsagePercent')}
                  </Tooltip>
                )}
                precision={1}
                value={contractMonthPercentage}
                suffix="%"
              />
            </Col>
          </Row>
        </Card>

        <Title level={5} style={{marginTop: 16}}>{I18n.t('homeScreenAdminTitleTodayOrder')}</Title>

        <Card>
          <Row gutter={16}>
            <Col span={8}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleTodayOrderCountTooltip')}>
                    {I18n.t('homeScreenAdminTitleTodayOrderCount')}
                  </Tooltip>
                )}
                value={orderTodayAllCount}
              />
            </Col>

            <Col span={8}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleTodayOrderDeliveredCountTooltip')}>
                    {I18n.t('homeScreenAdminTitleTodayOrderDeliveredCount')}
                  </Tooltip>
                )}
                value={orderTodayDeliveredCount}
              />
            </Col>

            <Col span={8}>
              <Statistic
                title={(
                  <Tooltip title={I18n.t('homeScreenAdminTitleTodayOrderCompletedCountTooltip')}>
                    {I18n.t('homeScreenAdminTitleTodayOrderCompletedCount')}
                  </Tooltip>
                )}
                value={orderTodayCompletedCount}
              />
            </Col>
          </Row>
        </Card>
      </>
    );
  };

  return loaded ? (
    renderAdmin()
  ) : <SpinFull />;
};