import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, Divider, message } from 'antd';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;

export default function MerchantEditScreen(props) {
  const history = useHistory();
  const metadata = useSelector(state => state.metadata.value);
  const { merchantId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [merchant, setMerchant] = useState(null);

  useEffect(() => {
    (async () => {
      const merchantRes = await ToolRequest.request('GET', `/v1/merchant/${merchantId}`);

      setMerchant({
        ...merchantRes,
        locale: [merchantRes.locale],
      });

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const data = {
        displayName: values.displayName,
        locale: values.locale[0],
        contactPhone: values.contactPhone,
      };

      const res = await ToolRequest.request('PUT', `/v1/merchant/${merchantId}`, data);

      message.success(I18n.t('merchantEditScreenEditSuccessMessage'));

      history.push('/merchant');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('merchantEditScreenTitle')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={merchant}
          onFinish={onFinish}
        >
          <Form.Item
            label={I18n.t('merchantAddScreenFormName')}
            name="name"
          >
            <Input
              placeholder={I18n.t('merchantAddScreenFormName')}
              disabled
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('merchantAddScreenFormDisplayName')}
            name="displayName"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('merchantAddScreenFormDisplayName'),
                }),
              },
            ]}
          >
            <Input
              placeholder={I18n.t('merchantAddScreenFormDisplayName')}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('merchantAddScreenFormLocale')}
            name="locale"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('merchantAddScreenFormLocale'),
                }),
              },
            ]}
          >
            <Cascader
              allowClear={false}
              options={[
                {
                  label: I18n.t('merchantAddScreenFormLocaleEn'),
                  value: 'en',
                },
                {
                  label: I18n.t('merchantAddScreenFormLocaleZhTw'),
                  value: 'zh-tw',
                },
                {
                  label: I18n.t('merchantAddScreenFormLocaleZhCn'),
                  value: 'zh-cn',
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('merchantAddScreenFormContactPhone')}
            name="contactPhone"
          >
            <Input
              placeholder={I18n.t('merchantAddScreenFormContactPhone')}
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {I18n.t('merchantAddScreenButtonSubmit')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
