import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import {
  Modal,
  Form,
  Input,
  Button,
  Cascader,
  Spin,
  Divider,
  Select,
  message,
  InputNumber,
  Alert,
  Typography,
} from 'antd'
import { EditOutlined, PlusOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { TextArea } = Input;
const { Paragraph } = Typography;
const { Option } = Select;

export default function LockerCellOrderEditModal(props) {
  const { cellOrderId, visible, onOk, onCancel } = props;
  const userData = useSelector(state => state.user.value);

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cellOrder, setCellOrder] = useState(null);
  const [selectedPhonePrefix, setSelectedPhonePrefix] = useState('+852');

  useEffect(() => {
    (async () => {
      const cellOrderRes = await ToolRequest.request('GET', `/v1/cell-order/${cellOrderId}`);
      const cellRes = await ToolRequest.request('GET', `/v1/cell/${cellOrderRes.cellId}`);
      const lockerRes = await ToolRequest.request('GET', `/v1/locker/${cellRes.lockerId}`);
      cellOrderRes.lockerAndCellName = `${lockerRes.name[userData.locale]} / ${cellRes.name}`;
      if(cellOrderRes.recipientPhone){
        cellOrderRes.recipientPhone = cellOrderRes.recipientPhone.split('+852-')[1];
      }
      setCellOrder(cellOrderRes);
      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    Modal.confirm({
      title: I18n.t('lockerCellOrderAddModalConfirmTitle'),
      content: (
        <div>
          <Paragraph>{I18n.t('lockerCellOrderAddModalConfirmContent')}</Paragraph>
          <Paragraph strong>
            {I18n.t('lockerCellOrderAddModalConfirmContentTrackNumber', {
              trackNumber: values.trackNumber || '-',
            })}
          </Paragraph>
          <Paragraph strong>
            {I18n.t('lockerCellOrderAddModalConfirmContentRecipientPhone', {
              recipientPhone: values.recipientPhone,
            })}
          </Paragraph>
        </div>
      ),
      onOk: async () => {
        setLoading(true);

        try {
          let data = {
            recipientPhone: values.recipientPhone ? `${selectedPhonePrefix}-${values.recipientPhone}` : null,
            merchantRemark: values.merchantRemark,
            trackNumber: values.trackNumber,
            recipientExpiryHour: values.recipientExpiryHour,
          };

          if(cellOrder.state !== 'WAIT_REPLENISHMENT'){
            data = {
              merchantRemark: values.merchantRemark
            };
          }

          await ToolRequest.request('PUT', `/v1/cell-order/${cellOrderId}`, data);
          message.success(I18n.t('cellOrderEditScreenEditSuccessMessage'));
          onOk();
        } catch (err) {
          setLoading(false);

          if (err?.response?.data) {
            switch (err.response.data) {
              default:
                return message.error(I18n.t('errorMessageUnknownError'));
            }
          }

          message.error(I18n.t('errorMessageUnknownError'));
        }
      },
    });
  };

  return (
    <Modal
      title={I18n.t('cellOrderEditScreenTitle')}
      visible={visible}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={cellOrder}
          onFinish={onFinish}
        >
          <Form.Item
            label={I18n.t('cellOrderEditScreenCell')}
            name="lockerAndCellName"
          >
            <Input
              disabled={true}
              placeholder={I18n.t('lockerCellOrderAddModalTrackNumber')}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('cellOrderEditScreenTrackNumber')}
            name="trackNumber"
          >
            <Input
              disabled={cellOrder.state !== 'WAIT_REPLENISHMENT'}
              placeholder={I18n.t('cellOrderEditScreenTrackNumber')}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('cellOrderEditScreenRecipientPhone')}
            name="recipientPhone"
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator: async (_, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (!value.match(/^\d{8}$/)) {
                    return Promise.reject(new Error(I18n.t('lockerCellOrderAddModalRecipientPhoneInvalidMessage')));
                  }
                },
              }),
            ]}
          >
            <Input
              disabled={cellOrder.state !== 'WAIT_REPLENISHMENT'}
              addonBefore={(
                <Select value={selectedPhonePrefix} onChange={value => setSelectedPhonePrefix(value)}>
                  <Option value="+852">+852</Option>
                </Select>
              )}
              placeholder={I18n.t('cellOrderEditScreenRecipientPhone')}
            />
          </Form.Item>

          <Form.Item
            {...formTailLayout}
          >
            <Alert
              message={I18n.t('lockerCellOrderAddModalRecipientPhoneReminderMessage')}
              type="warning"
              showIcon
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('cellOrderEditScreenRecipientExpiryHour')}
            name="recipientExpiryHour"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              disabled={cellOrder.state !== 'WAIT_REPLENISHMENT'}
              min={1}
              defaultValue={1}
              placeholder={I18n.t('cellOrderEditScreenRecipientExpiryHour')}
            />
          </Form.Item>

          <Divider />

          <Form.Item
            label={I18n.t('cellOrderEditScreenRemark')}
            name="merchantRemark"
          >
              <TextArea
                placeholder={I18n.t('cellOrderEditScreenRemark')}
              />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {I18n.t('cellOrderEditScreenSubmit')}
            </Button>
          </Form.Item>
        </Form>
        </Spin>
      ) : <SpinFull />}
    </Modal>
  );
};