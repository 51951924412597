import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { I18n, setLocale } from 'react-redux-i18n';

import { connect, useDispatch } from 'react-redux';

import { Form, Input, Button, Typography, Spin, message } from 'antd';

import { updateUser } from '../../reducers/userSlice';
import { updateUserPermissions } from '../../reducers/userPermissionsSlice';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolUser from '../../tools/ToolUser';

import '../../less/LoginScreen.less';
import logo from '../../images/wecooler-logo-white.png';

const formLayout = {
  labelCol: {
    sm: { span: 6 },
  },
  wrapperCol: {
    sm: { span: 12 },
  },
}

const formTailLayout = {
  wrapperCol: {
    sm: {
      offset: 6,
      span: 12,
    },
  },
};

const { Title } = Typography;

export default function LoginScreen(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    ToolRequest.request('POST', '/v1/auth/password/verify', {
      merchantName: values.merchantName,
      username: values.username,
      password: values.password,
    })
    .then(res => {
      message.success(I18n.t('loginScreenLoginSuccessMessage'));

      const search = history.location.search.substr(1).split('&');
      const next = search.find(tmp => tmp.match(/^next=/)) || null;
      const nextPath = next ? decodeURIComponent(next.substr(5)) : '/';

      return ToolRequest.request('GET', '/v1/user/me')
        .then(user => {
          ToolUser.updateLocale(user.locale);

          dispatch(setLocale(user.locale));

          dispatch(updateUser(user));

          return ToolRequest.request('GET', '/v1/user-permission')
            .then(userPermissions => {
              dispatch(updateUserPermissions(userPermissions));

              if (!Object.keys(userPermissions).length) {
                throw new Error();
              }

              history.replace(!nextPath.match(/^\/login/) ? nextPath : '/');
            });
        })
        .catch(err => {
          history.replace(`/login?next=${encodeURIComponent(history.location.pathname)}`);
        });
    })
    .catch(err => {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          case 'USER_NOT_FOUND': {
            return message.error(I18n.t('loginScreenLoginFailedMessageUserNotFound'));
          }
          case 'INCORRECT_PASSWORD': {
            return message.error(I18n.t('loginScreenLoginFailedMessageIncorrectPassword'));
          }
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }
      message.error(I18n.t('errorMessageUnknownError'));
    });
  };

  return (
    <Spin spinning={loading}>
      <div id="loginContainer">
        <div>
          <Title level={2} className="title">
            <img src={logo} height={100} style={{marginBottom: 16}} />
          </Title>

          <Form
            {...formLayout}
            onFinish={onFinish}
          >
            <Form.Item
              label={I18n.t('loginScreenFormMerchantName')}
              name="merchantName"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('loginScreenFormMerchantName'),
                  }),
                },
              ]}
            >
              <Input
                placeholder={I18n.t('loginScreenFormMerchantName')}
              />
            </Form.Item>

            <Form.Item
              label={I18n.t('loginScreenFormUserName')}
              name="username"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('loginScreenFormUserName'),
                  }),
                },
              ]}
            >
              <Input
                placeholder={I18n.t('loginScreenFormUserName')}
              />
            </Form.Item>

            <Form.Item
              label={I18n.t('loginScreenFormPassword')}
              name="password"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('loginScreenFormPassword'),
                  }),
                },
              ]}
            >
              <Input.Password
                placeholder={I18n.t('loginScreenFormPassword')}
              />
            </Form.Item>

            <Form.Item {...formTailLayout}>
              <Button type="default" htmlType="submit" block>
                {I18n.t('loginScreenFormLoginButton')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};
