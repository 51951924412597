import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import {Form, Input, Select, Button, Typography, Spin, message, PageHeader} from 'antd';
import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';
import {useSelector} from "react-redux";

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;
const { Option } = Select;

export default function UserAddScreen(props) {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const userData = useSelector(state => state.user.value);
  const [loading, setLoading] = useState(false);
  const [merchantOptions, setMerchantOptions] = useState(null);
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const roles = ['SUPER_ADMIN','ADMIN'].map(item=>{
    return <Option key={item}>{item}</Option>
  });

  const canGetXmMerchant = ToolPermission.hasPermission(userPermissionsData, 'merchant', 'get:xm');

  useEffect(() => {
    (async () => {
      if(canGetXmMerchant){
        try {
          const merchantRes = await ToolRequest.request('GET', '/v1/merchant', {
            count: 1000,
          });
          const merchantOptions = merchantRes.data.map(item=>{
            return <Option key={item.id}>{item.displayName}</Option>
          });
          setMerchantOptions(merchantOptions);
        } catch (err) {

        }
      }else {
        setMerchantOptions([
          <Option key={userData.merchantId}>{userData.displayName}</Option>
        ]);
      }

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const data = {
        merchantId:values.merchantId,
        name:values.name,
        displayName:values.displayName,
        email:values.email || null,
        phone:values.phone || null,
        locale:values.locale,
        roles:values.roles,
        password:values.password
      };
      const res = await ToolRequest.request('POST', '/v1/user', data);

      message.success(I18n.t('userAddScreenAddSuccessMessage'));

      history.push('/user');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }

      message.error(I18n.t('errorMessageUnknownError'));
    }
  };

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('userAddScreenTitle')}
    >

      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={{
            locale: 'zh-tw',
            merchantId:userData.merchantId,
            roles:canGetXmMerchant ? ['ADMIN'] : ['SUPER_ADMIN']
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={I18n.t('userAddScreenFormMerchant')}
            name="merchantId"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('userAddScreenFormMerchant'),
                }),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={I18n.t('userAddScreenFormMerchant')}
              optionFilterProp="children"
              disabled={!canGetXmMerchant}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {merchantOptions}
            </Select>
          </Form.Item>

          <Form.Item
            label={I18n.t('userAddScreenFormName')}
            name="name"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('userAddScreenFormName'),
                }),
              },
            ]}
          >
            <Input
              placeholder={I18n.t('userAddScreenFormName')}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('userAddScreenFormDisplayName')}
            name="displayName"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('userAddScreenFormDisplayName'),
                }),
              },
            ]}
          >
            <Input
              placeholder={I18n.t('userAddScreenFormDisplayName')}
            />
          </Form.Item>

          <Form.Item
            label={I18n.t('userAddScreenFormDisplayLocale')}
            name="locale"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredSelectAny', {
                  name: I18n.t('userAddScreenFormDisplayLocale'),
                }),
              },
            ]}
          >
            <Select placeholder={I18n.t('userAddScreenFormLocale')}>
              <Option value="en">
                {I18n.t('userAddScreenFormLocaleEn')}
              </Option>
              <Option value="zh-tw">
                {I18n.t('userAddScreenFormLocaleZhTw')}
              </Option>
              <Option value="zh-cn">
                {I18n.t('userAddScreenFormLocaleZhCn')}
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={I18n.t('userAddScreenFormEmail')}
            name="email"
          >
            <Input type="email" placeholder={I18n.t('userAddScreenFormEmail')} />
          </Form.Item>

          <Form.Item
            label={I18n.t('userAddScreenFormPhone')}
            name="phone"
          >
            <Input placeholder={I18n.t('userAddScreenFormPhone')} />
          </Form.Item>

          <Form.Item
            label={I18n.t('userAddScreenFormRoles')}
            name="roles"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredSelectAny', {
                  name: I18n.t('userAddScreenFormRoles'),
                }),
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder={I18n.t('userAddScreenFormRoles')}
            >
              {roles}
            </Select>
          </Form.Item>

          <Form.Item
            label={I18n.t('userAddScreenFormPassword')}
            name="password"
            rules={[
              {
                required: true,
                message: I18n.t('errorMessageRequiredInputAny', {
                  name: I18n.t('userAddScreenFormPassword'),
                }),
              },
            ]}
          >
            <Input type="password" placeholder={I18n.t('userAddScreenFormPassword')} />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {I18n.t('userAddScreenButtonSubmit')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
