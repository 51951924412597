import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Layout } from 'antd';

import HomeScreen from '../Home/HomeScreen';
import LockerViewScreen from '../Locker/LockerViewScreen';
import LockerAddScreen from '../Locker/LockerAddScreen';
import LockerEditScreen from '../Locker/LockerEditScreen';
import LockerCopyScreen from '../Locker/LockerCopyScreen';
import LockerDetailScreen from '../Locker/LockerDetailScreen';
import LockerCellViewScreen from '../LockerCell/LockerCellViewScreen';
import LockerCellAddScreen from '../LockerCell/LockerCellAddScreen';
import LockerCellEditScreen from '../LockerCell/LockerCellEditScreen';
import LockerCellAutoAddScreen from '../LockerCell/LockerCellAutoAddScreen';
import MerchantViewScreen from '../Merchant/MerchantViewScreen';
import MerchantAddScreen from '../Merchant/MerchantAddScreen';
import MerchantEditScreen from '../Merchant/MerchantEditScreen';
import MerchantDetailScreen from '../Merchant/MerchantDetailScreen';
import CellContractViewScreen from '../CellContract/CellContractViewScreen';
import CellContractAddScreen from '../CellContract/CellContractAddScreen';
import CellContractEditScreen from '../CellContract/CellContractEditScreen';
import CellContractDetailScreen from '../CellContract/CellContractDetailScreen';
import RegionViewScreen from '../Region/RegionViewScreen';
import RegionAddScreen from '../Region/RegionAddScreen';
import RegionEditScreen from '../Region/RegionEditScreen';
import UserViewScreen from '../User/UserViewScreen';
import UserAddScreen from '../User/UserAddScreen';
import UserEditScreen from '../User/UserEditScreen';
import CellOrderViewScreen from '../CellOrder/CellOrderViewScreen';
import CellOrderEditScreen from '../CellOrder/CellOrderEditScreen';
import CellOrderDetailScreen from '../CellOrder/CellOrderDetailScreen';
import MerchantBillViewScreen from '../MerchantBill/MerchantBillViewScreen';
import MerchantBillDetailScreen from '../MerchantBill/MerchantBillDetailScreen';
import MerchantTransactionViewScreen from '../MerchantTransaction/MerchantTransactionViewScreen';
const { Content } = Layout;

export default function AppContent(props) {
  return (
    <Content id="mainLayoutContent">
      <div>
        <Switch>
          <Route exact path="/locker" component={LockerViewScreen} />
          <Route exact path="/locker/add" component={LockerAddScreen} />
          <Route exact path="/locker/edit/:lockerId" component={LockerEditScreen} />
          <Route exact path="/locker/copy/:lockerId" component={LockerCopyScreen} />
          <Route exact path="/locker/detail/:lockerId" component={LockerDetailScreen} />
          <Route exact path="/locker/edit/:lockerId/cell" component={LockerCellViewScreen} />
          <Route exact path="/locker/edit/:lockerId/cell/add" component={LockerCellAddScreen} />
          <Route exact path="/locker/edit/:lockerId/cell/edit/:cellId" component={LockerCellEditScreen} />
          <Route exact path="/locker/edit/:lockerId/cell/auto-add" component={LockerCellAutoAddScreen} />
          <Route exact path="/merchant" component={MerchantViewScreen} />
          <Route exact path="/merchant/add" component={MerchantAddScreen} />
          <Route exact path="/merchant/edit/:merchantId" component={MerchantEditScreen} />
          <Route exact path="/merchant/detail/:merchantId" component={MerchantDetailScreen} />
          <Route exact path="/cell-contract" component={CellContractViewScreen} />
          <Route exact path="/cell-contract/add" component={CellContractAddScreen} />
          <Route exact path="/cell-contract/edit/:cellContractId" component={CellContractEditScreen} />
          <Route exact path="/cell-contract/detail/:cellContractId" component={CellContractDetailScreen} />
          <Route exact path="/region" component={RegionViewScreen} />
          <Route exact path="/region/add" component={RegionAddScreen} />
          <Route exact path="/region/edit/:regionId" component={RegionEditScreen} />
          <Route exact path="/region" component={RegionViewScreen} />
          <Route exact path="/region/add" component={RegionAddScreen} />
          <Route exact path="/region/edit/:regionId" component={RegionEditScreen} />
          <Route exact path="/user" component={UserViewScreen} />
          <Route exact path="/user/add" component={UserAddScreen} />
          <Route exact path="/user/edit/:userId" component={UserEditScreen} />
          <Route exact path="/cell-order" component={CellOrderViewScreen} />
          <Route exact path="/cell-order/edit/:cellOrderId" component={CellOrderEditScreen} />
          <Route exact path="/cell-order/detail/:orderId" component={CellOrderDetailScreen} />
          <Route exact path="/merchant-bill" component={MerchantBillViewScreen} />
          <Route exact path="/merchant-bill/detail/:billId" component={MerchantBillDetailScreen} />
          <Route exact path="/merchant-transaction" component={MerchantTransactionViewScreen} />
          <Route component={HomeScreen} />
        </Switch>
      </div>
    </Content>
  );
};
