import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { PageHeader, Typography} from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';
import SuperTable from '../../components/SuperTable';

const { Text } = Typography;

export default function MerchantTransactionViewScreen(props) {
  const history = useHistory();
  const userData = useSelector(state => state.user.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const [loaded, setLoaded] = useState(false);
  const [tableKey, setTableKey] = useState(Math.random().toString());
  const [merchants, setMerchants] = useState([]);

  const columns = [
    ...(ToolPermission.hasPermission(userPermissionsData, 'system-admin', 'get') ? [
      {
        template: 'id',
        key: 'id',
        width: 40,
        title: 'ID',
      },
    ] : []),
    {
      key: 'merchantName',
      title: I18n.t('merchantTransactionViewScreenTableMerchantName'),
      render: (value, record) => {
        return record.merchant.displayName;
      },
    },
    {
      key: 'type',
      title: I18n.t('merchantTransactionViewScreenTableType'),
      render: (value, record) => {
        return {
          'TRANSFER': I18n.t('merchantTransactionViewScreenTableTypeTransfer'),
          'MONTH_RENTAL': I18n.t('merchantTransactionViewScreenTableTypeMonthRental'),
          'DAY_RENTAL': I18n.t('merchantTransactionViewScreenTableTypeDayRental'),
          'CREDIT': I18n.t('merchantTransactionViewScreenTableTypeCredit'),
          'REFUND': I18n.t('merchantTransactionViewScreenTableTypeRefund'),
        }[value];
      },
    },
    {
      template: 'price',
      key: 'amount',
      title: I18n.t('merchantTransactionViewScreenTableAmount'),
      width: 120,
    },
    {
      key: 'remark',
      title: I18n.t('merchantTransactionViewScreenTableRemark'),
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('merchantTransactionViewScreenTableCreatedAt'),
    },
  ];

  const searchColumns = [
    ...(ToolPermission.hasPermission(userPermissionsData, 'merchant', 'get:xm') ? [
      {
        template: 'select',
        key: 'merchantId',
        title: I18n.t('tableSearchMerchantAny'),
        options: merchants.map(merchant => ({
          label: `${merchant.displayName} (${merchant.name})`,
          value: merchant.id,
        })),
      }
    ]: []),
    {
      template: 'select',
      key: 'type',
      title: I18n.t('tableSearchTransactionTypeAny'),
      options: [
        {
          label: I18n.t('tableSearchMerchantTransactionTypeTransfer'),
          value: 'TRANSFER'
        },
        {
          label: I18n.t('tableSearchMerchantTransactionTypeDayRental'),
          value: 'DAY_RENTAL'
        },
        {
          label: I18n.t('tableSearchMerchantTransactionTypeMonthRental'),
          value: 'MONTH_RENTAL'
        },
        {
          label: I18n.t('tableSearchMerchantTransactionTypeCredit'),
          value: 'CREDIT'
        },
        {
          label: I18n.t('tableSearchMerchantTransactionTypeRefund'),
          value: 'REFUND'
        }
      ],
    },
  ];

  const searchQueryParams = values => {
    return {
      merchantId: values.merchantId ? values.merchantId : undefined,
      type: values.type ? values.type: undefined,
    };
  };

  useEffect(() => {
    (async () => {
      try {
        const merchantRes = await ToolRequest.request('GET', '/v1/merchant', {
          count: 1000,
        });
        setMerchants(merchantRes.data);

        // TODO filter by locker?
      } catch (err) {
      }

      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const transactionRes = await ToolRequest.request('GET', '/v1/merchant-transaction', {
      ...body
    });

    const merchantIds = transactionRes.data.map(bill => bill.merchantId)
      .filter((value, index, self) => self.indexOf(value) === index);

    const merchants = await Promise.all(merchantIds.map(async merchantId => await ToolRequest.request('GET', `/v1/merchant/${merchantId}`).catch(err => {})));

    return {
      data: transactionRes.data.map(transaction => {
        const merchant = merchants.find(merchantItem => merchantItem.id === transaction.merchantId);
        return {
          ...transaction,
          merchant
        };
      }),
      totalCount: transactionRes.totalCount,
    };
  };

  return loaded ? (
    <PageHeader
      title={I18n.t('merchantTransactionViewScreenTitle')}
    >
      <SuperTable
        searchColumns={searchColumns}
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
    </PageHeader>
  ) : <SpinFull />;
};
