import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import {Button, PageHeader, Typography, Tag} from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';
import SuperTable from '../../components/SuperTable';
import {SearchOutlined} from "@ant-design/icons";

const { Text } = Typography;

export default function MerchantBillViewScreen(props) {
  const history = useHistory();
  const userData = useSelector(state => state.user.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);

  const [loaded, setLoaded] = useState(false);
  const [tableKey, setTableKey] = useState(Math.random().toString());
  const [merchants, setMerchants] = useState([]);

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              history.push(`/merchant-bill/detail/${record.id}`);
            }}
          >
            <SearchOutlined />
            {I18n.t('merchantBillViewScreenActionButtonView')}
          </Button>
        );
      },
    },
    ...(ToolPermission.hasPermission(userPermissionsData, 'system-admin', 'get') ? [
      {
        template: 'id',
        key: 'id',
        width: 40,
        title: 'ID',
      },
    ] : []),
    {
      key: 'merchantName',
      title: I18n.t('merchantBillViewScreenTableMerchantName'),
      render: (value, record) => {
        return record.merchant.displayName;
      },
    },
    {
      key: 'receiptNo',
      title: I18n.t('merchantBillViewScreenTableReceiptNo'),
    },
    {
      template: 'price',
      key: 'price',
      title: I18n.t('merchantBillViewScreenTablePrice'),
      width: 120,
    },
    {
      key: 'type',
      title: I18n.t('merchantBillViewScreenTableType'),
      width: 120,
      render: (value, record) => {
        if (value === 'DAY') {
          return <Tag color="volcano">{I18n.t('merchantBillViewScreenTypeDay')}</Tag>;
        } else if(value === 'MONTH') {
          return <Tag color="cyan">{I18n.t('merchantBillViewScreenTypeMonth')}</Tag>;
        }
      },
    },
    {
      key: 'description',
      title: I18n.t('merchantBillViewScreenDescription'),
    },
    {
      key: 'remark',
      title: I18n.t('merchantBillViewScreenTableRemark'),
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('merchantBillViewScreenTableCreatedAt'),
    },
  ];

  const searchColumns = [
    ...(ToolPermission.hasPermission(userPermissionsData, 'merchant', 'get:xm') ? [
      {
        template: 'select',
        key: 'merchantId',
        title: I18n.t('tableSearchMerchantAny'),
        options: merchants.map(merchant => ({
          label: `${merchant.displayName} (${merchant.name})`,
          value: merchant.id,
        })),
      }
    ]: []),
    {
      template: 'select',
      key: 'type',
      title: I18n.t('tableSearchBillTypeAny'),
      options: [
        {
          label: <Tag color="volcano">{I18n.t('tableSearchBillTypeDay')}</Tag>,
          value: 'DAY',
        },
        {
          label: <Tag color="cyan">{I18n.t('tableSearchBillTypeMonth')}</Tag>,
          value: 'MONTH',
        },
      ],
    },
  ];

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
      merchantId: values.merchantId ? values.merchantId : undefined,
      type: values.type ? values.type : undefined,
    };
  };

  useEffect(() => {
    (async () => {
      try {
        const merchantRes = await ToolRequest.request('GET', '/v1/merchant', {
          count: 1000,
        });
        setMerchants(merchantRes.data);

        // TODO filter by locker?
      } catch (err) {
      }

      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const billRes = await ToolRequest.request('GET', '/v1/merchant-bill', {
      ...body
    });
    console.dir(billRes)
    const merchantIds = billRes.data.map(bill => bill.merchantId)
      .filter((value, index, self) => self.indexOf(value) === index);

    const merchants = await Promise.all(merchantIds.map(async merchantId => await ToolRequest.request('GET', `/v1/merchant/${merchantId}`).catch(err => {})));

    return {
      data: billRes.data.map(bill => {
        const merchant = merchants.find(merchantItem => merchantItem.id === bill.merchantId);
        return {
          ...bill,
          merchant
        };
      }),
      totalCount: billRes.totalCount,
    };
  };

  return loaded ? (
    <PageHeader
      title={I18n.t('merchantBillViewScreenTitle')}
    >
      <SuperTable
        showSearchInput
        searchColumns={searchColumns}
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
    </PageHeader>
  ) : <SpinFull />;
};
