import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';

import { PageHeader, Typography, Descriptions, Card, Badge, Row, Col, Tooltip, Tag, Alert, Steps, Collapse, message } from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';
import SpinFull from '../../components/SpinFull';

const { Panel } = Collapse;

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const gridStyle = {
  width: '25%',
  textAlign: 'center',
};

const { Title, Text } = Typography;

export default function MerchantBillDetailScreen(props) {
  const history = useHistory();
  const { billId } = useParams();
  const userData = useSelector(state => state.user.value);

  const [loaded, setLoaded] = useState(false);
  const [bill, setBill] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [billCellContracts, setBillCellContracts] = useState([]);

  useEffect(() => {
    (async () => {
      const billRes = await ToolRequest.request('GET', `/v1/merchant-bill/${billId}`);
      setBill(billRes);
      const merchantRes = await ToolRequest.request('GET', `/v1/merchant/${billRes.merchantId}`);
      setMerchant({
        ...merchantRes,
        locale: [merchantRes.locale],
      });
      const billCellContractRes = await ToolRequest.request('GET', `/v1/merchant-bill-cell-contract`,{merchantBillId:billId});
      console.dir(billCellContractRes)
      const dataArr = await Promise.all(billCellContractRes.data.map(async (billCellContract)=>{
        const contract = await ToolRequest.request('GET', `/v1/cell-contract/${billCellContract.cellContractId}`);
        const cell = await ToolRequest.request('GET', `/v1/cell/${contract.cellId}`);
        const locker = await ToolRequest.request('GET', `/v1/locker/${cell.lockerId}`);
        return {
          ...billCellContract,
          contract,
          cell,
          locker,
          bill:billRes
        }
      }));
      setBillCellContracts(dataArr);
      setLoaded(true);
    })();
  }, []);

  return loaded ? (
    <PageHeader
      onBack={() => history.goBack()}
      title={I18n.t('merchantBillDetailScreenTitle')}
    >
      <Card>
        <Title level={5}></Title>
        <Row gutter={16}>
          <Col span={12}>
            <Descriptions
              size="small"
              column={1}
            >
              <Descriptions.Item label={I18n.t('merchantBillDetailScreenMerchantName')}>
                {merchant.displayName}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('merchantBillDetailScreenReceiptNo')}>
                {bill.type === 'DAY' ? I18n.t('merchantBillDetailScreenTypeDay') : I18n.t('merchantBillDetailScreenTypeMonth')}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('merchantBillDetailScreenReceiptNo')}>
                {bill.receiptNo}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('merchantBillDetailScreenPrice')}>
                {bill.price ? bill.price / 1000000 : ''}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('merchantBillDetailScreenDecscription')}>
                {bill.description}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('merchantBillDetailScreenRemark')}>
                {bill.remark}
              </Descriptions.Item>
              <Descriptions.Item label={I18n.t('merchantBillDetailScreenCreatedAt')}>
                {moment(bill.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>

      <Row>
        <Col flex="auto">
          {billCellContracts.map((billCellContract, i) => {
            const lockerName = billCellContract.locker.name[userData.locale];
            const cellName = billCellContract.cell.name;
            let options = {
              lockerName,
              cellName,
              price:billCellContract.price / 1000000
            }
            let text = I18n.t('merchantBillDetailScreenBillCellContractPanelHeaderMonthType',options);
            if(billCellContract.bill.type === 'DAY'){
              options.duration = moment(billCellContract.contract.contractEndAt).diff(moment(billCellContract.contract.contractStartAt),'hours');
              console.dir(options)
              text = I18n.t('merchantBillDetailScreenBillCellContractPanelHeaderDayType',options);
            }
            console.dir(text)
            return (
              <Collapse key={i} style={{marginTop: 4}}>
                <Panel header={text} key="1">
                  <Descriptions
                    size="small"
                    column={1}
                  >
                    <Descriptions.Item label="Locker">
                      {billCellContract.locker.name[userData.locale]}
                    </Descriptions.Item>
                    <Descriptions.Item label="Cell">
                      {billCellContract.cell.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Type">
                      {billCellContract.bill.type === 'DAY' ? I18n.t('merchantBillDetailScreenTypeDay') : I18n.t('merchantBillDetailScreenTypeMonth')}
                    </Descriptions.Item>
                    <Descriptions.Item label="Cell Price">
                      ${'$'+billCellContract.cell.price ? billCellContract.cell.price / 1000000 : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Price">
                      ${'$'+billCellContract.contract.price ? billCellContract.contract.price / 1000000 : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Start At">
                      {moment(billCellContract.contract.contractStartAt).format('YYYY-MM-DD HH:mm:ss')}
                    </Descriptions.Item>
                    <Descriptions.Item label="End At">
                      {moment(billCellContract.contract.contractEndAt).format('YYYY-MM-DD HH:mm:ss')}
                    </Descriptions.Item>
                    <Descriptions.Item label="Created At">
                      {moment(billCellContract.contract.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                    </Descriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            );
          })}
        </Col>
      </Row>
    </PageHeader>
  ) : <SpinFull />;
};
